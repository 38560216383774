//Team list

.team-list {
	position: relative;

	.team-list__member-container {
		position: static;
	}

	.team-list__member {
		cursor: pointer;
		margin-bottom: 40px;
		padding-left: 60px;
		padding-right: 60px;
		position: relative;
		text-align: center;
	}
	
	.team-list__member-image {
		background: map-get($brand, 'a');
		border-radius: 50%;
		display: inline-block;
		margin-bottom: 1em;
		padding: 2px;

		img {
			border-radius: 50%;
			transition: filter $easing-regular;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: 200px;
			}
		}
	}

	.team-list__member-name {
		font-family: $font-stack-bold;
		font-size: rem(18);
	}

	.team-list__member-role {
		font-family: $font-stack-header;
	}

	.team-list__member-bio-container {
		display: none;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: auto;
	}

	.team-list__member-bio {
		background-color: map-get($brand, 'a');
		color: $white;
		font-size: rem(14);
		margin-bottom: 40px;
		padding: 40px 100px;
		text-align: left;

		.rte-copy {
			a, a:hover, a:focus {
				color: $white;
			}
		}
	}

	.team-list__member:hover,
	.team-list__member:focus {
		.team-list__member-image img {
			padding: 1px;
		}
	}

	.team-list__member-container.active {

		.team-list__member:after {
			border-bottom: 20px solid map-get($brand, 'a');
			border-left: 30px solid $white;
			border-right: 30px solid $white;
			bottom: -40px;
			content: "";
			display: block;
			height: 20px;
			left: 50%;
			margin-left: -30px;
			position: absolute;
			width: 60px;
		}

		.team-list__member-image {
			@include gradient-angle();
			padding: 3px;
		}

		.team-list__member-bio-container {
			display: block;
		}
	}
}

@include media-breakpoint-down(lg) {
	.team-list {
		.team-list__member {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}


@include media-breakpoint-down(md) {
	.team-list {
		.team-list__member {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.team-list {
		.team-list__member {
			padding-left: 45px;
			padding-right: 45px;
		}
		.team-list__member-bio {
			padding: 40px;
		}
	}
}