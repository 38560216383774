.thanks-modal {
	display: none;

	.modal-active & {
		display: block;
	}

	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(33,33,33,0.2);
	z-index: 20;
	top: 0;
	left: 0;

	&__window {
		background: #FFFFFF;
		box-shadow: 10px 12px 36px 0 rgba(0,0,0,0.15);
		display: inline-block;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: rem(70) rem(140) rem(60);

		@include media-breakpoint-down(md) {
			padding: rem(35) rem(70) rem(30);
		}

		&:after {
			@include gradient-flat();
			content: "";
			display: block;
			height: 4px;
			left: 0;
			position: absolute;
			bottom: -4px;
			width: 100%;
		}

		.close-modal {
			position: absolute;
			top: rem(15);
			right: rem(15);

			.icon {
				fill: $purple;
				width: rem(37);
				height: rem(37);
			}
		}

		h3 {
			margin: 0;
			font-size: rem(42);
			color: $font-color;
			letter-spacing: rem(0.09);
			line-height: rem(36);
		}

		h4 {
			margin: 0;
			font-size: rem(30);
			color: $font-color;
			letter-spacing: rem(0.09);
			line-height: rem(36);
		}

		p {
			letter-spacing: rem(0.04);
		}
	}
}
