// Breakout panel

.breakout {
	background-color: $grey-light;
	margin-top: 4px;
	padding: 2.5em 0;
	position: relative;

	&:after {
		@include gradient-flat();
		content: "";
		display: block;
		height: 4px;
		left: 0;
		position: absolute;
		top: -4px;
		width: 100%;
	}

	h4 {
		margin-bottom: 1em;
	}

	.breakout__quote {
		@include gradient-text();
		font-size: rem(24);
		font-family: $font-stack-header;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background: none;
			color: $purple;
		}
	}
}

@include media-breakpoint-down(sm) {
	.breakout__image {
		margin-bottom: 1.5em;
	}
}

.quote-header{
	font-family: $font-stack-header;
}

.strip__quote {
	row-gap: 1.5em;
}

.strip__quote-card {
	background: $grey-light;
	margin-block-end: 1em;
	min-height: 25em;
	overflow: hidden;
	position: relative;
	z-index: 1;
	justify-content: center;
	padding: 2.25em;

	&:after {
		@include gradient-angle();
		content: "";
		left: 0;
		top: 0;
		height: 4px;
		width: 100%;
		position: absolute;
		z-index: -1;
	}

	h5 {
		@include gradient-text();
		font-family: $font-stack-header;
		font-size: rem(20);
		width: fit-content;
	}

	p {
		font-size: rem(12);
	}
}