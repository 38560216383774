// Section
.main-content {
	position: relative;
}

.section {
	margin: 2em 0;
	position: relative;
	z-index: 5;

	&.section--background {
		z-index: 1;
	}
}

.section-margin-bottom {
	margin-bottom: 2em;
}
