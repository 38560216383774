.glossary {
	&__header-wrap {
		@include gradient-angle();
		width: 100%;
		top: 0;

		.header {
			z-index: 15;
		}
	}
	&__overlay {
		background-image: url("../images/overlays/Homepage.jpg");
		opacity: 0.1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&__header-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url("../images/shape-lexicon-bg.png");
		background-repeat: no-repeat;
		background-size: 440px;
		background-position: top -130px right -150px;
	}
	.term__overlay {
		background-image: url("../images/graphs/snail-colour.svg");
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.1;
		transform: scaleX(-1);
		background-position: bottom -70px right;
	}
	.form__overlay {
		background-image: url("../images/shape-lexicon-bg.png");
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		opacity: 0.7;
		background-position: bottom right -200px;
	}
	.header {
		position: relative;
		background: transparent;
		// background: #3c008d;
		// background: linear-gradient(110deg,#3c008d,#1783bd 75%,#3ac7a7 90%,#70bc1f);

		.header__nav--solid {
			// background: #3c008d;
			// background: linear-gradient(110deg,#3c008d,#1783bd 75%,#3ac7a7 90%,#70bc1f);
		}

		@include media-breakpoint-down(md){
			&__nav {
				padding: rem(5) 0;
			}

			.brand-logo {
				width: rem(88);
				height: rem(47);
			}
		}
	}

	.heading {
		padding-top: rem(108);
		padding-bottom: rem(25);
		// @include gradient-angle();

		@include media-breakpoint-down(sm){
			padding-top: rem(55);
		}

		h1 {
			color: #ffffff;
			letter-spacing: rem(0.1);
			line-height: rem(68);

			@include media-breakpoint-down(sm){
				font-size: rem(30);
				letter-spacing: rem(0.06);
				line-height: rem(27);
			}

		}
		.subtitle {
			color: #ffffff;
			letter-spacing: rem(0.04);
		}
		.form-link {
			text-align: right;

			a {
				color: #fff;

				.icon {
					margin-left: rem(10);
				}

				&:hover {
					text-decoration: underline;
				}
			}

			.icon {
				transform: scaleX(-1);
			}
		}
	}

	&.search-active .heading, &.search-active .header {
		@include media-breakpoint-down(sm){
			display: none;
			.container-fluid {
				display: none;

			}
		}
	}

	.search-bar {
		background: #3d269a;
		position: relative;
		padding-top: rem(30);
		border-top: 2px solid #fff;

		@include media-breakpoint-down(md){
			padding-top: rem(15);
		}




		.search-field {

			&__animation {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				pointer-events: none;
				display: flex;
				align-items: center;
				line-height: 1;
				padding-left: rem(40);
				font-size: rem(18);
				color: #FFFFFF;
				letter-spacing: 0;

				&-text {
					margin-left: rem(10);
				}

				&.is-hidden {
					display: none;
				}
			}
		}


		.anchors {
			overflow-x: scroll;

			-webkit-overflow-scrolling: touch;

			-ms-overflow-style: none; // IE 10+
			scrollbar-width: none; // Firefox

			&::-webkit-scrollbar {
				display: none; // Safari and Chrome
			}

			&__wrapper {
				margin: rem(10) auto;
				min-width: rem(890);
			}

			a {
				display: inline-block;
				width: rem(30);
				color: #ffffff;
				text-decoration: none;
				padding: rem(2) rem(8);
				border: 1px solid transparent;
				border-radius: rem(20);
				@media (hover: hover) {
					&:hover {
						color: #3d269a;
						background-color: #ffffff;
					}
				}

				&.active {
					transition: all 0.1s ease-in;
					transition-delay: 0.1s;
					color: #3d269a;
					background-color: #ffffff;
				}
			}

			span {
				padding: rem(10);
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

.search-page {
	.header {
		position: fixed;
		width: 100%;
		top: 0;
	}
}

.search-close {
	display: none;
	position: absolute;
	top: 9px;
	right: 36px;
	width: 42px;
	height: 42px;
	background-color: transparent;
	border: none;
	background-size: 100% 100%;
	background-image: url('../images/graphs/icon-close.svg');
   .search-active & {
	   display: block;


   }
}
#searchinput {
	width: 100%;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 30px;
	padding: rem(15) rem(35);
	outline: none;
	border: 0;

	font-family: Roboto-Regular;
	font-size: rem(18);
	color: #ffffff;
	letter-spacing: 0;

	background-image: url('../images/graphs/icon-search.svg');
	background-repeat: no-repeat;
	background-position: center right rem(30);

	.search-active & {
		background-image: none;
	}

	&::placeholder {
		font-size: rem(18);
		color: rgba(255, 255, 255, 0.5);
	}

	&::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
}

.sticky-search {
	.search-bar {
		position: fixed;
		width: 100%;
		z-index: 1;
		top: rem(106);
		margin: 0;

		@include media-breakpoint-down(md){
			top: rem(57);
			&.nav-up {
				top: rem(-10);
			}
		}
	}

	#content {
		margin-top: rem(139);

		@include media-breakpoint-down(md){
			margin-top: rem(98);
		}
	}
}
