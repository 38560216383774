html {
	font-size: $root-font-size + px;
}

body {
	@extend %font-regular;
	color: $font-color;
	font-size: $root-font-size + px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend %font-header;
	font-weight: normal;
}

h1,
.h1 {
	font-size: rem(48);
}

h2,
.h2 {
	font-size: rem(42);
}

h3,
.h3 {
	font-size: rem(36);
}

h4,
.h4 {
	font-size: rem(30);
}

h5,
.h5 {
	font-size: rem(24);
}

h6,
.h6 {
	font-size: rem(18);
}

p {
	font-size: rem(16);
	margin: 1em 0;
}

a {
	color: $font-color;
	text-decoration: underline;
	transition: color $easing-regular;
}

a:active,
a:focus,
a:hover {
	color: map-get($brand, 'a');
	text-decoration: none;
}

ol,
ul {
	padding-left: 32px;

	li {
		line-height: 1.5;
	}

	&.remove-spacing {
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;
	}
}

.break--gradient {
	@include gradient-flat();
	border: none;
	height: 2px;
	width: 100%;
}

strong {
	@extend %font-bold;
}

.center,
.text-center {
	text-align: center;
}

.uppercase {
	text-transform: uppercase;
}

.subtitle {
	padding-left: 22px; 
	padding-right: 22px; 
}

.text-small {
	font-size: rem(14);
}

.text-extra-small {
	font-size: rem(12);
}

.rte-copy {
	h6 {
		color: $purple;
		font-size: 1.5rem;
		text-align: center;
	}

	ol,
	ul {
		padding-left: 20px;

		li {
			margin-bottom: 20px;
			padding-left: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	em {
		@extend %font-italic; 
		font-style: normal;
	}

	.header-font {
		@extend %font-header;
	}
}

.gradient-links {
	ol, ul {
		li a {
			@include gradient-text();
			display: inline-block;
			position: relative;
			text-decoration: none;

			&:after {
				@include gradient-flat();
				bottom: 0;
				content: "";
				height: 2px;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				transform: translateY(-5px);
				transition: all $easing-regular;
			}

			&:hover:after,
			&:focus:after {
				opacity: 1;
				transform: translateY(0);
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background: none;
				color: $purple;
			}
		}
	}
}

.icon-link {
	text-decoration: none;

	.icon {
		vertical-align: bottom;
	}
}
