.access-links {
	left: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&__item:not(:focus) {
		clip: rect(0 0 0 0);
		color: $white;
		height: 1px; width: 1px;
		margin: -1px; padding: 0; border: 0;
		overflow: hidden;
		position: absolute;
	}

	&__item:focus {
		background-color: $blue;
		box-shadow: 0px -3px 5px 5px rgba(0,0,0,0.75);
		color: $white;
		display: block;
		outline: none;
		padding: 0.5em 1em;
		text-align: center;
		text-decoration: underline;
	}

	/* z-index */

	z-index: 20;
}