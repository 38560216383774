// Icons

.icon {
	fill: $white;
	height: 24px;
	transition: $easing-regular;
	width: 24px;

	&.icon--email {
		stroke: $white;
	}

	&.icon--dark {
		color: $grey;
		fill: $black;

		&.icon--email {
			stroke: $black;
		}
	}

	&.icon--grey {
		color: $grey;
		fill: $grey;

		&.icon--email {
			stroke: $grey;
		}
	}

	&.icon--gradient {
		padding: 6px 5px;
	}

	&.icon--social:hover,
	&.icon--social:focus {
		cursor: pointer;
		opacity: 0.9;
	}
}

.icon--flag {
	width: 27px;
	height: 18px;
}
