// Branded Gradients
@mixin gradient-flat() {
	background: map-get($gradient-color, 'a');
	background: linear-gradient(to right, map-get($gradient-color, 'a'),  map-get($gradient-color, 'b') 70%, map-get($gradient-color, 'c') 85%, map-get($gradient-color, 'd') 100%);
}

@mixin gradient-angle($angle: 110deg) {
	background: map-get($gradient-color, 'a');
	background: linear-gradient($angle, map-get($gradient-color, 'a'),  map-get($gradient-color, 'b') 70%, map-get($gradient-color, 'c') 85%, map-get($gradient-color, 'd') 100%);
}

@mixin gradient-text() {
	background: map-get($gradient-color, 'a');
	background: linear-gradient(to right, map-get($gradient-color, 'a'),  map-get($gradient-color, 'b') 70%, map-get($gradient-color, 'c') 85%, map-get($gradient-color, 'd') 100%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
}

@mixin gradient-newstext($angle: 315deg) {
	background: map-get($gradient-color, 'a');
	background: linear-gradient($angle, map-get($gradient-color, 'd'),  map-get($gradient-color, 'c') 17.53%, map-get($gradient-color, 'b') 34.53%, map-get($gradient-color, 'a') 100%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
}

@mixin gradient-newsbutton($angle: 315deg) {
	background: map-get($gradient-color, 'a');
	background: linear-gradient($angle, map-get($gradient-color, 'd') 0%,  map-get($gradient-color, 'c') 17.53%, map-get($gradient-color, 'b') 34.53%, map-get($gradient-color, 'a') 100%);
}

@mixin gradient-newscard($angle: 136.38deg) {
	background: map-get($gradient-color, 'a');
	background: linear-gradient($angle, map-get($gradient-color, 'a') 36.3%,  map-get($gradient-color, 'b') 67.12%, map-get($gradient-color, 'c') 94.17%, map-get($gradient-color, 'd') 117.76%);
}

@mixin gradient-line($angle: 90deg) {
	background: map-get($gradient-color, 'a');
	background: linear-gradient($angle, map-get($gradient-color, 'a'),  map-get($gradient-color, 'b') 33.33%, map-get($gradient-color, 'c') 68.75%, map-get($gradient-color, 'd') 100%);
}