// Secondary navigation

.secondary-navigation {

	.secondary-navigation__mobile-toggle {
		display: none;
	}

	.secondary-navigation__menu {
		list-style-type: none;
	}

	.secondary-navigation__item {
		display: inline-block;
		margin: 0 50px;

		a {
			color: $white;
			display: inline-block;
			position: relative;
			text-decoration: none;

			&:after {
				background: $white;
				bottom: -1em;
				content: "";
				display: block;
				height: 2px;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				transition: opacity $easing-regular;
			}

			&:hover,
			&:focus {
				text-decoration: none;

				&:after {
					opacity: 1;
				}
			}

			.icon {
				display: none;
			}
		}

		&.active {
			font-family: $font-stack-bold;
			text-decoration: none;

			a:after {
				opacity: 1;
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	.secondary-navigation {
		.secondary-navigation__item {
			margin: 0 1.25em;
		}
	}
}
@include media-breakpoint-down(md) {
	.secondary-navigation {
		.secondary-navigation__mobile-toggle {
			cursor: pointer;
			display: block;
			font-family: $font-stack-bold;
			position: relative;

			.icon {
				position: absolute;
				right: 0;
				top: 0;
				transition: transform $easing-regular;
			}
		}

		.secondary-navigation__menu {
			display: none;
		}

		.secondary-navigation__item {
			display: block;
			margin: 0.5em 0;

			a:after {
				content: "";
				display: none;
			}
		}

		&.active {
			text-decoration: underline;

			.secondary-navigation__mobile-toggle {

				.icon {
					transform: rotate(180deg);
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.secondary-navigation {
		.secondary-navigation__menu {
			display: block !important;
		}
	}
}