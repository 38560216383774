/* animations */

$easing-regular:    140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
$easing-slow:       280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
$easing-extra-slow: 1s cubic-bezier(0.8, 0.2, 0.2, 0.8);

/* colours */

$black:             #212121;
$white:             #FFFFFF;
$grey:              #696765;
$grey-light:        #EFEEEE;
$grey-light-extra:  #F2F2F2;
$grey-transparent:  rgba(21, 21, 21, 0.2);
$grey-dark: 		#787878;

$indigo:        #1D1648;
$purple:        #3C008D;
$blue:          #1783BD;
$blue-dark:	    #094267;
$green:         #70BC1F;

$brand: (
	a: $purple,
	b: $blue,
	c: $green
);

$gradient-color: (
	a: #3C008D,
	b: #1783BD,
	c: #3AC7A7,
	d: #70BC1F
);

/* typography */

$font-color:          #455560;
$font-stack-header:   'vollkornregular', "Times New Roman", serif;
$font-stack-fallback: 'Gill Sans', Arial;
$font-stack-regular:  'robotoregular', 'Gill Sans', Arial;
$font-stack-bold:     'robotobold', 'Gill Sans', Arial;
$font-stack-italic:     'robotoitalic', 'Gill Sans', Arial;
$root-font-size:      16;