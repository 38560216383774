// Event pages/listings

.breakout-copy {
	text-align: center;
}

.event-info {
	margin: auto;
	max-width: 100%;
	padding-top: 20px;

	&__time,
	&__location {
		align-items: flex-start;
		display: inline-flex;
		margin-bottom: 1em;
		max-height: fit-content;

		p {
			margin: 0;
		}
	}

	.icon {
		fill: #7ED321;
		flex-shrink: 0;
		margin-right: 10px;
	}
}

.event-listing {
	font-size: 40px;

	.time-banner {
		background: rgba(255, 255, 255, 0.3);
		bottom: 0;
		display: block;
		height: 20%;
		left: 0;
		max-width: 100%;
		position: absolute;
		width: 100%;

		p {
			@include media-breakpoint-down(md) {
				font-size: 2vw;
				transform: translateY(-60%);
			}
		}
	}

	@include media-breakpoint-down(lg) {
		font-size: 2vw;
	}
}

.full-width-video {
	overflow-x: hidden;

	.event-video, .event-video-panel, .video-shader, .copy-container, iframe {
		min-height: 500px;

		@include media-breakpoint-down(lg) {
			min-height: 350px;
		}
	}
	
	.event-video {
		display: none;
		left: 0;
		position: absolute;
		width: 100%;

		iframe {
			background: #000;
		}
	}

	.event-video-panel {
		.copy-container {
			margin: 0;

			&.background-blue {
				background-color: rgba(23, 131, 189, 0.8);
			}

			&.background-darkBlue {
				background-color: rgba(19, 0, 103, 0.8);
			}

			&.background-green {
				background-color: rgba(112, 188, 31, 0.8);
			}

			&.background-purple {
				background-color: rgba(60, 0, 141, 0.8);
			}

			.video-copy {
				color: #fff;
				margin: auto;
				margin-top: 190px;
				text-align: center;

				@include media-breakpoint-down(lg) {
					margin-top: 110px;
				}
			}
		}

		.copy-image {
			background-repeat: no-repeat;
			background-size: cover; 
			padding:0;

			@include media-breakpoint-down(lg) {
				min-height: 350px;
			}
		}

		.video-placeholder {
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			img {
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 40%;
			}

			@include media-breakpoint-down(lg) {
				min-height: 350px;
			}
		}

		@include media-breakpoint-up(lg) {
			margin: auto;
		}
	}
}