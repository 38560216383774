.glossary {
    &__header-overlay {
        background-image: url("../images/shape-lexicon-bg.png");
        background-position: top -130px right -150px;
        background-repeat: no-repeat;
        background-size: 440px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__header-wrap {
        background: #3c008d;
        background: linear-gradient(110deg,#3c008d,#1783bd 70%,#3ac7a7 85%,#70bc1f);
        width: 100%;
        top: 0;
    }

    &__overlay {
        background-image: url("../images/overlays/Homepage.jpg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        opacity: .1;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .definition-header {
        margin-top: 3.125rem;

        .icon-link {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        a {
            .icon {
                margin-right: .625rem;
            }
        }
    }

    .header {
        background: transparent;
        position: relative;
    }

    .heading {
        padding-bottom: 1.5625rem;
        padding-top: 6.75rem;

        .form-link {
            text-align: right;

            a {
                color: #fff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                .icon {
                    transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                }
            }
        }

        .subtitle {
            color: #fff;
            letter-spacing: .0025rem;
        }

        h1 {
            color: #fff;
            letter-spacing: .00625rem;
            line-height: 4.25rem;
        }
    }

    .keep-exploring {
        background: #3c008d;
        background: linear-gradient(110deg,#3c008d,#1783bd 70%,#3ac7a7 85%,#70bc1f);
        padding: 1.25rem;

        h6 {
            color: #efeeee;
            display: inline-block;
            font-size: 1.5rem;
            letter-spacing: .00313rem;
            line-height: 2.25rem;
            margin-right: 3.4375rem;
        }
    }

    .search-bar {
        background: #3d269a;
        border-top: 2px solid #fff;
        padding-top: 1.875rem;
        position: relative;

        .anchors {
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -webkit-overflow-scrolling: touch;

            &__wrapper {
                margin: .625rem auto;
                min-width: 55.625rem;
            }

            a {
                border: 1px solid transparent;
                border-radius: 1.25rem;
                color: #fff;
                display: inline-block;
                padding: .125rem .5rem;
                text-decoration: none;
                width: 1.875rem;

                .active {
                    background-color: #fff;
                    color: #3d269a;
                    transition: all .1s ease-in;
                    transition-delay: 0s;
                    transition-delay: .1s;
                }

                &:hover {
                    background-color: #fff;
                    color: #3d269a;
                }
            }

            span {
                color: hsla(0,0%,100%,.5);
                padding: .625rem;
            }
        }

        .search-field__animation {
            align-items: center;
            bottom: 0;
            color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-size: 1.125rem;
            left: 0;
            letter-spacing: 0;
            line-height: 1;
            padding-left: 2.5rem;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            -ms-flex-align: center;
            -webkit-box-align: center;
        }

        .search-field__animation-text {
            margin-left: .625rem;
        }
    }

    #content {
        .definition-list {
            &__item {
                border-bottom: 1px solid rgba(69,85,96,.4);
                margin-top: 2.5rem;
                padding-bottom: 1.875rem;

                &:first-child {
                    margin-top: .625rem;
                }

                &:last-child {
                    border: 0;
                }

                &:hover {
                    cursor: pointer;
                    position: relative;

                    &::before {
                        box-shadow: 0 2rem 2rem -3rem rgba(0,0,0,.5);
                        content: " ";
                        height: 80%;
                        left: 1%;
                        position: absolute;
                        top: 20%;
                        width: 96%;
                    }
                }

                h2 {
                    color: #323f49;
                    display: inline-block;
                    font-size: 1.875rem;
                    letter-spacing: .00375rem;
                    line-height: 2.625rem;
                    position: relative;
                    word-break: break-word;

                    a {
                        text-decoration: none;

                        .icon {
                            fill: #323f49;
                            height: 1.5625rem;
                            margin-left: .625rem;
                            transform: scaleX(-1);
                            vertical-align: middle;
                            width: 1.5625rem;
                            -webkit-transform: scaleX(-1);
                        }
                    }

                    span {
                        position: relative;
                    }
                }
            }

            .letter-header {
                h2 {
                    margin: 0;
                    overflow: hidden;
                    position: relative;

                    &::after {
                        background: #3c008d;
                        background: linear-gradient(90deg,#3c008d,#1783bd 70%,#3ac7a7 85%,#70bc1f);
                        content: "";
                        display: block;
                        height: 2px;
                        left: 3.125rem;
                        position: absolute;
                        top: 50%;
                        width: 100%;
                    }
                }
            }
        }

        .glossary-intro {
            border-bottom: 1px solid #094267;
            padding-bottom: 0;
        }

        .phonetic {
            color: #696765;
            font-family: Roboto,sans-serif;
            font-size: 1rem;
            letter-spacing: 0;
            margin-top: 0;
        }

        h1 {
            color: #323f49;
            font-family: vollkornregular,Times New Roman,serif;
            font-size: 2.25rem;
            letter-spacing: .005rem;
            line-height: 3rem;
            margin-top: .9375rem;
            word-break: break-word;
        }
    }

    #main-content {
        .definition-intro {
            p {
                font-family: vollkornregular,Times New Roman,serif;
                font-size: 1.1875rem;
                letter-spacing: 0;
                line-height: 1.875rem;
            }
        }
    }

    #sidebar {
        margin-top: -3.75rem;

        .form-cta {
            .icon-link {
                color: #130067;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                .icon {
                    margin-left: .625rem;
                }
            }
        }

        .section {
            padding: 1.875rem 0 .3125rem;
        }
    }
}

.search-page {
    .header {
        position: fixed;
        top: 0;
        width: 100%;
    }
}

#searchinput {
    background: rgba(0,0,0,.2);
    background-image: url("../images/graphs/icon-search.svg");
    background-position: center right 1.875rem;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 30px;
    color: #fff;
    font-family: Roboto-Regular;
    font-size: 1.125rem;
    letter-spacing: 0;
    outline: none;
    padding: .9375rem 2.1875rem;
    width: 100%;
}
