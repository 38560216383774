// Contact map

.contact-map {

	.contact-map__location {
		background: $grey-light;
		border-bottom: 3px solid $grey-light;
		cursor: pointer;
		padding: 1em 1.5em;
		transition: border $easing-regular;

		&:hover,
		&:focus {
			border-bottom: 3px solid map-get($brand, 'c');
		}

		&.active {
			background: map-get($brand, 'c');
			border-bottom: 3px solid map-get($brand, 'c');
			color: $white;
		}
	}

	.contact-map__map-container {
		min-height: 400px;

		> div {
			border-bottom: 4px solid $white;
		}

		&:after {
			@include gradient-flat();
			bottom: 0;
			content: "";
			display: block;
			height: 4px;
			left: 0;
			position: absolute;
			right: 0;
			width: 100%;
		}
	}
}

@include media-breakpoint-down(md) {
	.contact-map {
		.contact-map__locations {
			margin-bottom: 50px;
			position: relative;
		}

		.contact-map__location {
			padding: 1.5em 0.5em;
			position: static;
			text-align: center;

			h6, .h6 {
				font-size: rem(12);
				margin: 0;
			}

			&.active {
				.contact-map__address {
					background: map-get($brand, 'c');
					bottom: -50px;
					color: $white;
					display: block;
					height: 60px;
					left: 0;
					margin: 0;
					padding: 1em;
					position: absolute;
					right: 0;

					br {
						display: none;
					}
					
					&.contact-map__phone-number {
						margin-bottom: -35px;
						padding-top: 0;
					}
				}
			}
		}

		.contact-map__address-title-extras {
			display: none;
		}

		.contact-map__address {
			display: none;
		}
	}
}