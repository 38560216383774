// CTA Panel

.cta-panel {
	@include gradient-angle();
	color: $white;
	overflow: hidden;
	padding: 3.5em 0;
	position: relative;

	&.double {
		position: relative;
		z-index: 2;
	}

	.cta-panel__graph {
		background-image: url(../images/graphs/scatter-white.svg);
		background-size: 100%;
		height: 698px;
		opacity: 0.8;
		position: absolute;
		right: -117px;
		top: -159px;
		width: 696px;
	}

	.cta-panel__buttons {
		margin-top: 30px;
	}

	&__wwd {
		background: $grey-dark;
	}

	&__wwd--blue {
		background-color: map-get($brand, 'b');
	}

	&__wwd--green {
		background-color: map-get($brand, 'c');
	}

	&__wwd--purple {
		background-color: map-get($brand, 'a');
	}

	&__wwd-cta {
		color: $grey-light-extra;
		text-decoration: none;

		span {
			img {
				margin-left: 17px;
				padding-bottom: 2px;
			}
		}

		&:hover {
			span {
				img {
					filter: invert(86%) sepia(98%) saturate(7348%) hue-rotate(270deg) brightness(55%) contrast(115%);
					transition: filter .14s;
				}
			}
		}

		&:active {
			span {
				img {
					filter: invert(86%) sepia(98%) saturate(7348%) hue-rotate(270deg) brightness(55%) contrast(115%);
				}
			}
		}
	}

	&__wwd-holder {
		.col-lg-12,
		.col-lg-7 {
			padding-bottom: 5px;
			padding-left: 5px;
			padding-right: 5px;
		}

		.col-lg-5 {
			padding-bottom: 5px;
			padding-left: 5px;
			padding-right: 0px;
		}

		.col-lg-5-right {
			padding-left: 0px;
			padding-right: 5px;
		}

		@media (max-width: 900px) {
			.col-lg-12,
			.col-lg-7,
			.col-lg-5 {
				padding-bottom: 5px;
				padding-left: 15px;
				padding-right: 15px;
			}

			text-align: center;
		}
	}

	&__wwd-overlay {
		background-size: cover;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	&__wwd-content {
		position: relative;
		z-index: 5;
	}

	&__wwd-graph {
		left: 0;
		opacity: .5;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	&__wwd-graph--angled-pie {
		background-image: url(../images/graphs/angled-pie.svg);
		background-size: 100%;
		height: 851px;
		left: -390px;
		opacity: .3;
		top: -238px;
		width: 792px;
	}

	&__wwd-graph--overlapping-circle {
		background-image: url(../images/graphs/overlapping-circle.svg);
		background-size: 100%;
		height: 851px;
		left: -390px;
		opacity: .3;
		top: -238px;
		width: 792px;
	}

	&__wwd-graph--snail {
		background-image: url(../images/graphs/snail-white.svg);
		background-size: 100%;
		height: 851px;
		left: -390px;
		opacity: .3;
		top: -238px;
		width: 792px;
	}

	&__wwd-graph--tunnel {
		background-image: url(../images/graphs/tunnel.svg);
		background-size: 100%;
		height: 851px;
		left: -390px;
		opacity: .3;
		top: -238px;
		width: 792px;
	}
}

@include media-breakpoint-up(md) {
	.cta-panel.double {
		height: 100%;
	}
}

@include media-breakpoint-down(sm) {
	.cta-panel__wrapper {
		padding: 0;
	}
	.cta-panel__graph {
		display: none;
	}

	.cta-panel.double {
		margin: 5px 0;
	}
}