// Header

.header {
	@include gradient-angle();
	color: $white;
	position: relative;

	&__row {
		justify-content: center;

		@media (max-width: 600px) {
			justify-content: left;
		}
	}

	.header__nav {
		border-bottom: 2px solid $white;
		padding: 1.6em 0;
		position: relative;
		transition: background $easing-extra-slow;
		z-index: 15;

		@media screen and (min-width: 896px) {
			.container {
				max-width: 1000px;
			}
		}

		@include media-breakpoint-up(xl) {
			.container {
				max-width: 1200px;
			}
		}
	}

	.header__nav--solid {
		@include gradient-angle();
		top: 0px !important;
	}

	.header__social {
		border-left: 1px solid $white;
		text-align: center;

		ul {
			line-height: 1;
			list-style-type: none;

			li {
				display: inline-block;
				line-height: 1;
				margin: 0 4px;
			}
		}
	}

	.header__intro {
		padding: 6em 0 8em;
		position: relative;
		z-index: 5;

		h1 {
			font-family: $font-stack-header;
			line-height: 1;
		}
	}

	.header__subnav {
		background-color: $grey-transparent;
		padding: 1em 0;
		position: relative;
		z-index: 5;
	}

	&__search {
		border-left: 2px solid white;
		height: 35px;

		button {
			background: none;
            border: none;
			margin-top: 5px;
		}
	}
}

// Graphs
.header__graph {
	background-repeat: no-repeat;
	height: 100%;
	max-height: 100%;
	opacity: 0.8;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.header__graph--circle-line {
	background-image: url(../images/graphs/circle-line.svg);
	background-position: 508px -303px;
	background-size: 100%;
	opacity: 0.6;
	width: 1149px;
}

.header__graph--scatter {
	background-image: url(../images/graphs/scatter-colour-white.svg);
	background-position: 145px -151px;
	background-size: 100%;
	opacity: 0.6;
	width: 702px;
}

.header__graph--scatter-plain{
	background-image: url(../images/graphs/scatter-grey.svg);
	background-position: 145px -151px;
	background-size: 100%;
	opacity: 0.6;
	width: 803px;
}

.header__graph--overlapping-circle {
	background-image: url(../images/graphs/overlapping-circle-grey.svg);
	background-position: -189px -17px;
	background-size: 100%;
	opacity: 1;
	transform: scaleX(-1);
	width: 702px;
}

.header__graph--snail {
	background-image: url(../images/graphs/snail-colour.svg);
	background-position: 62px 34px;
	background-size: 100%;
	opacity: 0.4;
	width: 480px;
}

.header__graph--tunnel {
	background-image: url(../images/graphs/tunnel.svg);
	background-position: 172px -109px;
	background-size: 100%;
	opacity: 0.8;
	width: 575px;
}

// Overlays
.header__overlay {
	background-size: cover;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.header--sticky {
	padding-top: 6.75em;

	.header__nav {
		height: 6.75em;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.header__nav--solid {
		position: fixed;
	}
}

//header carousel
.header__bg-carousel {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;

	.slick-list,
	.slick-track {
		height: 100%;
	}
}

.header__bg-carousel-slide {
	outline: none;

	&:nth-child(1n+0) {
		@include gradient-angle();
	}

	&:nth-child(2n+0) {
		@include gradient-angle(220deg);
	}

	&:nth-child(3n+0) {
		@include gradient-angle(180deg);
	}
}

.header__carousel {
	z-index: 5;
}

.header__carousel-slide {
	display: none;
	outline: none;

	.header__intro {
		padding: 6em 0 8em;

		.btn {
			margin-top: 2em;
		}
	}

	&:first-child {
		display: block;
	}
}

@include media-breakpoint-down(lg) {
	.header__social {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.header__graph {
		display: none;
	}

	.header__carousel-slide, .header {
		.header__intro {
			padding: 1em 0 4em;
			overflow-wrap: break-word;
			h1 {
				font-size: 2.8rem;
			}
		}
	}
}

.scroll-adjust {
	top: 5px !important;
}

.scroll-adjust-mobile-menu {
	top: 108px !important;
}
