// Listing item

.listing-item {
	border-bottom: 1px solid $grey;
	padding: 4.5em 0;

	&:first-child {
		padding-top: 0;
	}

	.listing-item__header {
		a {
			@include gradient-text();
			display: inline-block;
			position: relative;
			text-decoration: none;

			&:after {
				@include gradient-flat();
				bottom: 0;
				content: "";
				height: 2px;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				transform: translateY(-5px);
				transition: all $easing-regular;
			}

			&:hover:after,
			&:focus:after {
				opacity: 1;
				transform: translateY(0);
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				background: none;
				color: $purple;
			}
		}
	}

	.listing-item__category-wrapper {
		padding-top: 100%;
		position: relative;
		width: 100%;
	}

	.listing-item__category {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.listing-item__category-image {
		height: 100%;
		left: 0;
		opacity: 0.3;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.listing-item__category-item {
		color: $white;
		max-width: 50%;
		position: relative;
		text-align: center;
		text-transform: uppercase;
	}

	.listing-item--blue {
		background-color: map-get($brand, 'b');
	}

	.listing-item--purple {
		background-color: map-get($brand, 'a');
	}

	.listing-item--green {
		background-color: map-get($brand, 'c');
	}

	&.listing-item--case-study {
		.listing-item__category {
			background-color: map-get($brand, 'a');
		}
	}

	&.listing-item--news-and-insights {
		.listing-item__category {
			background-color: map-get($brand, 'c');
		}
	}

	&.listing-item--chart-of-the-month, &.listing-item--top-charts {
		.listing-item__category {
			background-color: $grey;
		}
	}

	&.listing-item--events {
		.listing-item__category {
			background-color: map-get($brand, 'b');
		}
	}

	//NEWS & INSIGHTS SPECIFIC STYLE
	.listing-items__news {
		row-gap: 1.5em;
		column-gap: 2.5em;
		
		a {
			text-decoration: none;
		}
		
		.listing-items__news-card {
			background: $grey-light;
			border-radius: 10px;
			height: 28.75em;
			margin-block-end: 1em;
			width: 22.125em;
			overflow: hidden;
			padding: 0 3em;
			position: relative;
			z-index: 1;

			&:after {
				@include gradient-newscard();
				content: "";
				left: 0;
				top: 0;
				height: 100%;
				width: 16px;
				transition: width $easing-slow;
				position: absolute;
				z-index: -1;
			}

			@media (min-width: 900px) {
				&:hover {

					h4 {
						background-image: none;
						background-color: $white;
						text-decoration-color: rgba(255, 255, 255, 1);
					}

					p, .alt {
						color: $white;
					}

					&:after {
						width: 100%;
					}
				}
			}

			h4 {
				@include gradient-newstext();
				background-color: transparent;
				font-family: $font-stack-header;
				font-size: rem(24);
				letter-spacing: 0.075px;
				text-decoration: underline 0.05em rgba(255, 255, 255, 0);
				text-underline-offset: .4em;
				transition: all $easing-slow;
			}

			p {
				font-family: $font-stack-regular;
				font-size: 0.875em;
				line-height: 1.125em;
				text-decoration: none;
				transition: all $easing-slow;

				&.alt {
					color: #8797A3;
					font-family: $font-stack-regular;
					text-decoration: none;
					transition: all $easing-slow;					
				}
			}
		}
	}
}

.listing-filter {
	overflow: hidden;

	.filter-content {
		margin-block-end: 2em;
		padding-block-end: 1em;
		
		select {
			//for the dropdown chevron
			appearance: none;
			background-color: $white; //cause firefox has to be TOLD WHAT TO DO
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right .5em center;
			background-size: 1em;
			//actual dropdown styles
			border: 1px solid #455560;
			box-sizing: border-box;
			border-radius: 5px;
			font-family: $font-stack-regular;
			height: 2.875em;
			width: 15em;
			margin-right: .5em;
			margin-bottom: 1em;
			padding: 0 .75em;
		}

		.filter-content-box {
			align-items: flex-end;
			margin-bottom: 2.5em;

			.btn-filter {
				width: 11.25rem;
				justify-content: center;
				text-align: center;
				padding: 0.75em 2em;
				margin-right: 1.5em;
				margin-bottom: 1em;
			}

			h5 {
				color: $font-color;
				font-family: $font-stack-header;
				line-height: 3rem
			}

			p {
				font-size: 0.875rem;
			}
		}		
	}
	
	.list-title {
		.list-title-content {
			align-items: flex-end;

			.display {
				background-color: $white; //cause firefox has to be TOLD WHAT TO DO
				border: 1px solid #455560;
				border-radius: 2px;
				box-sizing: border-box;
				color: $font-color;
				font-family: $font-stack-regular;
				width: 3.35em;
				margin: 0 0.25em;
				padding: 0.375em;
			}

			h3 {
				color: $font-color;
				font-family: $font-stack-header;
				line-height: 2.25rem
			}

			p {
				font-size: 0.875rem;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.listing-item__category-wrapper {
		display: none;
		margin-bottom: 1.5em;
	}

	.btn-filter {
		width: 100% !important;
	}

	.filter-content {	
		select {
			width: 100% !important;
		}
	}
}