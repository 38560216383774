.navigation-pagination {
	align-items: center;
	display: flex;
	font-size: rem(16);
	justify-content: center;
	margin: 4.5em 0;

    ul,
    li {
        list-style-type: none;
    }

	&__list {
		margin: 0 10px;
		padding-left: 0;
		align-items: center;
		display: flex;
		justify-content: center;
	}

	&__item {
        margin-left: 0.625em;
        margin-right: 0.625em;
    }

	.dots {
		@media (max-width: 600px) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__link {
		border: 2px solid #455560;
        border-radius: 50%;
        color: $grey;
        text-align: center;
        text-decoration: none;
        width: 2.5em;
        height: 2.5em;
        padding: 2px 2px 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;

		&:hover,
        &:focus {
			@include gradient-newsbutton();
            color: $white;
		}
	}

	&__link-active {
		background-color: #455560;
		color: $white;
		border: 2px solid #455560;
        border-radius: 50%;
        text-align: center;
        text-decoration: none;
        width: 2.5em;
        height: 2.5em;
        padding: 2px 2px 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
	}

	&__dots {
		@media (min-width: 600px) {
			align-items: center;
			border: 2px solid #455560;
			border-radius: 50%;
			color: $grey;
			display: flex;
			height: 2.5em;
			justify-content: center;
			padding: 2px 2px 0 2px;
			text-align: center;
			text-decoration: none;
			width: 2.5em;
		}
	}

	&__arrow {
        position: relative;
		width: 1.5em;

		@media (min-width: 600px) {
			&:focus,
			&:hover {
				width: 2.5em;

				img {
					&:first-child {
						display: none;
					}

					&:last-child {
						display: initial;
					}
				}
			}
		}

		&--previous {
			margin-left: 20px;
			margin-right: 20px;

			img {
				position: absolute;
				right: 0px;
				top: 1px;
			}

			@media (max-width: 600px) {
				margin-left: 40px;
				margin-right: 10px;
			}
		}

		&--next {
			padding-left: 20px;

			img {
				position: absolute;
				top: 1px;
			}

			@media (max-width: 600px) {
				padding-left: 10px;
			}
		}

		img {
			&:first-child {
				display: initial;
			}

			&:last-child {
				display: none;
			}

			&:only-child {
				display: initial;
			}
		}

	}

	&__icon {
		color: $grey;
		fill: $black;
		display: block;
		height: 15px;
		width: 15px;
		position: relative;
		top: 50%;
		margin-top: -8px;
		left: 50%;
		margin-left: -8px;
	}

	&__icon-right {
		transform: scaleX(-1);
	}

	&__disabled {
		color: $grey;
		pointer-events: none;

		.navigation-pagination__icon {
			fill: $grey;
		}
	}

    &__item-dots {
		border: none;
	}

	// @include media-breakpoint-down(sm) {
	// 	&__icon {
	// 		height: 18px;
	// 		top: 45%;
	// 		width: 22px;
	// 	}

	// 	&__item {
	// 		font-size: 22px;
	// 		margin: 0 10px;
	// 	}

	// 	&__prev,
	// 	&__next {
	// 		bottom: 100%;
	// 		font-size: 28px;
	// 		position: absolute;
	// 	}

	// 	&__prev {
	// 		right: 70%;

	// 		.navigation-pagination__icon {
	// 			margin-left: -20px;
	// 		}
	// 	}

	// 	&__next {
	// 		left: 70%;

	// 		.navigation-pagination__icon {
	// 			margin-left: 0px;
	// 			margin-right: 20px;
	// 		}
	// 	}
	// }
}
