// Share panel

.share-panel {
	color: $grey;
}

.share-panel__links {
	line-height: 1;
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		line-height: 1;
		margin: 0 1em;

		.icon {
			fill: $grey;

			&.icon--email {
				stroke: $grey;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.share-panel__links {

		li {
			margin: 0 0.5em;
		}
	}
}