.cookie-notification {
	background: #212121;
	bottom: 0;
	color: #fff;
	opacity: 0;
	position: fixed;
	text-align: left;
	transition: opacity 1s;
	width: 100%;
	z-index: 1001;
	display: none;

	&.show-notification {
		opacity: 1;
		display: block;
	}

	.cookie-notification-hide {
		text-align: center;

		a {
			margin-right: 10px;
		}
	}

	a {
		color: #fff;
	}
}

@include media-breakpoint-down(sm) {

	.cookie-notification {

		.cookie-notification-hide {
			padding-bottom: 36px;
			padding-top: 20px;
		}
	}

}