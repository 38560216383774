// Clickdimension form overrides
#content .clickdform {

	.responsiveCell {
		display: block;

		div.alignTop {
			display: block;
		}
	}

	.responsiveRow {
		display: block;
		font-size: 1em;

		&:before {
			clear: both;
			content: ' ';
			display: table;
		}
	}

	.responsiveCellSize1 {
		min-width: 0;
		width: auto;
	}

	.minSize1 {
		min-width: 0;
		width: auto;
	}

	.maxSize1 {
		max-width: none;
	}

	.requiredStar {
		font: inherit;
		color: inherit;
		padding: 0;
	}

	.floatLeft {
		float: none;
	}

	// I'm not proud of this
	span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#000000;"] {
		font-family: inherit !important;
		font-size: 1em !important;
		color: inherit !important;
		margin-bottom: 1em !important;
	}

	span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#455560;"] {
		font-family: Arial !important;
	}

	// Or this
	textarea[style="height:100px; font-family:Verdana;"] {
		height: auto !important;
		font-family: inherit !important;
	}

	// Why is it even doing this?
	div[style="font-size:13px"] {
		font-size: 1em !important;
	}

	input {
		max-width: none;
	}

	// Holy moly
	font {
		font-family: inherit !important;
		font-size: inherit !important;
		color: inherit !important;
	}

}



#content .clickdform {
	// Field defaults

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	.responsiveRow {
		margin-bottom: 1em;
		margin-top: 1em;

		label,
		input[type="text"],
		input[type="email"],
		textarea {
			display: block;
		}

		input[type="text"],
		input[type="email"],
		textarea {
			color: inherit !important;
			font-family: inherit !important;
			font-size: inherit !important;
			background-color: transparent;
			border: 1px solid $grey;
			border-radius: 5px;
			height: 3.29em;
			line-height: 3.29em;
			padding-left: 1.75em;
			padding-right: 1.75em;
			resize: none;
			width: 100%;

			&:disabled {
				cursor: not-allowed;
				opacity: 0.3;
				pointer-events: none;
			}
		}

		textarea {
			line-height: 1.9;
			min-height: 13em;
			padding-bottom: 1em;
			padding-top: 1em;
		}


	}
}


#content .clickdform {
	input.floatLeft[type="checkbox"] {

		+ span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#000000;"] {
			font-famiyl: inherit !important;
			font-size: inherit !important;
			color: inherit !important;
			padding-left: .5em;
			position: relative;
		}
	}
}


#content .clickdform .buttonContainer {
	font-family: inherit !important;
	font-size: inherit !important;
	background-color: $white;
	border-radius: 10em;
	display: inline-block;
	margin-right: 2px !important;
	margin-top: 25px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;

	.lt-ie9 & {
		color: $white;
	}

	&:before {
		@include gradient-angle();
		background-size: 200%;
		border-radius: 10em;
		bottom: -2px;
		content: '';
		left: -2px;
		position: absolute;
		right: -2px;
		top: -2px;
		transition: .28s;
		z-index: -1;
	}

	input[type="button"] {
		background: transparent !important;
		border: none;
		color: inherit !important;
		cursor: pointer;
		margin: 0 !important;
		outline: none;
		padding: 0.75em 2em;
		font-family: inherit !important;
		font-size: 1em !important;
	}

	&:hover {
		background-color: transparent;

		input[type="button"] {
			color: $white !important;
		}
	}
}