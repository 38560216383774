// Offset split panels

.offset-strip {
	overflow: hidden;

	.offset-strip__panel {
		align-items: center;
		background-color: $white;
		display: flex;
		padding: rem(60) 10%;
		position: relative;
		text-align: center;
	}

	.offset-strip__panel--purple {
		background-color: map-get($brand, 'a');
		color: $white;
		overflow: hidden;
	}

	.offset-strip__panel--blue {
		background-color: map-get($brand, 'b');
		color: $white;
		overflow: hidden;
	}

	.offset-strip__panel--green {
		background-color: map-get($brand, 'c');
		color: $white;
		overflow: hidden;
	}

	.offset-strip__panel-cta,
	.offset-strip__panel-stat,
	.offset-strip__panel-quote {
		position: relative;
		z-index: 5;
	}

	.offset-strip__copy {
		margin: 2em 0;

		&.final {
			margin-bottom: 0;
		}
	}

	.offset-strip__panel-stat {
		display: block;
		width: 100%;

		p {
			font-size: rem(24);
			font-weight: bold;
			margin: 0;

			&.value {
				@include gradient-text();
				font-size: rem(40);

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					background: none;
					color: $purple;
				}
			}
		}
	}

	.offset-strip__panel-quote {
		font-size: rem(30);
		font-weight: bold;
	}

	.offset-strip__overlay {
		background-size: cover;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	.offset-strip__graph {
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	.offset-strip__graph--scatter-colour {
		background-image: url(../images/graphs/scatter-colour.svg);
		background-size: 100%;
		height: 835px;
		left: -99px;
		opacity: 0.15;
		top: -248px;
		transform: scaleX(-1);
		width: 832px;

		&.offset-strip__graph--right {
			left: auto;
			right: -99px;
			transform: none;
		}
	}

	&.offset-strip__wideblue {
		@include gradient-angle;

		.offset-strip__panel {
			background: transparent;
			color: #fff;
			padding: rem(50) 10% rem(40);
		}

		.container-fluid {
			//background-image: url('../images/shape-lexicon-bg.png');
			background-repeat: no-repeat;
			background-position: center right rem(-130);
		}
	}

	&.offset-strip__whitethree {
		background: #f4f4f4;
		max-height: 34.75em;
    	padding-bottom: 1em;

		.offset-strip__panel {
			background: transparent;
			padding: rem(40) 10% rem(40);
		}
	}

	h3, h5 {
		font-family: $font-stack-header;
	}

	&.offset-strip__wideblue {
		@include gradient-angle;

		.offset-strip__panel {
			background: transparent;
			color: #fff;
			padding: rem(50) 10% rem(40);
		}

		.container-fluid {
			background-image: url('../img/shape-lexicon-bg.png');
			background-repeat: no-repeat;
			background-position: center right rem(-130);
		}
	}

	.offset-strip__graph--angled-pie {
		background-image: url(../images/graphs/angled-pie.svg);
		background-size: 100%;
		height: 851px;
		left: -390px;
		opacity: 0.3;
		top: -238px;
		width: 792px;
	}

	.offset-strip__heading {
		margin-bottom: 0;
	}

	.offset-strip__subheading {
		p {
			margin: 0;
			padding: 0;
			font-size: rem(18);
		}
	}

	.strip-news {

		.offset-strip__news-heading {
			padding-bottom: 1em;
		}

		.offset-strip__news {
			a {
				text-decoration: none;
			}

			.offset-strip__news-card {
				background: $grey-light;
				border-radius: 10px;
				margin-block-end: 1em;
				height: 26em;
				max-width: 25em;
				overflow: hidden;
				padding: 0 1.5em 0 2.5em;
				position: relative;
				z-index: 1;

				&:after {
					@include gradient-newscard();
					content: "";
					left: 0;
					top: 0;
					height: 100%;
					width: 16px;
					transition: width $easing-slow;
					position: absolute;
					z-index: -1;
				}

				&:hover {
					h4 {
						background-image: none;
						background-color: $white;
						text-decoration-color: rgba(255, 255, 255, 1);
					}

					p, .alt {
						color: $white;
					}

					&:after {
						width: 100%;
					}
				}

				h4 {
					@include gradient-newstext();
					background-color: transparent;
					font-family: $font-stack-header;
					font-size: rem(26);
					letter-spacing: 0.075px;
					text-decoration: underline 0.05em rgba(255, 255, 255, 0);
					text-underline-offset: .4em;
					transition: all $easing-slow;
				}

				p {
					color: $font-color;
					font-size: rem(12);
					margin: 0.5em 0;
					transition: all $easing-slow;

					&.alt {
						color: #8797A3;
						transition: all $easing-slow;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.offset-strip {
		.offset-strip__panel {
			padding: 40px 10%;
		}
	}

	.offset-strip__whitethree {
		height: initial;
	}
}
