.featured-article {
    position: relative;
    top: -4em;
    z-index: 2;

    .featured-panel {
        max-height: 22em;

        .featured-panel-card {
            background: $white;
            border-radius: 5px;
            max-width: 64.375em;
            overflow: hidden;
            padding: 0 1em 0.75em;
            position: relative;
            z-index: 1; 
            box-shadow: 0px 6px 11px 0px #69676580;

            &:after {
                @include gradient-newscard();
                content: "";
                left: 0;
                top: 0;
                height: 100%;
                width: 16px;
                position: absolute;
                z-index: -1;
            }

            h2, h5 {
                @include gradient-newstext();
                font-family: $font-stack-header;
                width: fit-content;
                margin: 0;
            }

            p {
                color: $font-color;
                font-family: $font-stack-regular;
                margin: .65em 0;
                font-size: 0.93rem;

                &.cat {
                    text-transform: uppercase;
                    font-size: .85rem;
                }
            }

            a {
                text-decoration: none;
            }

            .break--gradient {
                height: 1px;
                margin: 0;
                width: 85%;
            }

            .btn--news {
                width: 11.25rem;
                text-align: center;
                margin-top: 0.5em;
                margin-left: -0.35em;
            }

            .featured__image {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .featured-article {
        margin: 0 1em;
        z-index: 2;

        .featured-panel {
            margin-top: 4.063em;
            max-height: initial;

            .featured-panel-card {
                padding: 0.75em 1em 2em 1em;

                &:after {
                    display: none;
                }

                .btn--news {
                    width: 100%;
                    margin: 0;
                }

                .featured__image {
                    width: 17.5em;
                    display: block;
                }
            }
        }    
    }
}