.location-banner {
    position: relative;
    display: none;
    background-color: $indigo;
    color: $white;
    padding: 10px 0;
    z-index: 1000;

    &--show {
        display: block;
    }

    &__select-item {
        border-radius: 5px;
        padding: 10px 5px;
        width: 270px;

        @media (max-width: 440px) {
            width: 100%;
        }
    }

    &__confirm-btn {
        background-color: $indigo;
        color: $white;
        width: 100%;
    }

    .btn {
        border: $white 1px solid;
    }
}
