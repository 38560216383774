.international {

    @include gradient-angle(122deg);
    color: $white;
    min-height: 100vh;
    position: relative;

    &__background {

        &:before {
            background: transparent url(/includes/img/international/world-map.png) no-repeat;
            background-position: center 266px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:after {
            background: transparent url(/includes/img/international/dots.png) no-repeat;
            background-position: center 232px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 100%;
        }

    }

    &__nodes {

        height: 392px;
        left: 50%;
        margin-left: -580px;
        position: absolute;
        top: 325px;
        width: 1013px;
        max-width: 100%;


        &:after {
            background: url(/includes/img/international/nodes.png);
            content:'';
            height: 100%;
            left: -7px;
            position: absolute;
            top: -8px;
            width: 100%;
        }


        &__highlight {
            background-repeat: no-repeat;
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &.highlighted {
                display: block;
            }

            &[data-highlight="canada"] {
                background-image: url(/includes/img/international/lines/canada.png);
            }

            &[data-highlight="united-kingdom"] {
                background-image: url(/includes/img/international/lines/united-kingdom.png);
            }

            &[data-highlight="united-states"] {
                background-image: url(/includes/img/international/lines/united-states.png);
            }
        }
    }

    .header {
        background: transparent;

        .header__nav {
            @include gradient-angle(122deg);
            background-size: 100% 100vh;
            transition: none;
        }
    }

    & .location-selector {

        border-bottom: 1px solid $white;
        display: flex;
        list-style: none;
        padding: 0 0 3em 0;
        margin-bottom: 3em;
        margin-top: 2em;

        &__option {
            display: block;
            width: 33.33%;
            padding: 0;
            margin: 0;
        }

        &__link {
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;
            margin: 0 auto;
            max-width: 120px;

            img {
                height: 84px;
                width: 84px;
            }

            &:hover {
                p {
                    text-decoration: underline;
                }
            }
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            padding-left: rem(36);
            padding-right: rem(36);

            &__option {
                margin: rem(11) 0;
                width: 100%;
            }

            &__link {
                flex-direction: row;
                max-width: none;

                img {
                    height: 46px;
                    width: 46px;
                    margin: 0 rem(18);
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}
