// Buttons
@keyframes hover {
    0%{background-position:0% 50%}
    50%{background-position:20% 50%}
    100%{background-position:0% 50%}
}

// Default button
.btn,
.btn:visited {
	background-color: $white;
	border-radius: 10em;
	color: $font-color;
	cursor: pointer;
	display: inline-block;
	font-size: rem(14);
	padding: 0.75em 2em;
	position: relative;
	text-decoration: none;
	transition: $easing-regular;

	&:before {
		@include gradient-angle();
		background-size: 100%;
		border-radius: 10em;
		bottom: -2px;
		content: '';
		left: -2px;
		position: absolute;
		right: -2px;
		top: -2px;
		transition: $easing-slow;
		z-index: -1;
	}

	&:after {
		border-radius: 10em;
		border: 2px solid transparent;
		bottom: -4px;
		content: '';
		left: -4px;
		position: absolute;
		right: -4px;
		top: -4px;
		transition: $easing-regular;
	}

	// Style to space adjacent buttons
	+ .btn {
		margin-left: 1.5em;
	}
}

.btn:hover,
.btn:focus {
	background: transparent;
	color: $white;
	text-decoration: none;
}

.btn:active {
	&:after {
		border-color: white;
		bottom: 0px;
		left: 0px;
		right: 0px;
		top: 0px;
	}
}

// Additional style for <button> element
button.btn {
	border: none;
	outline: none;
}

// Block button
.btn--block,
.btn--block:visited {
	display: block;
	width: 100%;
}

// Light button
.btn.btn--light {
	background: transparent;
	border: 2px solid $white;
	color: $white;

	&:before,
	&:after {
		display: none;
	}

	&:hover,
	&:focus {
		background-color: $white;
		color: $black;
	}
}

// Dark button
.btn.btn--dark {
	background: transparent;
	border: 2px solid $font-color;
	padding-left: 40px;
	padding-right: 40px;

	&:before,
	&:after {
		display: none;
	}

	&:hover,
	&:focus {
		background-color: $font-color;
	}
}

// Squared button
.btn.btn--squared {
	background: transparent;
	border: 2px solid $grey-light;
	border-radius: 5px;

	&:before,
	&:after {
		display: none;
	}

	&:hover,
	&.active,
	&:focus {
		border-color: $black;
		color: $black;
	}
}

//News and insights button
.btn--news {	
	border: none;
	border-radius: 10em;
	cursor: pointer;
	display: inline-block;	
	font-weight: 500;
	@include gradient-newsbutton();
	outline: none;
	padding: 0.75em 2em;
	position: relative;	
	z-index: 1;

	&:before {
		background-color: white;
		border-radius: 8em;
		content: "";
		bottom: 2px;
		left: 2px;
		right: 2px;
		top: 2px;
		position: absolute;
		transition: $easing-regular;
		z-index: -1;
	}

	&:after {
		content: attr(data);
		font-size: rem(14);
		@include gradient-newstext();
		transition: $easing-regular;
	}
}

  .btn--news:hover:before {
	opacity: 0%;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
  }
  
  .btn--news:hover:after {
	background-image: none;
	background-color: $white;
  }