$location-switcher-background:     #201751;
$location-switcher-highlight:      #130D36;

.location-switcher {

    position: relative;

    &:hover {

        .location-switcher__current {
            background: $location-switcher-background;
            border-left-color: transparent;
            border-right-color: transparent;

            &:after {
                transform: rotateZ(-45deg);
            }

            &:before {
                display: block;
            }
        }

        .location-switcher__locations {
            display: block;
        }
    }

    &__current {
        background-color: #1D1648;
        border-radius: 50px;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;
        width: 90px;

        img {
            height: 28px;
            width: 28px;
        }

        &:after {
            border-top: 2px solid $white;
            border-right: 2px solid $white;
            border-radius: 2px;
            content: '';
            height: 8px;
            position: absolute;
            right: 15px;
            top: 20px;
            transform: rotateZ(135deg);
            width: 8px;
        }

        &:focus,
        &:active {
            background: $location-switcher-background;
            border-left-color: transparent;
            border-right-color: transparent;

            &:after {
                transform: rotateZ(-45deg);
            }
        }

        @include media-breakpoint-down(sm) {

            &:before {
                background: $location-switcher-background;
                box-sizing: content-box;
                border-left: 2px solid $location-switcher-background;
                border-right: 2px solid $location-switcher-background;
                content: '';
                display: none;
                height: 94px;
                left: -2px;
                position: absolute;
                top: -20px;
                width: 100%;
                z-index: -1;
            }

            &:focus,
            &:active {
                &:before  {
                    display: block;
                }
            }
        }
    }

    &__locations {
        background: $location-switcher-background;
        display: none;
        right: 0;
        list-style: none;
        padding: rem(7) 0;
        position: absolute;
        top: 105%;
        width: 218px;
        z-index: 401;

        .location-switcher__current:focus + & {
            display: block;
        }

        @include media-breakpoint-down(sm) {
            position: fixed;
            top: 105px;
            left: 0;
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            top: 105% !important;
        }
    }

    &__location {
        align-items: center;

        &:hover {
            background-color: $location-switcher-highlight;
        }
    }

    &__link {
        color: white;
        display: flex;
        flex-direction: row;
        padding: rem(7) 0 rem(7) rem(24);
        text-decoration: none;

        img {
            height: 25px;
            width: 25px;
            margin-right: rem(17);
        }

        p {
            margin: 0;
            font-size: rem(14);
        }

        &--active {
            p {
                text-decoration: underline;
            }
        }

        &:hover,
        &:active,
        &:focus {
            color: $white;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            max-width: 200px;
            margin: 0 auto;
        }
    }
}
