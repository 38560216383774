#results {
	display: none;
	max-height: calc(100vh - 402px);
	overflow-y: scroll;
	border-bottom: 4px solid #3C269A;
	padding: rem(40) rem(40) rem(20) rem(40);
	width: 88%;
	background: #fff;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;

	.sticky-search & {
		max-height: calc(100vh - 195px);
	}

	.search-active & {
		display: block;
		@include media-breakpoint-down(md) {
			max-height: calc(100vh - 195px);
		}
	}

	@include media-breakpoint-down(md) {
		.search-active & {
			max-height: calc(100vh - 195px);
		}
	}

	@include media-breakpoint-down(md) {
		width: 85%;
		padding: rem(20);
	}
	@include media-breakpoint-down(sm) {

		width: 100%;
		top: rem(101);
		max-height: calc(100vh - 337px);

		.sticky-search & {
			max-height: calc(100vh - 175px);
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			margin-bottom: rem(20);
			padding-bottom: rem(20);

			@include media-breakpoint-down(sm) {
				margin-bottom: rem(20);
				padding-bottom: 0;
			}


			h3 {
				font-family: $font-stack-header;
				font-size: rem(24);
				color: #455560;
				letter-spacing: rem(0.05);
				line-height: rem(36);
			}

			p {
				font-family: Roboto-Regular;
				font-size: rem(16);
				color: #455560;
				letter-spacing: rem(0.04);
				line-height: rem(24);

				@include media-breakpoint-down(sm) {
					display: none;
				}

			}

			a {
				text-decoration: none;
			}

			&:hover {
				cursor: pointer;
				box-shadow: 0 rem(18) rem(33) rem(-28) rgba(0,0,0,0.3);

				a {
					text-decoration: underline;
					// @include gradient-text();
				}
			}

			span {
				background: #D0CCE1;
			}
		}
	}

	#no-results-message {
		h5 {
			font-size: rem(26);
			color: #323F49;
			letter-spacing: rem(0.05);
			line-height: rem(36);
		}

		.popular {
			margin-top: rem(40);

			h6 {
				font-size: rem(21);
				letter-spacing: rem(0.05);
				line-height: rem(30);
			}

			a {
				display: inline-block;
				margin-right: rem(20);
				margin-bottom: rem(10);
			}
		}
	}

}

.search-active #content::after {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
}

body.search-active {
	overflow: hidden;
}
