.glossary {
	&.search-page {
		#content {
			padding-top: rem(50);
		}
	}

	#content {
		background: #fff;
		padding-bottom: rem(60);
		position: relative;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			padding-top: rem(15);
			padding-bottom: rem(20);
		}

		h1 {
			@include media-breakpoint-down(sm) {
				font-size: rem(42);
				letter-spacing: rem(0.09);
				line-height: rem(48);
			}
		}

		.glossary-intro {
			border-bottom: 1px solid $blue-dark;
			padding: 2em 0;
			margin: 0;

			@include media-breakpoint-down(sm) {
				padding-top: rem(1);
			}

			h1 {
				font-size: rem(36);
				font-family: $font-stack-header;
				letter-spacing: rem(0.08);
				line-height: rem(48);
				margin-top: rem(15);
				word-break: break-word;

				@include media-breakpoint-down(sm) {
					font-size: rem(24);
					letter-spacing: rem(0.05);
					line-height: rem(36);
				}
			}

			.phonetic {
				font-family: Roboto-Regular;
				font-size: rem(16);
				color: $grey;
				letter-spacing: 0;
				margin-top: 0;
			}
		}

		.definition-list {
			.letter-header {
				h2 {
					position: relative;
					overflow: hidden;
					margin: 0;
				}

				h2:after {
					background: #3c008d;
					background: linear-gradient(90deg, #3c008d, #1783bd 70%, #3ac7a7 85%, #70bc1f);
					content: "";
					display: block;
					height: 2px;
					left: rem(50);
					position: absolute;
					top: 50%;
					width: 100%;
				}
			}
			&__item {
				margin-top: rem(40);
				padding-bottom: rem(30);
				border-bottom: 1px solid rgba(69, 85, 96, 0.4);

				&:first-child {
					margin-top: rem(10);
				}

				&:last-child {
					border: 0;
				}

				h2 {
					font-size: rem(30);
					color: #323f49;
					letter-spacing: rem(0.06);
					line-height: rem(42);
					position: relative;
					display: inline-block;
					word-break: break-word;

					span {
						position: relative;

					}

					a {
						text-decoration: none;

						&:hover {
							text-decoration: none;
						}

						&:focus {
							span {
								@include gradient-text();

								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									background: none;
									color: #130067;
								}

								&::after {
									@include gradient-flat();
									content: "";
									display: block;
									height: 2px;
									left: 0;
									position: absolute;
									bottom: 0;
									width: 100%;
								}
							}
						}

						.icon {
							fill: #323f49;
							transform: scaleX(-1);
							vertical-align: middle;
							width: rem(25);
							height: rem(25);
							margin-left: rem(10);
						}
					}
				}

				p {
					a {
						text-decoration: none;
						color: #130067;

						&:hover, &:focus {
							text-decoration: underline;
						}
					}
				}

				.content-parent {
					.icon {
						width: rem(20);
						height: rem(20);
						margin-left: rem(10);
						vertical-align: text-top;
					}
				}

				@media (hover: hover) {
					&:hover {
						cursor: pointer;
						position: relative;
						&:before {
							box-shadow: 0 2rem 2rem -3rem rgba(0,0,0,.5);
							position: absolute;
							height: 80%;
							width: 96%;
							top: 20%;
							left: 1%;
							content: ' ';
						}
						span {
							@include gradient-text();

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								background: none;
								color: #130067;
							}

							&::after {
								@include gradient-flat();
								content: "";
								display: block;
								height: 2px;
								left: 0;
								position: absolute;
								bottom: 0;
								width: 100%;
							}
						}

					}
				}
			}
		}
	}

	.definition-header {
		margin-top: rem(50);

		@include media-breakpoint-down(sm) {
			margin-top: 0;
		}

		a {
			.icon {
				margin-right: rem(10);
			}

			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}

	#main_content {
		margin-top: rem(10);

		.definition-intro {
			p {
				font-family: $font-stack-header;
				font-size: rem(19);
				letter-spacing: 0;
				line-height: rem(30);
			}
		}

		.content-parent {
			h3 {
				font-size: rem(20);
				color: $grey-dark;
				letter-spacing: rem(0.04);
				line-height: rem(39);
			}

			h2 {
				font-size: rem(30);
				color: $grey-dark;
				letter-spacing: rem(0.04);
				line-height: rem(39);
			}

			.icon-link {
				color: #130067;
				display: inline-block;
				margin-top: rem(40);

				.icon {
					margin-left: rem(10);
				}

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}

		.image-wrapper {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	#sidebar {
		margin-top: rem(-60);

		@include media-breakpoint-down(sm) {
			margin: 0;
		}

		.section {
			padding: rem(30) 0 rem(5);
			margin: 0;
		}

		h3 {
			font-family: $font-stack-header;
			font-size: rem(24);
			color: #323f49;
			letter-spacing: rem(0.05);
			line-height: rem(36);
		}

		.glossary-related-terms {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					margin: rem(12) 0;

					a {
						color: $grey;
						letter-spacing: rem(0.04);
					}
				}
			}
		}

		.share-panel {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					display: inline-block;
					margin-right: rem(15);
				}
			}

			.icon--social {
				&:hover, &:focus {
					color: #130067;
					fill: #130067;
				}
			}

			.icon--email {
				&:hover, &:focus {
					stroke: #130067;
				}
			}
		}

		.form-cta {
			p {
				margin-top: 0;
			}

			.icon-link {
				color: #130067;

				.icon {
					margin-left: rem(10);
				}
				@media (hover: hover) {
					&:hover {
						text-decoration: underline;
					}
				}
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}

	&--related-content {
		margin-top: rem(60);

		@include media-breakpoint-down(sm){
			margin-top: rem(40);

			h2 {
				font-size: rem(36);
				letter-spacing: rem(0.08);
				line-height: rem(42);
			}
		}

		&__wrapper {
			display: flex;
			margin: rem(40) auto rem(50);
			overflow: auto;
			max-width: 100%;

			&:hover {
				cursor: pointer;
			}

			-ms-overflow-style: none;  // IE 10+
			scrollbar-width: none;  // Firefox

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__items {
			display: flex;
			margin: 0 auto;
		}

		.related-item {
			margin: 0 rem(13);
			padding: rem(25) rem(15) rem(20);
			display: inline-block;
			width: rem(260);
			height: rem(295);
			vertical-align: top;
			position: relative;
			background-size: cover;

			@include media-breakpoint-down(sm){
				margin: 0 rem(5);
			}

			&:hover, &:focus {
				.icon-link {
					text-decoration: underline;
				}
			}

			.gradient-overlay {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				background: rgb(0, 0, 0);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			}

			.icon-link {
				font-family: $font-stack-header;
				font-size: rem(20);
				letter-spacing: rem(0.04);
				line-height: rem(27);
				position: relative;

				&:hover, &:focus {
					text-decoration: underline;
				}

				.icon {
					transform: scaleX(-1);
					margin-left: rem(5);
				}
			}

			&-type {
				font-size: rem(14);
				letter-spacing: rem(0.03);
				line-height: rem(24);
				position: relative;
			}

			&-country {
				position: absolute;
				bottom: 10px;
			}
		}
	}

	.contact-form {
		padding-bottom: 0;
	}

	.keep-exploring {
		@include gradient-angle();
		padding: rem(20);

		@include media-breakpoint-down(sm){
			display: none;
		}

		h6 {
			display: inline-block;
			font-size: rem(24);
			color: #EFEEEE;
			letter-spacing: rem(0.05);
			line-height: rem(36);
			margin-right: rem(55);
		}
	}

	.term-form {
		.form {
			.btn {
				min-width: rem(140);
				font-family: Roboto-Medium;
				color: #455560;
			}

			label, .text-small {
				font-family: Roboto-Medium;
			}
		}

		&__intro {
			h1 {
				font-family: $font-stack-header;
				font-size: rem(42);
				color: #455560;
				letter-spacing: rem(0.09);
				text-align: center;
				line-height: rem(54);
			}
			.form-introduction {
				p {
					margin: rem(10) 0;
					font-family: $font-stack-header;
					font-size: rem(24);
					color: #455560;
					letter-spacing: rem(0.05);
					text-align: center;
					line-height: rem(36);
				}
			}
		}

	}
}
