.background-graph {
	background-repeat: no-repeat;
	left: 0;
	opacity: 0.4;
	position: absolute;
	top: 0;
	z-index: 0;


	@media (max-width: 1024px) {
		display: none;
	}
}

.background-graph--overlapping-circle {
	background-position: -282px 0;
	background-image: url(../images/graphs/overlapping-circle-blue.svg);
	height: 785px;
	width: 782px;

	&.background-graph--right {
		left: auto;
		right: 0;
		transform: scale(-1);
	}
}

.background-graph--circle-line {
	background-position: -350px 0;
	background-image: url(../images/graphs/circle-line.svg);
	height: 992px;
	width: 988px;

	&.background-graph--right {
		background-position: 488px 0;
		left: auto;
		right: 0;
	}
}

.background-graph--scatter {
	background-position: 100px 0;
	background-image: url(../images/graphs/scatter-colour.svg);
	height: 785px;
	transform: scale(-1);
	width: 500px;

	&.background-graph--right {
		background-position: 100px 0;
		left: auto;
		right: 0;
		transform: none;
	}
}

.background-graph--scatter-plain {
	background-position: -322px 0;
	background-image: url(../images/graphs/scatter-grey.svg);
	height: 594px;
	width: 592px;

	&.background-graph--right {
		background-position: 282px 0;
		left: auto;
		right: 0;
	}
}

.background-graph--snail {
	background-position: 180px 0;
	background-image: url(../images/graphs/snail-colour.svg);
	height: 595px;
	transform: scale(-1);
	width: 593px;

	&.background-graph--right {
		background-position: 180px 0;
		left: auto;
		right: 0;
		transform: none;
	}
}
