// Footer

.footer {
	background-color: $black;
	color: $white;
	font-size: rem(14);
	position: relative;
	z-index: 5;
	padding: 60px 0 60px;

	@include media-breakpoint-down(sm) {
		padding: 20px 0;
		text-align: center;
	}

	ul {
		list-style-type: none;
		margin-bottom: 0;
		padding: 0;

		li {
			color: $white;
			margin-bottom: 0.5em;
			margin-top: 0.5em;

			a {
				color: $white;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}

	&__social {
		// bottom: 0;
		// position: absolute;

		@include media-breakpoint-down(sm) {
			position: relative;
		}
	}

	&__social-item {
		&:first-child {
			margin-right: 1em;
			margin-top: 0.5em;
		}

		&:last-child {
			margin-left: 1em;
			margin-top: 0.5em;
		}

		img {
			&:hover {
				filter: invert(30%) sepia(27%) saturate(923%) hue-rotate(116deg) brightness(92%) contrast(85%);
			}
		}
	}

	&:after {
		@include gradient-flat();
		bottom: 0;
		content: "";
		display: block;
		height: 9px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	.footer__copyright {
		bottom: 0;
		font-size: rem(14);
		line-height: 27px;
		margin: 0;
		position: absolute;
		right: 0;

		@include media-breakpoint-down(sm) {
			font-size: rem(12);
			line-height: 1.2;
			position: relative;
		}
	}
}