// Article

.article-meta {

	.article-meta__author, .article-meta__speaker {
		img {
			border: 2px solid map-get($brand, 'a');
			border-radius: 50%;
		}
	}

	.article-meta__speaker {
		margin-top: 20px;

		.share-panel__links li {
			margin: 0;
			padding-right: 10px;
		}

		&-info {
			max-height: 145px;
		}
	}

	.article-meta__author-type,
	.article-meta__author-name,
	.article-meta__author-title,
	.article-meta__author-company,
	.article-meta__date,
	.article-meta__categories,
	.article-meta__speaker-name,
	.article-meta__speaker-company,
	.article-meta__speaker-role {
		display: block;
	}

	.article-meta__type {
		font-size: rem(14);
	}
}

@include media-breakpoint-down(lg) {
	.article-meta .article-meta__speaker {
		margin-bottom: 40px;
		margin-top: 40px;
	}
}

@include media-breakpoint-down(md) {
	.article-meta .article-meta__speaker img {
		width: 100px;
	}
}

@include media-breakpoint-down(sm) {
	.article-meta {
		text-align: center;

		.article-meta__speaker {
			margin-bottom: 0;
		}
	}
}

@media (min-width: 600px) and (max-width: 900px) {
	.article-meta__speaker-info {
		margin-left: 10px;
	}
}