// Timeline

.timeline {
	.timeline__year-title {
		background: map-get($brand, 'c');
		border-radius: 50%;
		color: $white;
		display: block;
		font-family: $font-stack-bold;
		font-size: rem(14);
		height: 65px;
		line-height: 65px;
		margin: 1em auto;
		width: 65px;
	}

	.timeline__blocks {
		position: relative;

		&:before {
			background: $grey;
			content: "";
			display: block;
			height: 100%;
			left: 50%;
			margin-left: -2px;
			position: absolute;
			top: 0;
			width: 4px;
		}
	}

	.timeline__block {
		opacity: 0;
		transform: translateX(-80px);
		transition: transform $easing-extra-slow, opacity $easing-extra-slow;

		.timeline__block-inner {
			margin-top: -65px;
		}

		&:first-child .timeline__block-inner {
			margin-top: 0;
		}

		.timeline__block-image {
			transform: translateX(-80px);
			transition: transform $easing-extra-slow;
		}

		.timeline__block-divider {
			padding: 0;

			hr {
				border: none;
				border-bottom: 2px solid $grey;
			}
		}

		&:nth-child(even) {
			justify-content: flex-end;
			transform: translateX(80px);

			.row {
				flex-direction: row-reverse;
			}

			.timeline__block-image {
				transform: translateX(80px);
			}
		}

		&.active {
			transform: translateX(0);
			opacity: 1;

			.timeline__block-image {
				transform: translateX(0);
			}
		}
	}

	// flipped layout
	.timeline__blocks--flip {
		.timeline__block {
			transform: translateX(-80px);
			justify-content: flex-start;

			.row {
				flex-direction: row;
			}

			.timeline__block-image {
				transform: translateX(-80px);
			}


			&:nth-child(odd) {
				justify-content: flex-end;
				transform: translateX(80px);

				.row {
					flex-direction: row-reverse;
				}

				.timeline__block-image {
					transform: translateX(80px);
				}
			}

			&.active {
				transform: translateX(0);
				opacity: 1;

				.timeline__block-image {
					transform: translateX(0);
				}
			}
		}
	}

	.timeline__end {
		border-bottom: 4px solid $grey;
		display: block;
		height: 80px;
		margin: 0 auto;
		position: relative;
		width: 40px;

		&:before {
			background: $grey;
			content: "";
			display: block;
			height: 80px;
			left: 50%;
			margin-left: -2px;
			position: absolute;
			top: 0;
			width: 4px;
		}
	}
}

.timeline-card {
	background-color: $grey;
	color: $white;
	margin-bottom: 30px;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	.timeline-card__details {
		padding: 1em;
		position: relative;

		&:before {
			background-color: $grey;
			border-bottom: 24px solid $white;
			border-top: 22px solid $white;
			content: "";
			display: block;
			height: 44px;
			margin-top: -22px;
			position: absolute;
			top: 50%;
			width: 24px;
		}

		a {
			color: $white;
			&:hover,
			&:focus {
				color: $white;
			}
		}
	}

	.timeline-card__type {
		border-bottom: 1px solid $white;
		margin-bottom: 10px;
	}

	.timeline-card__extra-toggle {
		background-color: rgba(255,255,255,0.2);
		border-top: 2px solid rgba(255,255,255,0.6);
		cursor: pointer;
		display: block;
		font-size: rem(14);
		padding: 0.4em 1em;
		text-align: center;

		.toggle-icon {
			display: inline-block;
			height: 13px;
			margin-left: 20px;
			position: relative;
			width: 13px;

			&:before {
				background: $white;
				content: "";
				height: 3px;
				left: 0;
				position: absolute;
				right: 0;
				top: 5px;
			}

			&:after {
				background: $white;
				bottom: 0;
				content: "";
				left: 5px;
				position: absolute;
				top: 0;
				transition: transform $easing-regular;
				width: 3px;
			}
		}

		&:hover,
		&:focus {
			background-color: rgba(255,255,255,0.4);
		}
	}

	.timeline-card__extra.active {
		.timeline-card__extra-toggle .toggle-icon:after {
			transform: rotate(90deg);
		}
	}

	.timeline-card__extra-content {
		display: none;
		font-size: rem(14);
		overflow: hidden;
		padding: 1em;

		p,a {
			font-size: rem(14);
		}
	}

	// modifiers
	&.timeline-card--purple {
		background-color: map-get($brand, 'a');

		.timeline-card__details:before {
			background-color: map-get($brand, 'a');
		}
	}

	&.timeline-card--blue {
		background-color: map-get($brand, 'b');

		.timeline-card__details:before {
			background-color: map-get($brand, 'b');
		}
	}

	&.timeline-card--green {
		background-color: map-get($brand, 'c');

		.timeline-card__details:before {
			background-color: map-get($brand, 'c');
		}
	}

	.timeline__block:nth-child(odd) & {
		margin-right: 24px;

		.timeline-card__details:before {
			border-left: 24px solid transparent;
			right: -24px;
		}
	}

	.timeline__block:nth-child(even) & {
		margin-left: 24px;

		.timeline-card__details:before {
			border-right: 24px solid transparent;
			left: -24px;
		}
	}

	.timeline__blocks--flip .timeline__block:nth-child(even) & {
		margin-left: 0;
		margin-right: 24px;

		.timeline-card__details:before {
			border-left: 24px solid transparent;
			border-right: none;
			left: auto;
			right: -24px;
		}
	}

	.timeline__blocks--flip .timeline__block:nth-child(odd) & {
		margin-left: 24px;
		margin-right: 0;

		.timeline-card__details:before {
			border-left: none;
			border-right: 24px solid transparent;
			left: -24px;
			right: auto;
		}
	}
}

@include media-breakpoint-down(md) {
	.timeline {
		.timeline__block-image {
			display: none;
		}
		.timeline__block-divider {
			display: none;
		}
	}
}

@include media-breakpoint-down(sm) {
	.timeline {
		.timeline__blocks:before {
			height: 115%;
			top: -14px;
		}
		.timeline__end {
			top: -14px;
		}
		.timeline__block {
			opacity: 1;

			.timeline__block-inner {
				margin-top: 40px;
			}

			.timeline-card {
				margin-left: 0 !important;
				margin-right: 0 !important;

				.timeline-card__details:before {
					display: none;
				}
			}
		}
	}
}