.global-copy {
	padding: 1em 0;

	.break--gradient {
		@include gradient-flat();
		border: none;
		height: 1.5px;
		width: 65%;

        @include media-breakpoint-down(md) {    
            width: 100%
        }
	}

    .btn.btn--square {
        background: transparent;
        border: 2px solid $grey-light;
        border-radius: 5px;        
        width: 10rem;
        height: 8rem;

        p {
            font-size: .8rem;
            margin-top: 0.7em;
        }
    
        &:before,
        &:after {
            display: none;
        }
    
        &:hover,
        &.active,
        &:focus {
            border-color: $black;
            color: $black;
        }
    }

	h1 {
		@include gradient-text();
		font-size: 2.8rem;
	}

	h3 {
		font-size: 2.1rem;
	}

	h4 {
		font-size: 1.65rem;

		span {
			position: relative;
		}

		a {
			text-decoration: none;

			&:focus,
			&:hover  {
				span {
					@include gradient-text();

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						background: none;
						color: #130067;
					}

					&::after {
						@include gradient-flat();
						content: "";
						display: block;
						height: 2px;
						left: 0;
						position: absolute;
						bottom: 0;
						width: 100%;
					}
				}
			}

			.icon {
				transform: scaleX(-1);
				margin-left: rem(10);
			}
		}
	}
}