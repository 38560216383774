// Freeform styling

form .freeform-row {
	.freeform-column {
		-ms-flex: 1 0 40%;
		flex: 1 0 40%;

		label.freeform-label {
			font-weight: normal;
		}

		.freeform-input {
			border: 1px solid $grey;
			border-radius: 5px;
			display: block;
			height: 44px;
			line-height: 1;
			padding: 10px 20px;

			&::placeholder {
				font-size: rem(14);
				color: #ABABAB;
				letter-spacing: rem(0.03);
				line-height: rem(18);
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	form .freeform-row {
		display: block;
	}
}
