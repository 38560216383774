// Forms

#form-submitted {
	height: 400px;
	text-align: center;
}

.form {
	
	.form__field {
		margin-bottom: 1em;

		label {
			display: block;
		}

		input,
		textarea {
			border: 1px solid $grey;
			border-radius: 5px;
			display: block;
			line-height: 1;
			padding: 10px 20px;
			width: 100%;

			&::placeholder {
				font-size: rem(14);
				color: #ABABAB;
				letter-spacing: rem(0.03);
				line-height: rem(18);
			}
		}

		.errors {
			color: red;
			list-style-type: none;
			padding: 0;
		}
	}
}

@include media-breakpoint-down(sm) {
	.form {
		.form__field {
			.btn {
				width: 100%;
			}
		}
	}
}