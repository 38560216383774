@font-face {
    font-family: 'robotoitalic';
    src: url('../font/roboto-italic-webfont.eot');
    src: url('../font/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-italic-webfont.woff2') format('woff2'),
         url('../font/roboto-italic-webfont.woff') format('woff'),
         url('../font/roboto-italic-webfont.ttf') format('truetype'),
         url('../font/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: 'robotolight';
    src: url('../font/roboto-light-webfont.eot');
    src: url('../font/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-light-webfont.woff2') format('woff2'),
         url('../font/roboto-light-webfont.woff') format('woff'),
         url('../font/roboto-light-webfont.ttf') format('truetype'),
         url('../font/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/*@font-face {
    font-family: 'robotolight_italic';
    src: url('../font/roboto-lightitalic-webfont.eot');
    src: url('../font/roboto-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../font/roboto-lightitalic-webfont.woff') format('woff'),
         url('../font/roboto-lightitalic-webfont.ttf') format('truetype'),
         url('../font/roboto-lightitalic-webfont.svg#robotolight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/*@font-face {
    font-family: 'robotomedium';
    src: url('../font/roboto-medium-webfont.eot');
    src: url('../font/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-medium-webfont.woff2') format('woff2'),
         url('../font/roboto-medium-webfont.woff') format('woff'),
         url('../font/roboto-medium-webfont.ttf') format('truetype'),
         url('../font/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/*@font-face {
    font-family: 'robotomedium_italic';
    src: url('../font/roboto-mediumitalic-webfont.eot');
    src: url('../font/roboto-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../font/roboto-mediumitalic-webfont.woff') format('woff'),
         url('../font/roboto-mediumitalic-webfont.ttf') format('truetype'),
         url('../font/roboto-mediumitalic-webfont.svg#robotomedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
    font-family: 'robotoregular';
    src: url('../font/roboto-regular-webfont.eot');
    src: url('../font/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-regular-webfont.woff2') format('woff2'),
         url('../font/roboto-regular-webfont.woff') format('woff'),
         url('../font/roboto-regular-webfont.ttf') format('truetype'),
         url('../font/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold';
    src: url('../font/roboto-bold-webfont.eot');
    src: url('../font/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-bold-webfont.woff2') format('woff2'),
         url('../font/roboto-bold-webfont.woff') format('woff'),
         url('../font/roboto-bold-webfont.ttf') format('truetype'),
         url('../font/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('../font/roboto-bolditalic-webfont.eot');
    src: url('../font/roboto-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../font/roboto-bolditalic-webfont.woff') format('woff'),
         url('../font/roboto-bolditalic-webfont.ttf') format('truetype'),
         url('../font/roboto-bolditalic-webfont.svg#robotobold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornbold';
    src: url('../font/vollkorn-bold-webfont.eot');
    src: url('../font/vollkorn-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/vollkorn-bold-webfont.woff2') format('woff2'),
         url('../font/vollkorn-bold-webfont.woff') format('woff'),
         url('../font/vollkorn-bold-webfont.ttf') format('truetype'),
         url('../font/vollkorn-bold-webfont.svg#vollkornbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornitalic';
    src: url('../font/vollkorn-italic-webfont.eot');
    src: url('../font/vollkorn-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/vollkorn-italic-webfont.woff2') format('woff2'),
         url('../font/vollkorn-italic-webfont.woff') format('woff'),
         url('../font/vollkorn-italic-webfont.ttf') format('truetype'),
         url('../font/vollkorn-italic-webfont.svg#vollkornitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornmedium';
    src: url('../font/vollkorn-medium-webfont.eot');
    src: url('../font/vollkorn-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/vollkorn-medium-webfont.woff2') format('woff2'),
         url('../font/vollkorn-medium-webfont.woff') format('woff'),
         url('../font/vollkorn-medium-webfont.ttf') format('truetype'),
         url('../font/vollkorn-medium-webfont.svg#vollkornmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornmedium_italic';
    src: url('../font/vollkorn-mediumitalic-webfont.eot');
    src: url('../font/vollkorn-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/vollkorn-mediumitalic-webfont.woff2') format('woff2'),
         url('../font/vollkorn-mediumitalic-webfont.woff') format('woff'),
         url('../font/vollkorn-mediumitalic-webfont.ttf') format('truetype'),
         url('../font/vollkorn-mediumitalic-webfont.svg#vollkornmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vollkornregular';
    src: url('../font/vollkorn-regular-webfont.eot');
    src: url('../font/vollkorn-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/vollkorn-regular-webfont.woff2') format('woff2'),
         url('../font/vollkorn-regular-webfont.woff') format('woff'),
         url('../font/vollkorn-regular-webfont.ttf') format('truetype'),
         url('../font/vollkorn-regular-webfont.svg#vollkornregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

%font-header {
    font-family: $font-stack-fallback;

    .wf-vollkorn_regular-active & {
        font-family: $font-stack-header;
    }
}

%font-regular {
    font-family: $font-stack-fallback;

    .wf-roboto_regular-active & {
        font-family: $font-stack-regular;
    }
}

%font-bold {
    font-family: $font-stack-fallback;

    .wf-roboto_regular-active & {
        font-family: $font-stack-bold;
    }
}

%font-italic {
    font-family: $font-stack-fallback;

    .wf-roboto_italic-active & {
        font-family: $font-stack-italic;
    }
}