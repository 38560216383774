@import './node_modules/slick-carousel/slick/slick.scss';

// Custom theme

.slick-dots {
	bottom: 20px;
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	li {
		cursor: pointer;
		display: inline-block;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		position: relative;
		width: 20px;
		button {
			background: transparent;
			border: 2px solid #FFFFFF;
			border-radius: 50%;
			color: transparent;
			cursor: pointer;
			display: block;
			font-size: 0px;
			height: 20px;
			line-height: 0px;
			outline: none;
			padding: 5px;
			transition: background 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
			width: 20px;
			&:hover, &:focus {
				background: #FFFFFF;
				outline: none;
			}
		}
		&.slick-active button {
			background: rgba(255,255,255,0.6);
		}
	}
}