.header-search {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 10;

    &__input-group {
        margin: 10px 0;

        button {
            background-color: #F4F4F4;
            border: none;
            height: 73px;
            position: absolute;
            right: 16px;
            top: 11px;
            width: 70px;

            span {
                .icon {
                    filter: invert(60%);
                    margin-left: 20px;
                }
            }
        }

        p {
            color: #8797A3;
            position: absolute;
            right: 100px;
            top: 20px;

            @media (max-width: 600px) {
                display: none;
            }
        }

        input {
            border: 1px solid #B2B2B2;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 18px;
            height: 75px;
            padding-left: 100px;
            padding-right: 200px;
            width: 100%;

            @media (max-width: 600px) {
                padding-left: 10px;
                padding-right: 75px;
            }
        }
    }
}
