// Navigation mobile/burger based

$bardist: 0.2em;

%bar {
	background-color: $white;
	display: block;
	height: $bardist;
	width: ($bardist * 7);

	.header-active &,
	.menu-open & {
		background-color: $white;
	}
}

.primary-nav-trigger {
	display: none;
	height: 2.375em;
	padding-bottom: 0.375em;
	padding-right: ($bardist * 13);
	padding-top: 0.375em;
 	position: absolute;
 	top: 50%;
	margin-top: -1.1875em;
 	right: 15px;
 	text-decoration: none;

 	@include media-breakpoint-down(md) {
 		display: block;
 		font-size: 12px;
 		right: 1em;
 	}

 	.header-active &,
 	.menu-open & {
 		color: $white;
 	}

 	.menu-text {
 		font-size: 0.875em;
	 	text-transform: uppercase;
 	}

	.menu-burger {
		@extend %bar;
		right: ($bardist * 3);
		position: absolute;
		margin-top: -0.125em;
		top: 50%;
		transform: rotate(0);
		transition: background-color .1s 0s, transform .35s;

		&:before, &:after {
			@extend %bar;
			content: '';
			position: absolute;
			transform: rotate(0);
			transition:
				transform .14s 0s,
				top .14s 0s
			;
		}

		&:before {
			transform: rotate(0) translate(0, -($bardist * 3));

			.no-csstransforms & {
				top: -($bardist * 3);
			}
		}
		&:after {
			transform: rotate(0) translate(0, ($bardist * 3));

			.no-csstransforms & {
				top: ($bardist * 3);
			}
		}
	}

	&:hover,
	&:focus {
		.menu-burger {
			&:before {
				// top: -7px;
			}
			&:after {
				// top: 7px;
			}
		}
	}

	.menu-open & {
		.menu-burger {
			background: transparent;
			transform: rotate(180deg);

			&:before, &:after {
				transition:
					transform .14s .08s,
					top .1s .04s
				;
			}

			&:before {
				transform: rotate(45deg) translate(0, 0);
				// top: 0;
			}
			&:after {
				transform: rotate(-45deg) translate(0, 0);
				// top: 0;
			}

			.no-csstransforms & {
				background: #fff;
			}
		}
	}
}