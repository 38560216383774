// Branding Elements

.brand-logo {
	background-image: url('../images/club-vita-logo.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	display: block;
	height: 55px;
	margin-right: 1rem;
	width: 103px;

	&.brand-logo--small {
		height: 27px;
		width: 77px;
	}

	@media (max-width: 900px) {
		margin-right: 1rem;
	}
}
