.search-results {

    .search-results-items {

        select {
            border: 1px solid #455560;
            border-radius: 2px;
            box-sizing: border-box;
            color: $font-color;
            font-family: $font-stack-regular;
            margin: 0px 16px;
            padding: 6px;
        }

        .search-results-item {

            a {
                text-decoration: none;
            }

            h4 span {
                @include gradient-newstext();
                background-color: transparent;
                font-family: $font-stack-header;

                &:hover {
                    --g: linear-gradient(315deg,#70bc1f,#3ac7a7 17.53%,#1783bd 34.53%,#3c008d);
                    background: var(--g),var(--g) bottom 0.5px left 0/100% 0.75px no-repeat;
                    background-clip: text,padding-box;
                    -webkit-background-clip: text,padding-box;
                }
            }

            p {
                font-family: $font-stack-regular;
                font-size: 0.875em;
                line-height: 1.125em;

                &.top {
                    color: #8797A3;
                    padding: 0.5em 0;
                }

                &.bottom {
                    color: #8797A3;
                    padding: 0.5em 0;
                }
            }
        }
    }
}