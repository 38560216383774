/* lib */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 320px;
}

@media (min-width: 439px) {
  .container-sm, .container {
    max-width: 440px;
  }
}
@media (min-width: 599px) {
  .container-md, .container-sm, .container {
    max-width: 600px;
  }
}
@media (min-width: 899px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 900px;
  }
}
@media (min-width: 1199px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 439px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 599px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 899px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1199px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 439px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 599px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 899px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1199px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 439px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 599px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 899px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1199px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 439px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 599px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 899px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1199px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 439px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 599px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 899px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1199px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 439px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 599px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 899px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1199px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  bottom: 20px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}
.slick-dots li button {
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0px;
  height: 20px;
  line-height: 0px;
  outline: none;
  padding: 5px;
  transition: background 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  width: 20px;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  background: #FFFFFF;
  outline: none;
}
.slick-dots li.slick-active button {
  background: rgba(255, 255, 255, 0.6);
}

/* settings */
/* animations */
/* colours */
/* typography */
/* util */
@font-face {
  font-family: "robotoitalic";
  src: url("../font/roboto-italic-webfont.eot");
  src: url("../font/roboto-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../font/roboto-italic-webfont.woff2") format("woff2"), url("../font/roboto-italic-webfont.woff") format("woff"), url("../font/roboto-italic-webfont.ttf") format("truetype"), url("../font/roboto-italic-webfont.svg#robotoitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*@font-face {
    font-family: 'robotolight';
    src: url('../font/roboto-light-webfont.eot');
    src: url('../font/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-light-webfont.woff2') format('woff2'),
         url('../font/roboto-light-webfont.woff') format('woff'),
         url('../font/roboto-light-webfont.ttf') format('truetype'),
         url('../font/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
/*@font-face {
    font-family: 'robotolight_italic';
    src: url('../font/roboto-lightitalic-webfont.eot');
    src: url('../font/roboto-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../font/roboto-lightitalic-webfont.woff') format('woff'),
         url('../font/roboto-lightitalic-webfont.ttf') format('truetype'),
         url('../font/roboto-lightitalic-webfont.svg#robotolight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
/*@font-face {
    font-family: 'robotomedium';
    src: url('../font/roboto-medium-webfont.eot');
    src: url('../font/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-medium-webfont.woff2') format('woff2'),
         url('../font/roboto-medium-webfont.woff') format('woff'),
         url('../font/roboto-medium-webfont.ttf') format('truetype'),
         url('../font/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
/*@font-face {
    font-family: 'robotomedium_italic';
    src: url('../font/roboto-mediumitalic-webfont.eot');
    src: url('../font/roboto-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../font/roboto-mediumitalic-webfont.woff') format('woff'),
         url('../font/roboto-mediumitalic-webfont.ttf') format('truetype'),
         url('../font/roboto-mediumitalic-webfont.svg#robotomedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
  font-family: "robotoregular";
  src: url("../font/roboto-regular-webfont.eot");
  src: url("../font/roboto-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/roboto-regular-webfont.woff2") format("woff2"), url("../font/roboto-regular-webfont.woff") format("woff"), url("../font/roboto-regular-webfont.ttf") format("truetype"), url("../font/roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold";
  src: url("../font/roboto-bold-webfont.eot");
  src: url("../font/roboto-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/roboto-bold-webfont.woff2") format("woff2"), url("../font/roboto-bold-webfont.woff") format("woff"), url("../font/roboto-bold-webfont.ttf") format("truetype"), url("../font/roboto-bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold_italic";
  src: url("../font/roboto-bolditalic-webfont.eot");
  src: url("../font/roboto-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../font/roboto-bolditalic-webfont.woff2") format("woff2"), url("../font/roboto-bolditalic-webfont.woff") format("woff"), url("../font/roboto-bolditalic-webfont.ttf") format("truetype"), url("../font/roboto-bolditalic-webfont.svg#robotobold_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vollkornbold";
  src: url("../font/vollkorn-bold-webfont.eot");
  src: url("../font/vollkorn-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/vollkorn-bold-webfont.woff2") format("woff2"), url("../font/vollkorn-bold-webfont.woff") format("woff"), url("../font/vollkorn-bold-webfont.ttf") format("truetype"), url("../font/vollkorn-bold-webfont.svg#vollkornbold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vollkornitalic";
  src: url("../font/vollkorn-italic-webfont.eot");
  src: url("../font/vollkorn-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../font/vollkorn-italic-webfont.woff2") format("woff2"), url("../font/vollkorn-italic-webfont.woff") format("woff"), url("../font/vollkorn-italic-webfont.ttf") format("truetype"), url("../font/vollkorn-italic-webfont.svg#vollkornitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vollkornmedium";
  src: url("../font/vollkorn-medium-webfont.eot");
  src: url("../font/vollkorn-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../font/vollkorn-medium-webfont.woff2") format("woff2"), url("../font/vollkorn-medium-webfont.woff") format("woff"), url("../font/vollkorn-medium-webfont.ttf") format("truetype"), url("../font/vollkorn-medium-webfont.svg#vollkornmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vollkornmedium_italic";
  src: url("../font/vollkorn-mediumitalic-webfont.eot");
  src: url("../font/vollkorn-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../font/vollkorn-mediumitalic-webfont.woff2") format("woff2"), url("../font/vollkorn-mediumitalic-webfont.woff") format("woff"), url("../font/vollkorn-mediumitalic-webfont.ttf") format("truetype"), url("../font/vollkorn-mediumitalic-webfont.svg#vollkornmedium_italic") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vollkornregular";
  src: url("../font/vollkorn-regular-webfont.eot");
  src: url("../font/vollkorn-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/vollkorn-regular-webfont.woff2") format("woff2"), url("../font/vollkorn-regular-webfont.woff") format("woff"), url("../font/vollkorn-regular-webfont.ttf") format("truetype"), url("../font/vollkorn-regular-webfont.svg#vollkornregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.rte-copy .header-font, h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Gill Sans", Arial;
}
.wf-vollkorn_regular-active .rte-copy .header-font, .rte-copy .wf-vollkorn_regular-active .header-font, .wf-vollkorn_regular-active h1,
.wf-vollkorn_regular-active h2,
.wf-vollkorn_regular-active h3,
.wf-vollkorn_regular-active h4,
.wf-vollkorn_regular-active h5,
.wf-vollkorn_regular-active h6,
.wf-vollkorn_regular-active .h1,
.wf-vollkorn_regular-active .h2,
.wf-vollkorn_regular-active .h3,
.wf-vollkorn_regular-active .h4,
.wf-vollkorn_regular-active .h5,
.wf-vollkorn_regular-active .h6 {
  font-family: "vollkornregular", "Times New Roman", serif;
}

body {
  font-family: "Gill Sans", Arial;
}
.wf-roboto_regular-active body {
  font-family: "robotoregular", "Gill Sans", Arial;
}

strong {
  font-family: "Gill Sans", Arial;
}
.wf-roboto_regular-active strong {
  font-family: "robotobold", "Gill Sans", Arial;
}

.rte-copy em {
  font-family: "Gill Sans", Arial;
}
.wf-roboto_italic-active .rte-copy em, .rte-copy .wf-roboto_italic-active em {
  font-family: "robotoitalic", "Gill Sans", Arial;
}

html {
  font-size: 16px;
}

body {
  color: #455560;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.625rem;
}

h3,
.h3 {
  font-size: 2.25rem;
}

h4,
.h4 {
  font-size: 1.875rem;
}

h5,
.h5 {
  font-size: 1.5rem;
}

h6,
.h6 {
  font-size: 1.125rem;
}

p {
  font-size: 1rem;
  margin: 1em 0;
}

a {
  color: #455560;
  text-decoration: underline;
  transition: color 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}

a:active,
a:focus,
a:hover {
  color: #3C008D;
  text-decoration: none;
}

ol,
ul {
  padding-left: 32px;
}
ol li,
ul li {
  line-height: 1.5;
}
ol.remove-spacing,
ul.remove-spacing {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.break--gradient {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  border: none;
  height: 2px;
  width: 100%;
}

.center,
.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.subtitle {
  padding-left: 22px;
  padding-right: 22px;
}

.text-small {
  font-size: 0.875rem;
}

.text-extra-small {
  font-size: 0.75rem;
}

.rte-copy h6 {
  color: #3C008D;
  font-size: 1.5rem;
  text-align: center;
}
.rte-copy ol,
.rte-copy ul {
  padding-left: 20px;
}
.rte-copy ol li,
.rte-copy ul li {
  margin-bottom: 20px;
  padding-left: 15px;
}
.rte-copy ol li:last-child,
.rte-copy ul li:last-child {
  margin-bottom: 0;
}
.rte-copy em {
  font-style: normal;
}
.gradient-links ol li a, .gradient-links ul li a {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.gradient-links ol li a:after, .gradient-links ul li a:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translateY(-5px);
  transition: all 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.gradient-links ol li a:hover:after, .gradient-links ol li a:focus:after, .gradient-links ul li a:hover:after, .gradient-links ul li a:focus:after {
  opacity: 1;
  transform: translateY(0);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gradient-links ol li a, .gradient-links ul li a {
    background: none;
    color: #3C008D;
  }
}

.icon-link {
  text-decoration: none;
}
.icon-link .icon {
  vertical-align: bottom;
}

.brand-logo {
  background-image: url("../images/club-vita-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 55px;
  margin-right: 1rem;
  width: 103px;
}
.brand-logo.brand-logo--small {
  height: 27px;
  width: 77px;
}
@media (max-width: 900px) {
  .brand-logo {
    margin-right: 1rem;
  }
}

.icon {
  fill: #FFFFFF;
  height: 24px;
  transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  width: 24px;
}
.icon.icon--email {
  stroke: #FFFFFF;
}
.icon.icon--dark {
  color: #696765;
  fill: #212121;
}
.icon.icon--dark.icon--email {
  stroke: #212121;
}
.icon.icon--grey {
  color: #696765;
  fill: #696765;
}
.icon.icon--grey.icon--email {
  stroke: #696765;
}
.icon.icon--gradient {
  padding: 6px 5px;
}
.icon.icon--social:hover, .icon.icon--social:focus {
  cursor: pointer;
  opacity: 0.9;
}

.icon--flag {
  width: 27px;
  height: 18px;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
}

.responsive-image {
  max-width: 100%;
}

/* components */
@keyframes hover {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 20% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.btn,
.btn:visited {
  background-color: #FFFFFF;
  border-radius: 10em;
  color: #455560;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.75em 2em;
  position: relative;
  text-decoration: none;
  transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.btn:before,
.btn:visited:before {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  background-size: 100%;
  border-radius: 10em;
  bottom: -2px;
  content: "";
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  z-index: -1;
}
.btn:after,
.btn:visited:after {
  border-radius: 10em;
  border: 2px solid transparent;
  bottom: -4px;
  content: "";
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.btn + .btn,
.btn:visited + .btn {
  margin-left: 1.5em;
}

.btn:hover,
.btn:focus {
  background: transparent;
  color: #FFFFFF;
  text-decoration: none;
}

.btn:active:after {
  border-color: white;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
}

button.btn {
  border: none;
  outline: none;
}

.btn--block,
.btn--block:visited {
  display: block;
  width: 100%;
}

.btn.btn--light {
  background: transparent;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
}
.btn.btn--light:before, .btn.btn--light:after {
  display: none;
}
.btn.btn--light:hover, .btn.btn--light:focus {
  background-color: #FFFFFF;
  color: #212121;
}

.btn.btn--dark {
  background: transparent;
  border: 2px solid #455560;
  padding-left: 40px;
  padding-right: 40px;
}
.btn.btn--dark:before, .btn.btn--dark:after {
  display: none;
}
.btn.btn--dark:hover, .btn.btn--dark:focus {
  background-color: #455560;
}

.btn.btn--squared {
  background: transparent;
  border: 2px solid #EFEEEE;
  border-radius: 5px;
}
.btn.btn--squared:before, .btn.btn--squared:after {
  display: none;
}
.btn.btn--squared:hover, .btn.btn--squared.active, .btn.btn--squared:focus {
  border-color: #212121;
  color: #212121;
}

.btn--news {
  border: none;
  border-radius: 10em;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F 0%, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  outline: none;
  padding: 0.75em 2em;
  position: relative;
  z-index: 1;
}
.btn--news:before {
  background-color: white;
  border-radius: 8em;
  content: "";
  bottom: 2px;
  left: 2px;
  right: 2px;
  top: 2px;
  position: absolute;
  transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  z-index: -1;
}
.btn--news:after {
  content: attr(data);
  font-size: 0.875rem;
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}

.btn--news:hover:before {
  opacity: 0%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.btn--news:hover:after {
  background-image: none;
  background-color: #FFFFFF;
}

.main-content {
  position: relative;
}

.section {
  margin: 2em 0;
  position: relative;
  z-index: 5;
}
.section.section--background {
  z-index: 1;
}

.section-margin-bottom {
  margin-bottom: 2em;
}

.footer {
  background-color: #212121;
  color: #FFFFFF;
  font-size: 0.875rem;
  position: relative;
  z-index: 5;
  padding: 60px 0 60px;
}
@media (max-width: 598.98px) {
  .footer {
    padding: 20px 0;
    text-align: center;
  }
}
.footer ul {
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
}
.footer ul li {
  color: #FFFFFF;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.footer ul li a {
  color: #FFFFFF;
  text-decoration: none;
}
.footer ul li a:hover, .footer ul li a:focus {
  color: #FFFFFF;
  text-decoration: underline;
}
@media (max-width: 598.98px) {
  .footer__social {
    position: relative;
  }
}
.footer__social-item:first-child {
  margin-right: 1em;
  margin-top: 0.5em;
}
.footer__social-item:last-child {
  margin-left: 1em;
  margin-top: 0.5em;
}
.footer__social-item img:hover {
  filter: invert(30%) sepia(27%) saturate(923%) hue-rotate(116deg) brightness(92%) contrast(85%);
}
.footer:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 9px;
  left: 0;
  position: absolute;
  width: 100%;
}
.footer .footer__copyright {
  bottom: 0;
  font-size: 0.875rem;
  line-height: 27px;
  margin: 0;
  position: absolute;
  right: 0;
}
@media (max-width: 598.98px) {
  .footer .footer__copyright {
    font-size: 0.75rem;
    line-height: 1.2;
    position: relative;
  }
}

.header {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  color: #FFFFFF;
  position: relative;
}
.header__row {
  justify-content: center;
}
@media (max-width: 600px) {
  .header__row {
    justify-content: left;
  }
}
.header .header__nav {
  border-bottom: 2px solid #FFFFFF;
  padding: 1.6em 0;
  position: relative;
  transition: background 1s cubic-bezier(0.8, 0.2, 0.2, 0.8);
  z-index: 15;
}
@media screen and (min-width: 896px) {
  .header .header__nav .container {
    max-width: 1000px;
  }
}
@media (min-width: 1199px) {
  .header .header__nav .container {
    max-width: 1200px;
  }
}
.header .header__nav--solid {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  top: 0px !important;
}
.header .header__social {
  border-left: 1px solid #FFFFFF;
  text-align: center;
}
.header .header__social ul {
  line-height: 1;
  list-style-type: none;
}
.header .header__social ul li {
  display: inline-block;
  line-height: 1;
  margin: 0 4px;
}
.header .header__intro {
  padding: 6em 0 8em;
  position: relative;
  z-index: 5;
}
.header .header__intro h1 {
  font-family: "vollkornregular", "Times New Roman", serif;
  line-height: 1;
}
.header .header__subnav {
  background-color: rgba(21, 21, 21, 0.2);
  padding: 1em 0;
  position: relative;
  z-index: 5;
}
.header__search {
  border-left: 2px solid white;
  height: 35px;
}
.header__search button {
  background: none;
  border: none;
  margin-top: 5px;
}

.header__graph {
  background-repeat: no-repeat;
  height: 100%;
  max-height: 100%;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.header__graph--circle-line {
  background-image: url(../images/graphs/circle-line.svg);
  background-position: 508px -303px;
  background-size: 100%;
  opacity: 0.6;
  width: 1149px;
}

.header__graph--scatter {
  background-image: url(../images/graphs/scatter-colour-white.svg);
  background-position: 145px -151px;
  background-size: 100%;
  opacity: 0.6;
  width: 702px;
}

.header__graph--scatter-plain {
  background-image: url(../images/graphs/scatter-grey.svg);
  background-position: 145px -151px;
  background-size: 100%;
  opacity: 0.6;
  width: 803px;
}

.header__graph--overlapping-circle {
  background-image: url(../images/graphs/overlapping-circle-grey.svg);
  background-position: -189px -17px;
  background-size: 100%;
  opacity: 1;
  transform: scaleX(-1);
  width: 702px;
}

.header__graph--snail {
  background-image: url(../images/graphs/snail-colour.svg);
  background-position: 62px 34px;
  background-size: 100%;
  opacity: 0.4;
  width: 480px;
}

.header__graph--tunnel {
  background-image: url(../images/graphs/tunnel.svg);
  background-position: 172px -109px;
  background-size: 100%;
  opacity: 0.8;
  width: 575px;
}

.header__overlay {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.header--sticky {
  padding-top: 6.75em;
}
.header--sticky .header__nav {
  height: 6.75em;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.header--sticky .header__nav--solid {
  position: fixed;
}

.header__bg-carousel {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.header__bg-carousel .slick-list,
.header__bg-carousel .slick-track {
  height: 100%;
}

.header__bg-carousel-slide {
  outline: none;
}
.header__bg-carousel-slide:nth-child(1n+0) {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
}
.header__bg-carousel-slide:nth-child(2n+0) {
  background: #3C008D;
  background: linear-gradient(220deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
}
.header__bg-carousel-slide:nth-child(3n+0) {
  background: #3C008D;
  background: linear-gradient(180deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
}

.header__carousel {
  z-index: 5;
}

.header__carousel-slide {
  display: none;
  outline: none;
}
.header__carousel-slide .header__intro {
  padding: 6em 0 8em;
}
.header__carousel-slide .header__intro .btn {
  margin-top: 2em;
}
.header__carousel-slide:first-child {
  display: block;
}

@media (max-width: 1198.98px) {
  .header__social {
    display: none;
  }
}
@media (max-width: 598.98px) {
  .header__graph {
    display: none;
  }

  .header__carousel-slide .header__intro, .header .header__intro {
    padding: 1em 0 4em;
    overflow-wrap: break-word;
  }
  .header__carousel-slide .header__intro h1, .header .header__intro h1 {
    font-size: 2.8rem;
  }
}
.scroll-adjust {
  top: 5px !important;
}

.scroll-adjust-mobile-menu {
  top: 108px !important;
}

.primary-navigation {
  font-size: 0.875rem;
  text-align: right;
}
.primary-navigation .primary-navigation__menu {
  list-style-type: none;
}
.primary-navigation .primary-navigation__menu-2 {
  display: none;
}
.primary-navigation .primary-navigation__item {
  display: inline-block;
  margin: 0 15px;
}
.primary-navigation .primary-navigation__item a {
  color: #FFFFFF;
  text-decoration: none;
}
.primary-navigation .primary-navigation__item a .icon {
  display: none;
}
.primary-navigation .primary-navigation__item.active {
  border-bottom: 2px solid #fff;
  font-family: "robotobold", "Gill Sans", Arial;
  padding-bottom: 1px;
}
.primary-navigation .primary-navigation__item--login a {
  display: inline-block;
  position: relative;
}
.primary-navigation .primary-navigation__item--login a .icon {
  display: inline-block;
  position: absolute;
  top: 0;
}
.primary-navigation .primary-navigation__mobile-social {
  display: none;
}

@media (min-width: 899px) {
  .primary-navigation__item {
    height: 22px;
  }

  .primary-navigation__item--login a {
    margin-right: 30px;
  }
  .primary-navigation__item--login a .icon {
    right: -30px;
  }

  .primary-navigation__menu-2 {
    display: none !important;
  }
}
@media (max-width: 1198.98px) {
  .primary-navigation .primary-navigation__menu {
    padding-right: 40px;
  }
  .primary-navigation .primary-navigation__item {
    margin: 0 5px;
  }

  .primary-navigation__item--login a {
    margin-right: 0;
  }
  .primary-navigation__item--login a .icon {
    left: unset !important;
    margin-left: 10px;
  }
}
@media (min-width: 599px) {
  .primary-navigation__item a:hover, .primary-navigation__item a:focus {
    border-bottom: 2px solid #fff;
    padding-bottom: 1px;
  }
}
@media (max-width: 898.98px) {
  .primary-navigation {
    background: #3C008D;
    background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
    bottom: 0;
    left: -500px;
    overflow-y: scroll;
    padding: 0;
    position: fixed;
    text-align: center;
    top: 7.7em;
    transition: background-color 0.25s;
    width: 0;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
  }
  .primary-navigation .primary-navigation__menu {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 10;
  }
  .primary-navigation .primary-navigation__menu li {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    float: none;
    list-style-type: none;
  }
  .primary-navigation .primary-navigation__menu li:before {
    display: none;
  }
  .primary-navigation .primary-navigation__item.active {
    border-bottom: 0;
  }
  .primary-navigation .primary-navigation__menu {
    opacity: 0;
    transform: translate3d(0, 4vh, 0);
    transition: 0.5s;
  }
  .primary-navigation .primary-navigation__menu a,
.primary-navigation .primary-navigation__menu a:visited {
    color: #FFFFFF;
    display: block;
    padding: 1.2em 30px;
    text-decoration: none;
    text-transform: uppercase;
  }
  .primary-navigation > ul > li > a,
.primary-navigation > ul > li > a:visited {
    border-bottom: 1px solid #FFFFFF;
  }
  .primary-navigation .has-children > a,
.primary-navigation .has-children > a:visited {
    position: relative;
  }
  .primary-navigation .has-children > a .icon,
.primary-navigation .has-children > a:visited .icon {
    display: block;
    margin-top: -12px;
    position: absolute;
    right: 0;
    top: 50%;
    transition: 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  }
  .primary-navigation .has-children.sub-menu-open > a {
    text-decoration: underline;
  }
  .primary-navigation .has-children.sub-menu-open > a .icon,
.primary-navigation .has-children.sub-menu-open > a:visited .icon {
    transform: rotate(-90deg);
  }
  .primary-navigation .has-children .primary-navigation__menu-2 {
    display: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .primary-navigation .has-children .primary-navigation__menu-2 > li {
    padding-left: 15px;
    padding-right: 15px;
  }
  .primary-navigation .has-children .primary-navigation__menu-2 > li > a,
.primary-navigation .has-children .primary-navigation__menu-2 > li > a:visited {
    border-bottom: 1px solid #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
  }
  .primary-navigation .primary-navigation__item--login a .icon {
    right: auto;
    left: 0;
    top: 1.2em;
  }
  .primary-navigation .primary-navigation__mobile-social {
    padding-bottom: 2em;
    padding-top: 2em;
  }
  .primary-navigation .primary-navigation__mobile-social a,
.primary-navigation .primary-navigation__mobile-social a:visited {
    color: #FFFFFF;
    font-size: 1.5rem;
  }
  .primary-navigation .primary-navigation__mobile-social ul {
    margin: 0;
    padding: 0;
  }
  .primary-navigation .primary-navigation__mobile-social li {
    display: inline-block;
    list-style-type: none;
    margin: 0 1em;
  }

  .menu-open {
    max-height: 100vh;
    overflow: hidden;
  }
  .menu-open .primary-navigation {
    overflow: auto;
    right: 0;
    left: 0;
    width: auto;
  }
  .menu-open .primary-navigation .primary-navigation__menu {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .menu-open .primary-navigation .primary-navigation__mobile-social {
    display: block;
  }
  .menu-open #content,
.menu-open .footer {
    display: none;
  }
}
.secondary-navigation .secondary-navigation__mobile-toggle {
  display: none;
}
.secondary-navigation .secondary-navigation__menu {
  list-style-type: none;
}
.secondary-navigation .secondary-navigation__item {
  display: inline-block;
  margin: 0 50px;
}
.secondary-navigation .secondary-navigation__item a {
  color: #FFFFFF;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.secondary-navigation .secondary-navigation__item a:after {
  background: #FFFFFF;
  bottom: -1em;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.secondary-navigation .secondary-navigation__item a:hover, .secondary-navigation .secondary-navigation__item a:focus {
  text-decoration: none;
}
.secondary-navigation .secondary-navigation__item a:hover:after, .secondary-navigation .secondary-navigation__item a:focus:after {
  opacity: 1;
}
.secondary-navigation .secondary-navigation__item a .icon {
  display: none;
}
.secondary-navigation .secondary-navigation__item.active {
  font-family: "robotobold", "Gill Sans", Arial;
  text-decoration: none;
}
.secondary-navigation .secondary-navigation__item.active a:after {
  opacity: 1;
}

@media (max-width: 1198.98px) {
  .secondary-navigation .secondary-navigation__item {
    margin: 0 1.25em;
  }
}
@media (max-width: 898.98px) {
  .secondary-navigation .secondary-navigation__mobile-toggle {
    cursor: pointer;
    display: block;
    font-family: "robotobold", "Gill Sans", Arial;
    position: relative;
  }
  .secondary-navigation .secondary-navigation__mobile-toggle .icon {
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  }
  .secondary-navigation .secondary-navigation__menu {
    display: none;
  }
  .secondary-navigation .secondary-navigation__item {
    display: block;
    margin: 0.5em 0;
  }
  .secondary-navigation .secondary-navigation__item a:after {
    content: "";
    display: none;
  }
  .secondary-navigation.active {
    text-decoration: underline;
  }
  .secondary-navigation.active .secondary-navigation__mobile-toggle .icon {
    transform: rotate(180deg);
  }
}
@media (min-width: 899px) {
  .secondary-navigation .secondary-navigation__menu {
    display: block !important;
  }
}
.primary-nav-trigger .menu-burger:before, .primary-nav-trigger .menu-burger:after, .primary-nav-trigger .menu-burger {
  background-color: #FFFFFF;
  display: block;
  height: 0.2em;
  width: 1.4em;
}
.header-active .primary-nav-trigger .menu-burger:before, .primary-nav-trigger .header-active .menu-burger:before, .header-active .primary-nav-trigger .menu-burger:after, .primary-nav-trigger .header-active .menu-burger:after, .header-active .primary-nav-trigger .menu-burger, .primary-nav-trigger .header-active .menu-burger, .menu-open .primary-nav-trigger .menu-burger:before, .primary-nav-trigger .menu-open .menu-burger:before, .menu-open .primary-nav-trigger .menu-burger:after, .primary-nav-trigger .menu-open .menu-burger:after, .menu-open .primary-nav-trigger .menu-burger, .primary-nav-trigger .menu-open .menu-burger {
  background-color: #FFFFFF;
}

.primary-nav-trigger {
  display: none;
  height: 2.375em;
  padding-bottom: 0.375em;
  padding-right: 2.6em;
  padding-top: 0.375em;
  position: absolute;
  top: 50%;
  margin-top: -1.1875em;
  right: 15px;
  text-decoration: none;
}
@media (max-width: 898.98px) {
  .primary-nav-trigger {
    display: block;
    font-size: 12px;
    right: 1em;
  }
}
.header-active .primary-nav-trigger, .menu-open .primary-nav-trigger {
  color: #FFFFFF;
}
.primary-nav-trigger .menu-text {
  font-size: 0.875em;
  text-transform: uppercase;
}
.primary-nav-trigger .menu-burger {
  right: 0.6em;
  position: absolute;
  margin-top: -0.125em;
  top: 50%;
  transform: rotate(0);
  transition: background-color 0.1s 0s, transform 0.35s;
}
.primary-nav-trigger .menu-burger:before, .primary-nav-trigger .menu-burger:after {
  content: "";
  position: absolute;
  transform: rotate(0);
  transition: transform 0.14s 0s, top 0.14s 0s;
}
.primary-nav-trigger .menu-burger:before {
  transform: rotate(0) translate(0, -0.6em);
}
.no-csstransforms .primary-nav-trigger .menu-burger:before {
  top: -0.6em;
}
.primary-nav-trigger .menu-burger:after {
  transform: rotate(0) translate(0, 0.6em);
}
.no-csstransforms .primary-nav-trigger .menu-burger:after {
  top: 0.6em;
}
.menu-open .primary-nav-trigger .menu-burger {
  background: transparent;
  transform: rotate(180deg);
}
.menu-open .primary-nav-trigger .menu-burger:before, .menu-open .primary-nav-trigger .menu-burger:after {
  transition: transform 0.14s 0.08s, top 0.1s 0.04s;
}
.menu-open .primary-nav-trigger .menu-burger:before {
  transform: rotate(45deg) translate(0, 0);
}
.menu-open .primary-nav-trigger .menu-burger:after {
  transform: rotate(-45deg) translate(0, 0);
}
.no-csstransforms .menu-open .primary-nav-trigger .menu-burger {
  background: #fff;
}

.cta-panel {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  color: #FFFFFF;
  overflow: hidden;
  padding: 3.5em 0;
  position: relative;
}
.cta-panel.double {
  position: relative;
  z-index: 2;
}
.cta-panel .cta-panel__graph {
  background-image: url(../images/graphs/scatter-white.svg);
  background-size: 100%;
  height: 698px;
  opacity: 0.8;
  position: absolute;
  right: -117px;
  top: -159px;
  width: 696px;
}
.cta-panel .cta-panel__buttons {
  margin-top: 30px;
}
.cta-panel__wwd {
  background: #787878;
}
.cta-panel__wwd--blue {
  background-color: #1783BD;
}
.cta-panel__wwd--green {
  background-color: #70BC1F;
}
.cta-panel__wwd--purple {
  background-color: #3C008D;
}
.cta-panel__wwd-cta {
  color: #F2F2F2;
  text-decoration: none;
}
.cta-panel__wwd-cta span img {
  margin-left: 17px;
  padding-bottom: 2px;
}
.cta-panel__wwd-cta:hover span img {
  filter: invert(86%) sepia(98%) saturate(7348%) hue-rotate(270deg) brightness(55%) contrast(115%);
  transition: filter 0.14s;
}
.cta-panel__wwd-cta:active span img {
  filter: invert(86%) sepia(98%) saturate(7348%) hue-rotate(270deg) brightness(55%) contrast(115%);
}
.cta-panel__wwd-holder .col-lg-12,
.cta-panel__wwd-holder .col-lg-7 {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.cta-panel__wwd-holder .col-lg-5 {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 0px;
}
.cta-panel__wwd-holder .col-lg-5-right {
  padding-left: 0px;
  padding-right: 5px;
}
@media (max-width: 900px) {
  .cta-panel__wwd-holder {
    text-align: center;
  }
  .cta-panel__wwd-holder .col-lg-12,
.cta-panel__wwd-holder .col-lg-7,
.cta-panel__wwd-holder .col-lg-5 {
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.cta-panel__wwd-overlay {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.cta-panel__wwd-content {
  position: relative;
  z-index: 5;
}
.cta-panel__wwd-graph {
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 1;
}
.cta-panel__wwd-graph--angled-pie {
  background-image: url(../images/graphs/angled-pie.svg);
  background-size: 100%;
  height: 851px;
  left: -390px;
  opacity: 0.3;
  top: -238px;
  width: 792px;
}
.cta-panel__wwd-graph--overlapping-circle {
  background-image: url(../images/graphs/overlapping-circle.svg);
  background-size: 100%;
  height: 851px;
  left: -390px;
  opacity: 0.3;
  top: -238px;
  width: 792px;
}
.cta-panel__wwd-graph--snail {
  background-image: url(../images/graphs/snail-white.svg);
  background-size: 100%;
  height: 851px;
  left: -390px;
  opacity: 0.3;
  top: -238px;
  width: 792px;
}
.cta-panel__wwd-graph--tunnel {
  background-image: url(../images/graphs/tunnel.svg);
  background-size: 100%;
  height: 851px;
  left: -390px;
  opacity: 0.3;
  top: -238px;
  width: 792px;
}

@media (min-width: 599px) {
  .cta-panel.double {
    height: 100%;
  }
}
@media (max-width: 598.98px) {
  .cta-panel__wrapper {
    padding: 0;
  }

  .cta-panel__graph {
    display: none;
  }

  .cta-panel.double {
    margin: 5px 0;
  }
}
.breakout {
  background-color: #EFEEEE;
  margin-top: 4px;
  padding: 2.5em 0;
  position: relative;
}
.breakout:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: -4px;
  width: 100%;
}
.breakout h4 {
  margin-bottom: 1em;
}
.breakout .breakout__quote {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 1.5rem;
  font-family: "vollkornregular", "Times New Roman", serif;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .breakout .breakout__quote {
    background: none;
    color: #3C008D;
  }
}

@media (max-width: 598.98px) {
  .breakout__image {
    margin-bottom: 1.5em;
  }
}
.quote-header {
  font-family: "vollkornregular", "Times New Roman", serif;
}

.strip__quote {
  row-gap: 1.5em;
}

.strip__quote-card {
  background: #EFEEEE;
  margin-block-end: 1em;
  min-height: 25em;
  overflow: hidden;
  position: relative;
  z-index: 1;
  justify-content: center;
  padding: 2.25em;
}
.strip__quote-card:after {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  content: "";
  left: 0;
  top: 0;
  height: 4px;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.strip__quote-card h5 {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.25rem;
  width: fit-content;
}
.strip__quote-card p {
  font-size: 0.75rem;
}

.share-panel {
  color: #696765;
}

.share-panel__links {
  line-height: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.share-panel__links li {
  display: inline-block;
  line-height: 1;
  margin: 0 1em;
}
.share-panel__links li .icon {
  fill: #696765;
}
.share-panel__links li .icon.icon--email {
  stroke: #696765;
}

@media (max-width: 898.98px) {
  .share-panel__links li {
    margin: 0 0.5em;
  }
}
.listing-sort__title {
  margin-right: 1em;
}

.listing-item {
  border-bottom: 1px solid #696765;
  padding: 4.5em 0;
}
.listing-item:first-child {
  padding-top: 0;
}
.listing-item .listing-item__header a {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.listing-item .listing-item__header a:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translateY(-5px);
  transition: all 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.listing-item .listing-item__header a:hover:after, .listing-item .listing-item__header a:focus:after {
  opacity: 1;
  transform: translateY(0);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .listing-item .listing-item__header a {
    background: none;
    color: #3C008D;
  }
}
.listing-item .listing-item__category-wrapper {
  padding-top: 100%;
  position: relative;
  width: 100%;
}
.listing-item .listing-item__category {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.listing-item .listing-item__category-image {
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
}
.listing-item .listing-item__category-item {
  color: #FFFFFF;
  max-width: 50%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.listing-item .listing-item--blue {
  background-color: #1783BD;
}
.listing-item .listing-item--purple {
  background-color: #3C008D;
}
.listing-item .listing-item--green {
  background-color: #70BC1F;
}
.listing-item.listing-item--case-study .listing-item__category {
  background-color: #3C008D;
}
.listing-item.listing-item--news-and-insights .listing-item__category {
  background-color: #70BC1F;
}
.listing-item.listing-item--chart-of-the-month .listing-item__category, .listing-item.listing-item--top-charts .listing-item__category {
  background-color: #696765;
}
.listing-item.listing-item--events .listing-item__category {
  background-color: #1783BD;
}
.listing-item .listing-items__news {
  row-gap: 1.5em;
  column-gap: 2.5em;
}
.listing-item .listing-items__news a {
  text-decoration: none;
}
.listing-item .listing-items__news .listing-items__news-card {
  background: #EFEEEE;
  border-radius: 10px;
  height: 28.75em;
  margin-block-end: 1em;
  width: 22.125em;
  overflow: hidden;
  padding: 0 3em;
  position: relative;
  z-index: 1;
}
.listing-item .listing-items__news .listing-items__news-card:after {
  background: #3C008D;
  background: linear-gradient(136.38deg, #3C008D 36.3%, #1783BD 67.12%, #3AC7A7 94.17%, #70BC1F 117.76%);
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 16px;
  transition: width 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  position: absolute;
  z-index: -1;
}
@media (min-width: 900px) {
  .listing-item .listing-items__news .listing-items__news-card:hover h4 {
    background-image: none;
    background-color: #FFFFFF;
    text-decoration-color: rgb(255, 255, 255);
  }
  .listing-item .listing-items__news .listing-items__news-card:hover p, .listing-item .listing-items__news .listing-items__news-card:hover .alt {
    color: #FFFFFF;
  }
  .listing-item .listing-items__news .listing-items__news-card:hover:after {
    width: 100%;
  }
}
.listing-item .listing-items__news .listing-items__news-card h4 {
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: transparent;
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.5rem;
  letter-spacing: 0.075px;
  text-decoration: underline 0.05em rgba(255, 255, 255, 0);
  text-underline-offset: 0.4em;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.listing-item .listing-items__news .listing-items__news-card p {
  font-family: "robotoregular", "Gill Sans", Arial;
  font-size: 0.875em;
  line-height: 1.125em;
  text-decoration: none;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.listing-item .listing-items__news .listing-items__news-card p.alt {
  color: #8797A3;
  font-family: "robotoregular", "Gill Sans", Arial;
  text-decoration: none;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}

.listing-filter {
  overflow: hidden;
}
.listing-filter .filter-content {
  margin-block-end: 2em;
  padding-block-end: 1em;
}
.listing-filter .filter-content select {
  appearance: none;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  background-size: 1em;
  border: 1px solid #455560;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "robotoregular", "Gill Sans", Arial;
  height: 2.875em;
  width: 15em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  padding: 0 0.75em;
}
.listing-filter .filter-content .filter-content-box {
  align-items: flex-end;
  margin-bottom: 2.5em;
}
.listing-filter .filter-content .filter-content-box .btn-filter {
  width: 11.25rem;
  justify-content: center;
  text-align: center;
  padding: 0.75em 2em;
  margin-right: 1.5em;
  margin-bottom: 1em;
}
.listing-filter .filter-content .filter-content-box h5 {
  color: #455560;
  font-family: "vollkornregular", "Times New Roman", serif;
  line-height: 3rem;
}
.listing-filter .filter-content .filter-content-box p {
  font-size: 0.875rem;
}
.listing-filter .list-title .list-title-content {
  align-items: flex-end;
}
.listing-filter .list-title .list-title-content .display {
  background-color: #FFFFFF;
  border: 1px solid #455560;
  border-radius: 2px;
  box-sizing: border-box;
  color: #455560;
  font-family: "robotoregular", "Gill Sans", Arial;
  width: 3.35em;
  margin: 0 0.25em;
  padding: 0.375em;
}
.listing-filter .list-title .list-title-content h3 {
  color: #455560;
  font-family: "vollkornregular", "Times New Roman", serif;
  line-height: 2.25rem;
}
.listing-filter .list-title .list-title-content p {
  font-size: 0.875rem;
}

@media (max-width: 598.98px) {
  .listing-item__category-wrapper {
    display: none;
    margin-bottom: 1.5em;
  }

  .btn-filter {
    width: 100% !important;
  }

  .filter-content select {
    width: 100% !important;
  }
}
.article-meta .article-meta__author img, .article-meta .article-meta__speaker img {
  border: 2px solid #3C008D;
  border-radius: 50%;
}
.article-meta .article-meta__speaker {
  margin-top: 20px;
}
.article-meta .article-meta__speaker .share-panel__links li {
  margin: 0;
  padding-right: 10px;
}
.article-meta .article-meta__speaker-info {
  max-height: 145px;
}
.article-meta .article-meta__author-type,
.article-meta .article-meta__author-name,
.article-meta .article-meta__author-title,
.article-meta .article-meta__author-company,
.article-meta .article-meta__date,
.article-meta .article-meta__categories,
.article-meta .article-meta__speaker-name,
.article-meta .article-meta__speaker-company,
.article-meta .article-meta__speaker-role {
  display: block;
}
.article-meta .article-meta__type {
  font-size: 0.875rem;
}

@media (max-width: 1198.98px) {
  .article-meta .article-meta__speaker {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
@media (max-width: 898.98px) {
  .article-meta .article-meta__speaker img {
    width: 100px;
  }
}
@media (max-width: 598.98px) {
  .article-meta {
    text-align: center;
  }
  .article-meta .article-meta__speaker {
    margin-bottom: 0;
  }
}
@media (min-width: 600px) and (max-width: 900px) {
  .article-meta__speaker-info {
    margin-left: 10px;
  }
}
#form-submitted {
  height: 400px;
  text-align: center;
}

.form .form__field {
  margin-bottom: 1em;
}
.form .form__field label {
  display: block;
}
.form .form__field input,
.form .form__field textarea {
  border: 1px solid #696765;
  border-radius: 5px;
  display: block;
  line-height: 1;
  padding: 10px 20px;
  width: 100%;
}
.form .form__field input::placeholder,
.form .form__field textarea::placeholder {
  font-size: 0.875rem;
  color: #ABABAB;
  letter-spacing: 0.001875rem;
  line-height: 1.125rem;
}
.form .form__field .errors {
  color: red;
  list-style-type: none;
  padding: 0;
}

@media (max-width: 598.98px) {
  .form .form__field .btn {
    width: 100%;
  }
}
.offset-strip {
  overflow: hidden;
}
.offset-strip .offset-strip__panel {
  align-items: center;
  background-color: #FFFFFF;
  display: flex;
  padding: 3.75rem 10%;
  position: relative;
  text-align: center;
}
.offset-strip .offset-strip__panel--purple {
  background-color: #3C008D;
  color: #FFFFFF;
  overflow: hidden;
}
.offset-strip .offset-strip__panel--blue {
  background-color: #1783BD;
  color: #FFFFFF;
  overflow: hidden;
}
.offset-strip .offset-strip__panel--green {
  background-color: #70BC1F;
  color: #FFFFFF;
  overflow: hidden;
}
.offset-strip .offset-strip__panel-cta,
.offset-strip .offset-strip__panel-stat,
.offset-strip .offset-strip__panel-quote {
  position: relative;
  z-index: 5;
}
.offset-strip .offset-strip__copy {
  margin: 2em 0;
}
.offset-strip .offset-strip__copy.final {
  margin-bottom: 0;
}
.offset-strip .offset-strip__panel-stat {
  display: block;
  width: 100%;
}
.offset-strip .offset-strip__panel-stat p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
.offset-strip .offset-strip__panel-stat p.value {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 2.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .offset-strip .offset-strip__panel-stat p.value {
    background: none;
    color: #3C008D;
  }
}
.offset-strip .offset-strip__panel-quote {
  font-size: 1.875rem;
  font-weight: bold;
}
.offset-strip .offset-strip__overlay {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.offset-strip .offset-strip__graph {
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 1;
}
.offset-strip .offset-strip__graph--scatter-colour {
  background-image: url(../images/graphs/scatter-colour.svg);
  background-size: 100%;
  height: 835px;
  left: -99px;
  opacity: 0.15;
  top: -248px;
  transform: scaleX(-1);
  width: 832px;
}
.offset-strip .offset-strip__graph--scatter-colour.offset-strip__graph--right {
  left: auto;
  right: -99px;
  transform: none;
}
.offset-strip.offset-strip__wideblue {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
}
.offset-strip.offset-strip__wideblue .offset-strip__panel {
  background: transparent;
  color: #fff;
  padding: 3.125rem 10% 2.5rem;
}
.offset-strip.offset-strip__wideblue .container-fluid, .offset-strip.offset-strip__wideblue .container-xs, .offset-strip.offset-strip__wideblue .container-sm, .offset-strip.offset-strip__wideblue .container-md, .offset-strip.offset-strip__wideblue .container-lg, .offset-strip.offset-strip__wideblue .container-xl {
  background-repeat: no-repeat;
  background-position: center right -8.125rem;
}
.offset-strip.offset-strip__whitethree {
  background: #f4f4f4;
  max-height: 34.75em;
  padding-bottom: 1em;
}
.offset-strip.offset-strip__whitethree .offset-strip__panel {
  background: transparent;
  padding: 2.5rem 10% 2.5rem;
}
.offset-strip h3, .offset-strip h5 {
  font-family: "vollkornregular", "Times New Roman", serif;
}
.offset-strip.offset-strip__wideblue {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
}
.offset-strip.offset-strip__wideblue .offset-strip__panel {
  background: transparent;
  color: #fff;
  padding: 3.125rem 10% 2.5rem;
}
.offset-strip.offset-strip__wideblue .container-fluid, .offset-strip.offset-strip__wideblue .container-xs, .offset-strip.offset-strip__wideblue .container-sm, .offset-strip.offset-strip__wideblue .container-md, .offset-strip.offset-strip__wideblue .container-lg, .offset-strip.offset-strip__wideblue .container-xl {
  background-image: url("../img/shape-lexicon-bg.png");
  background-repeat: no-repeat;
  background-position: center right -8.125rem;
}
.offset-strip .offset-strip__graph--angled-pie {
  background-image: url(../images/graphs/angled-pie.svg);
  background-size: 100%;
  height: 851px;
  left: -390px;
  opacity: 0.3;
  top: -238px;
  width: 792px;
}
.offset-strip .offset-strip__heading {
  margin-bottom: 0;
}
.offset-strip .offset-strip__subheading p {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
}
.offset-strip .strip-news .offset-strip__news-heading {
  padding-bottom: 1em;
}
.offset-strip .strip-news .offset-strip__news a {
  text-decoration: none;
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card {
  background: #EFEEEE;
  border-radius: 10px;
  margin-block-end: 1em;
  height: 26em;
  max-width: 25em;
  overflow: hidden;
  padding: 0 1.5em 0 2.5em;
  position: relative;
  z-index: 1;
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card:after {
  background: #3C008D;
  background: linear-gradient(136.38deg, #3C008D 36.3%, #1783BD 67.12%, #3AC7A7 94.17%, #70BC1F 117.76%);
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 16px;
  transition: width 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  position: absolute;
  z-index: -1;
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card:hover h4 {
  background-image: none;
  background-color: #FFFFFF;
  text-decoration-color: rgb(255, 255, 255);
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card:hover p, .offset-strip .strip-news .offset-strip__news .offset-strip__news-card:hover .alt {
  color: #FFFFFF;
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card:hover:after {
  width: 100%;
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card h4 {
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: transparent;
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.625rem;
  letter-spacing: 0.075px;
  text-decoration: underline 0.05em rgba(255, 255, 255, 0);
  text-underline-offset: 0.4em;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card p {
  color: #455560;
  font-size: 0.75rem;
  margin: 0.5em 0;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.offset-strip .strip-news .offset-strip__news .offset-strip__news-card p.alt {
  color: #8797A3;
  transition: all 280ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}

@media (max-width: 598.98px) {
  .offset-strip .offset-strip__panel {
    padding: 40px 10%;
  }

  .offset-strip__whitethree {
    height: initial;
  }
}
.team-list {
  position: relative;
}
.team-list .team-list__member-container {
  position: static;
}
.team-list .team-list__member {
  cursor: pointer;
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
  text-align: center;
}
.team-list .team-list__member-image {
  background: #3C008D;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1em;
  padding: 2px;
}
.team-list .team-list__member-image img {
  border-radius: 50%;
  transition: filter 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .team-list .team-list__member-image img {
    height: 200px;
  }
}
.team-list .team-list__member-name {
  font-family: "robotobold", "Gill Sans", Arial;
  font-size: 1.125rem;
}
.team-list .team-list__member-role {
  font-family: "vollkornregular", "Times New Roman", serif;
}
.team-list .team-list__member-bio-container {
  display: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: auto;
}
.team-list .team-list__member-bio {
  background-color: #3C008D;
  color: #FFFFFF;
  font-size: 0.875rem;
  margin-bottom: 40px;
  padding: 40px 100px;
  text-align: left;
}
.team-list .team-list__member-bio .rte-copy a, .team-list .team-list__member-bio .rte-copy a:hover, .team-list .team-list__member-bio .rte-copy a:focus {
  color: #FFFFFF;
}
.team-list .team-list__member:hover .team-list__member-image img,
.team-list .team-list__member:focus .team-list__member-image img {
  padding: 1px;
}
.team-list .team-list__member-container.active .team-list__member:after {
  border-bottom: 20px solid #3C008D;
  border-left: 30px solid #FFFFFF;
  border-right: 30px solid #FFFFFF;
  bottom: -40px;
  content: "";
  display: block;
  height: 20px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  width: 60px;
}
.team-list .team-list__member-container.active .team-list__member-image {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  padding: 3px;
}
.team-list .team-list__member-container.active .team-list__member-bio-container {
  display: block;
}

@media (max-width: 1198.98px) {
  .team-list .team-list__member {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 898.98px) {
  .team-list .team-list__member {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 598.98px) {
  .team-list .team-list__member {
    padding-left: 45px;
    padding-right: 45px;
  }
  .team-list .team-list__member-bio {
    padding: 40px;
  }
}
.contact-map .contact-map__location {
  background: #EFEEEE;
  border-bottom: 3px solid #EFEEEE;
  cursor: pointer;
  padding: 1em 1.5em;
  transition: border 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.contact-map .contact-map__location:hover, .contact-map .contact-map__location:focus {
  border-bottom: 3px solid #70BC1F;
}
.contact-map .contact-map__location.active {
  background: #70BC1F;
  border-bottom: 3px solid #70BC1F;
  color: #FFFFFF;
}
.contact-map .contact-map__map-container {
  min-height: 400px;
}
.contact-map .contact-map__map-container > div {
  border-bottom: 4px solid #FFFFFF;
}
.contact-map .contact-map__map-container:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}

@media (max-width: 898.98px) {
  .contact-map .contact-map__locations {
    margin-bottom: 50px;
    position: relative;
  }
  .contact-map .contact-map__location {
    padding: 1.5em 0.5em;
    position: static;
    text-align: center;
  }
  .contact-map .contact-map__location h6, .contact-map .contact-map__location .h6 {
    font-size: 0.75rem;
    margin: 0;
  }
  .contact-map .contact-map__location.active .contact-map__address {
    background: #70BC1F;
    bottom: -50px;
    color: #FFFFFF;
    display: block;
    height: 60px;
    left: 0;
    margin: 0;
    padding: 1em;
    position: absolute;
    right: 0;
  }
  .contact-map .contact-map__location.active .contact-map__address br {
    display: none;
  }
  .contact-map .contact-map__location.active .contact-map__address.contact-map__phone-number {
    margin-bottom: -35px;
    padding-top: 0;
  }
  .contact-map .contact-map__address-title-extras {
    display: none;
  }
  .contact-map .contact-map__address {
    display: none;
  }
}
.timeline .timeline__year-title {
  background: #70BC1F;
  border-radius: 50%;
  color: #FFFFFF;
  display: block;
  font-family: "robotobold", "Gill Sans", Arial;
  font-size: 0.875rem;
  height: 65px;
  line-height: 65px;
  margin: 1em auto;
  width: 65px;
}
.timeline .timeline__blocks {
  position: relative;
}
.timeline .timeline__blocks:before {
  background: #696765;
  content: "";
  display: block;
  height: 100%;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 4px;
}
.timeline .timeline__block {
  opacity: 0;
  transform: translateX(-80px);
  transition: transform 1s cubic-bezier(0.8, 0.2, 0.2, 0.8), opacity 1s cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.timeline .timeline__block .timeline__block-inner {
  margin-top: -65px;
}
.timeline .timeline__block:first-child .timeline__block-inner {
  margin-top: 0;
}
.timeline .timeline__block .timeline__block-image {
  transform: translateX(-80px);
  transition: transform 1s cubic-bezier(0.8, 0.2, 0.2, 0.8);
}
.timeline .timeline__block .timeline__block-divider {
  padding: 0;
}
.timeline .timeline__block .timeline__block-divider hr {
  border: none;
  border-bottom: 2px solid #696765;
}
.timeline .timeline__block:nth-child(even) {
  justify-content: flex-end;
  transform: translateX(80px);
}
.timeline .timeline__block:nth-child(even) .row {
  flex-direction: row-reverse;
}
.timeline .timeline__block:nth-child(even) .timeline__block-image {
  transform: translateX(80px);
}
.timeline .timeline__block.active {
  transform: translateX(0);
  opacity: 1;
}
.timeline .timeline__block.active .timeline__block-image {
  transform: translateX(0);
}
.timeline .timeline__blocks--flip .timeline__block {
  transform: translateX(-80px);
  justify-content: flex-start;
}
.timeline .timeline__blocks--flip .timeline__block .row {
  flex-direction: row;
}
.timeline .timeline__blocks--flip .timeline__block .timeline__block-image {
  transform: translateX(-80px);
}
.timeline .timeline__blocks--flip .timeline__block:nth-child(odd) {
  justify-content: flex-end;
  transform: translateX(80px);
}
.timeline .timeline__blocks--flip .timeline__block:nth-child(odd) .row {
  flex-direction: row-reverse;
}
.timeline .timeline__blocks--flip .timeline__block:nth-child(odd) .timeline__block-image {
  transform: translateX(80px);
}
.timeline .timeline__blocks--flip .timeline__block.active {
  transform: translateX(0);
  opacity: 1;
}
.timeline .timeline__blocks--flip .timeline__block.active .timeline__block-image {
  transform: translateX(0);
}
.timeline .timeline__end {
  border-bottom: 4px solid #696765;
  display: block;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 40px;
}
.timeline .timeline__end:before {
  background: #696765;
  content: "";
  display: block;
  height: 80px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 4px;
}

.timeline-card {
  background-color: #696765;
  color: #FFFFFF;
  margin-bottom: 30px;
  position: relative;
}
.timeline-card:last-child {
  margin-bottom: 0;
}
.timeline-card .timeline-card__details {
  padding: 1em;
  position: relative;
}
.timeline-card .timeline-card__details:before {
  background-color: #696765;
  border-bottom: 24px solid #FFFFFF;
  border-top: 22px solid #FFFFFF;
  content: "";
  display: block;
  height: 44px;
  margin-top: -22px;
  position: absolute;
  top: 50%;
  width: 24px;
}
.timeline-card .timeline-card__details a {
  color: #FFFFFF;
}
.timeline-card .timeline-card__details a:hover, .timeline-card .timeline-card__details a:focus {
  color: #FFFFFF;
}
.timeline-card .timeline-card__type {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 10px;
}
.timeline-card .timeline-card__extra-toggle {
  background-color: rgba(255, 255, 255, 0.2);
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  padding: 0.4em 1em;
  text-align: center;
}
.timeline-card .timeline-card__extra-toggle .toggle-icon {
  display: inline-block;
  height: 13px;
  margin-left: 20px;
  position: relative;
  width: 13px;
}
.timeline-card .timeline-card__extra-toggle .toggle-icon:before {
  background: #FFFFFF;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}
.timeline-card .timeline-card__extra-toggle .toggle-icon:after {
  background: #FFFFFF;
  bottom: 0;
  content: "";
  left: 5px;
  position: absolute;
  top: 0;
  transition: transform 140ms cubic-bezier(0.8, 0.2, 0.2, 0.8);
  width: 3px;
}
.timeline-card .timeline-card__extra-toggle:hover, .timeline-card .timeline-card__extra-toggle:focus {
  background-color: rgba(255, 255, 255, 0.4);
}
.timeline-card .timeline-card__extra.active .timeline-card__extra-toggle .toggle-icon:after {
  transform: rotate(90deg);
}
.timeline-card .timeline-card__extra-content {
  display: none;
  font-size: 0.875rem;
  overflow: hidden;
  padding: 1em;
}
.timeline-card .timeline-card__extra-content p, .timeline-card .timeline-card__extra-content a {
  font-size: 0.875rem;
}
.timeline-card.timeline-card--purple {
  background-color: #3C008D;
}
.timeline-card.timeline-card--purple .timeline-card__details:before {
  background-color: #3C008D;
}
.timeline-card.timeline-card--blue {
  background-color: #1783BD;
}
.timeline-card.timeline-card--blue .timeline-card__details:before {
  background-color: #1783BD;
}
.timeline-card.timeline-card--green {
  background-color: #70BC1F;
}
.timeline-card.timeline-card--green .timeline-card__details:before {
  background-color: #70BC1F;
}
.timeline__block:nth-child(odd) .timeline-card {
  margin-right: 24px;
}
.timeline__block:nth-child(odd) .timeline-card .timeline-card__details:before {
  border-left: 24px solid transparent;
  right: -24px;
}
.timeline__block:nth-child(even) .timeline-card {
  margin-left: 24px;
}
.timeline__block:nth-child(even) .timeline-card .timeline-card__details:before {
  border-right: 24px solid transparent;
  left: -24px;
}
.timeline__blocks--flip .timeline__block:nth-child(even) .timeline-card {
  margin-left: 0;
  margin-right: 24px;
}
.timeline__blocks--flip .timeline__block:nth-child(even) .timeline-card .timeline-card__details:before {
  border-left: 24px solid transparent;
  border-right: none;
  left: auto;
  right: -24px;
}
.timeline__blocks--flip .timeline__block:nth-child(odd) .timeline-card {
  margin-left: 24px;
  margin-right: 0;
}
.timeline__blocks--flip .timeline__block:nth-child(odd) .timeline-card .timeline-card__details:before {
  border-left: none;
  border-right: 24px solid transparent;
  left: -24px;
  right: auto;
}

@media (max-width: 898.98px) {
  .timeline .timeline__block-image {
    display: none;
  }
  .timeline .timeline__block-divider {
    display: none;
  }
}
@media (max-width: 598.98px) {
  .timeline .timeline__blocks:before {
    height: 115%;
    top: -14px;
  }
  .timeline .timeline__end {
    top: -14px;
  }
  .timeline .timeline__block {
    opacity: 1;
  }
  .timeline .timeline__block .timeline__block-inner {
    margin-top: 40px;
  }
  .timeline .timeline__block .timeline-card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .timeline .timeline__block .timeline-card .timeline-card__details:before {
    display: none;
  }
}
.background-graph {
  background-repeat: no-repeat;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  z-index: 0;
}
@media (max-width: 1024px) {
  .background-graph {
    display: none;
  }
}

.background-graph--overlapping-circle {
  background-position: -282px 0;
  background-image: url(../images/graphs/overlapping-circle-blue.svg);
  height: 785px;
  width: 782px;
}
.background-graph--overlapping-circle.background-graph--right {
  left: auto;
  right: 0;
  transform: scale(-1);
}

.background-graph--circle-line {
  background-position: -350px 0;
  background-image: url(../images/graphs/circle-line.svg);
  height: 992px;
  width: 988px;
}
.background-graph--circle-line.background-graph--right {
  background-position: 488px 0;
  left: auto;
  right: 0;
}

.background-graph--scatter {
  background-position: 100px 0;
  background-image: url(../images/graphs/scatter-colour.svg);
  height: 785px;
  transform: scale(-1);
  width: 500px;
}
.background-graph--scatter.background-graph--right {
  background-position: 100px 0;
  left: auto;
  right: 0;
  transform: none;
}

.background-graph--scatter-plain {
  background-position: -322px 0;
  background-image: url(../images/graphs/scatter-grey.svg);
  height: 594px;
  width: 592px;
}
.background-graph--scatter-plain.background-graph--right {
  background-position: 282px 0;
  left: auto;
  right: 0;
}

.background-graph--snail {
  background-position: 180px 0;
  background-image: url(../images/graphs/snail-colour.svg);
  height: 595px;
  transform: scale(-1);
  width: 593px;
}
.background-graph--snail.background-graph--right {
  background-position: 180px 0;
  left: auto;
  right: 0;
  transform: none;
}

.access-links {
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  /* z-index */
  z-index: 20;
}
.access-links__item:not(:focus) {
  clip: rect(0 0 0 0);
  color: #FFFFFF;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
}
.access-links__item:focus {
  background-color: #1783BD;
  box-shadow: 0px -3px 5px 5px rgba(0, 0, 0, 0.75);
  color: #FFFFFF;
  display: block;
  outline: none;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: underline;
}

.cookie-notification {
  background: #212121;
  bottom: 0;
  color: #fff;
  opacity: 0;
  position: fixed;
  text-align: left;
  transition: opacity 1s;
  width: 100%;
  z-index: 1001;
  display: none;
}
.cookie-notification.show-notification {
  opacity: 1;
  display: block;
}
.cookie-notification .cookie-notification-hide {
  text-align: center;
}
.cookie-notification .cookie-notification-hide a {
  margin-right: 10px;
}
.cookie-notification a {
  color: #fff;
}

@media (max-width: 598.98px) {
  .cookie-notification .cookie-notification-hide {
    padding-bottom: 36px;
    padding-top: 20px;
  }
}
.breakout-copy {
  text-align: center;
}

.event-info {
  margin: auto;
  max-width: 100%;
  padding-top: 20px;
}
.event-info__time, .event-info__location {
  align-items: flex-start;
  display: inline-flex;
  margin-bottom: 1em;
  max-height: fit-content;
}
.event-info__time p, .event-info__location p {
  margin: 0;
}
.event-info .icon {
  fill: #7ED321;
  flex-shrink: 0;
  margin-right: 10px;
}

.event-listing {
  font-size: 40px;
}
.event-listing .time-banner {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  display: block;
  height: 20%;
  left: 0;
  max-width: 100%;
  position: absolute;
  width: 100%;
}
@media (max-width: 898.98px) {
  .event-listing .time-banner p {
    font-size: 2vw;
    transform: translateY(-60%);
  }
}
@media (max-width: 1198.98px) {
  .event-listing {
    font-size: 2vw;
  }
}

.full-width-video {
  overflow-x: hidden;
}
.full-width-video .event-video, .full-width-video .event-video-panel, .full-width-video .video-shader, .full-width-video .copy-container, .full-width-video iframe {
  min-height: 500px;
}
@media (max-width: 1198.98px) {
  .full-width-video .event-video, .full-width-video .event-video-panel, .full-width-video .video-shader, .full-width-video .copy-container, .full-width-video iframe {
    min-height: 350px;
  }
}
.full-width-video .event-video {
  display: none;
  left: 0;
  position: absolute;
  width: 100%;
}
.full-width-video .event-video iframe {
  background: #000;
}
.full-width-video .event-video-panel .copy-container {
  margin: 0;
}
.full-width-video .event-video-panel .copy-container.background-blue {
  background-color: rgba(23, 131, 189, 0.8);
}
.full-width-video .event-video-panel .copy-container.background-darkBlue {
  background-color: rgba(19, 0, 103, 0.8);
}
.full-width-video .event-video-panel .copy-container.background-green {
  background-color: rgba(112, 188, 31, 0.8);
}
.full-width-video .event-video-panel .copy-container.background-purple {
  background-color: rgba(60, 0, 141, 0.8);
}
.full-width-video .event-video-panel .copy-container .video-copy {
  color: #fff;
  margin: auto;
  margin-top: 190px;
  text-align: center;
}
@media (max-width: 1198.98px) {
  .full-width-video .event-video-panel .copy-container .video-copy {
    margin-top: 110px;
  }
}
.full-width-video .event-video-panel .copy-image {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}
@media (max-width: 1198.98px) {
  .full-width-video .event-video-panel .copy-image {
    min-height: 350px;
  }
}
.full-width-video .event-video-panel .video-placeholder {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.full-width-video .event-video-panel .video-placeholder img {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 40%;
}
@media (max-width: 1198.98px) {
  .full-width-video .event-video-panel .video-placeholder {
    min-height: 350px;
  }
}
@media (min-width: 899px) {
  .full-width-video .event-video-panel {
    margin: auto;
  }
}

#content .clickdform .responsiveCell {
  display: block;
}
#content .clickdform .responsiveCell div.alignTop {
  display: block;
}
#content .clickdform .responsiveRow {
  display: block;
  font-size: 1em;
}
#content .clickdform .responsiveRow:before {
  clear: both;
  content: " ";
  display: table;
}
#content .clickdform .responsiveCellSize1 {
  min-width: 0;
  width: auto;
}
#content .clickdform .minSize1 {
  min-width: 0;
  width: auto;
}
#content .clickdform .maxSize1 {
  max-width: none;
}
#content .clickdform .requiredStar {
  font: inherit;
  color: inherit;
  padding: 0;
}
#content .clickdform .floatLeft {
  float: none;
}
#content .clickdform span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#000000;"] {
  font-family: inherit !important;
  font-size: 1em !important;
  color: inherit !important;
  margin-bottom: 1em !important;
}
#content .clickdform span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#455560;"] {
  font-family: Arial !important;
}
#content .clickdform textarea[style="height:100px; font-family:Verdana;"] {
  height: auto !important;
  font-family: inherit !important;
}
#content .clickdform div[style="font-size:13px"] {
  font-size: 1em !important;
}
#content .clickdform input {
  max-width: none;
}
#content .clickdform font {
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}

#content .clickdform fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
#content .clickdform .responsiveRow {
  margin-bottom: 1em;
  margin-top: 1em;
}
#content .clickdform .responsiveRow label,
#content .clickdform .responsiveRow input[type=text],
#content .clickdform .responsiveRow input[type=email],
#content .clickdform .responsiveRow textarea {
  display: block;
}
#content .clickdform .responsiveRow input[type=text],
#content .clickdform .responsiveRow input[type=email],
#content .clickdform .responsiveRow textarea {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  background-color: transparent;
  border: 1px solid #696765;
  border-radius: 5px;
  height: 3.29em;
  line-height: 3.29em;
  padding-left: 1.75em;
  padding-right: 1.75em;
  resize: none;
  width: 100%;
}
#content .clickdform .responsiveRow input[type=text]:disabled,
#content .clickdform .responsiveRow input[type=email]:disabled,
#content .clickdform .responsiveRow textarea:disabled {
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}
#content .clickdform .responsiveRow textarea {
  line-height: 1.9;
  min-height: 13em;
  padding-bottom: 1em;
  padding-top: 1em;
}

#content .clickdform input.floatLeft[type=checkbox] + span[style="font-family:Verdana; font-weight:normal; font-size:13px; color:#000000;"] {
  font-famiyl: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  padding-left: 0.5em;
  position: relative;
}

#content .clickdform .buttonContainer {
  font-family: inherit !important;
  font-size: inherit !important;
  background-color: #FFFFFF;
  border-radius: 10em;
  display: inline-block;
  margin-right: 2px !important;
  margin-top: 25px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
.lt-ie9 #content .clickdform .buttonContainer {
  color: #FFFFFF;
}
#content .clickdform .buttonContainer:before {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  background-size: 200%;
  border-radius: 10em;
  bottom: -2px;
  content: "";
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  transition: 0.28s;
  z-index: -1;
}
#content .clickdform .buttonContainer input[type=button] {
  background: transparent !important;
  border: none;
  color: inherit !important;
  cursor: pointer;
  margin: 0 !important;
  outline: none;
  padding: 0.75em 2em;
  font-family: inherit !important;
  font-size: 1em !important;
}
#content .clickdform .buttonContainer:hover {
  background-color: transparent;
}
#content .clickdform .buttonContainer:hover input[type=button] {
  color: #FFFFFF !important;
}

form .freeform-row .freeform-column {
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
}
form .freeform-row .freeform-column label.freeform-label {
  font-weight: normal;
}
form .freeform-row .freeform-column .freeform-input {
  border: 1px solid #696765;
  border-radius: 5px;
  display: block;
  height: 44px;
  line-height: 1;
  padding: 10px 20px;
}
form .freeform-row .freeform-column .freeform-input::placeholder {
  font-size: 0.875rem;
  color: #ABABAB;
  letter-spacing: 0.001875rem;
  line-height: 1.125rem;
}

@media (max-width: 598.98px) {
  form .freeform-row {
    display: block;
  }
}
.location-switcher {
  position: relative;
}
.location-switcher:hover .location-switcher__current {
  background: #201751;
  border-left-color: transparent;
  border-right-color: transparent;
}
.location-switcher:hover .location-switcher__current:after {
  transform: rotateZ(-45deg);
}
.location-switcher:hover .location-switcher__current:before {
  display: block;
}
.location-switcher:hover .location-switcher__locations {
  display: block;
}
.location-switcher__current {
  background-color: #1D1648;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  width: 90px;
}
.location-switcher__current img {
  height: 28px;
  width: 28px;
}
.location-switcher__current:after {
  border-top: 2px solid #FFFFFF;
  border-right: 2px solid #FFFFFF;
  border-radius: 2px;
  content: "";
  height: 8px;
  position: absolute;
  right: 15px;
  top: 20px;
  transform: rotateZ(135deg);
  width: 8px;
}
.location-switcher__current:focus, .location-switcher__current:active {
  background: #201751;
  border-left-color: transparent;
  border-right-color: transparent;
}
.location-switcher__current:focus:after, .location-switcher__current:active:after {
  transform: rotateZ(-45deg);
}
@media (max-width: 598.98px) {
  .location-switcher__current:before {
    background: #201751;
    box-sizing: content-box;
    border-left: 2px solid #201751;
    border-right: 2px solid #201751;
    content: "";
    display: none;
    height: 94px;
    left: -2px;
    position: absolute;
    top: -20px;
    width: 100%;
    z-index: -1;
  }
  .location-switcher__current:focus:before, .location-switcher__current:active:before {
    display: block;
  }
}
.location-switcher__locations {
  background: #201751;
  display: none;
  right: 0;
  list-style: none;
  padding: 0.4375rem 0;
  position: absolute;
  top: 105%;
  width: 218px;
  z-index: 401;
}
.location-switcher__current:focus + .location-switcher__locations {
  display: block;
}
@media (max-width: 598.98px) {
  .location-switcher__locations {
    position: fixed;
    top: 105px;
    left: 0;
    width: 100%;
  }
}
@media (min-width: 599px) {
  .location-switcher__locations {
    top: 105% !important;
  }
}
.location-switcher__location {
  align-items: center;
}
.location-switcher__location:hover {
  background-color: #130D36;
}
.location-switcher__link {
  color: white;
  display: flex;
  flex-direction: row;
  padding: 0.4375rem 0 0.4375rem 1.5rem;
  text-decoration: none;
}
.location-switcher__link img {
  height: 25px;
  width: 25px;
  margin-right: 1.0625rem;
}
.location-switcher__link p {
  margin: 0;
  font-size: 0.875rem;
}
.location-switcher__link--active p {
  text-decoration: underline;
}
.location-switcher__link:hover, .location-switcher__link:active, .location-switcher__link:focus {
  color: #FFFFFF;
}
@media (max-width: 598.98px) {
  .location-switcher__link {
    padding-left: 0;
    max-width: 200px;
    margin: 0 auto;
  }
}

.location-banner {
  position: relative;
  display: none;
  background-color: #1D1648;
  color: #FFFFFF;
  padding: 10px 0;
  z-index: 1000;
}
.location-banner--show {
  display: block;
}
.location-banner__select-item {
  border-radius: 5px;
  padding: 10px 5px;
  width: 270px;
}
@media (max-width: 440px) {
  .location-banner__select-item {
    width: 100%;
  }
}
.location-banner__confirm-btn {
  background-color: #1D1648;
  color: #FFFFFF;
  width: 100%;
}
.location-banner .btn {
  border: #FFFFFF 1px solid;
}

.glossary__header-overlay {
  background-image: url("../images/shape-lexicon-bg.png");
  background-position: top -130px right -150px;
  background-repeat: no-repeat;
  background-size: 440px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.glossary__header-wrap {
  background: #3c008d;
  background: linear-gradient(110deg, #3c008d, #1783bd 70%, #3ac7a7 85%, #70bc1f);
  width: 100%;
  top: 0;
}
.glossary__overlay {
  background-image: url("../images/overlays/Homepage.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 100%;
}
.glossary .definition-header {
  margin-top: 3.125rem;
}
.glossary .definition-header .icon-link {
  text-decoration: none;
}
.glossary .definition-header .icon-link:hover {
  text-decoration: underline;
}
.glossary .definition-header a .icon {
  margin-right: 0.625rem;
}
.glossary .header {
  background: transparent;
  position: relative;
}
.glossary .heading {
  padding-bottom: 1.5625rem;
  padding-top: 6.75rem;
}
.glossary .heading .form-link {
  text-align: right;
}
.glossary .heading .form-link a {
  color: #fff;
  text-decoration: none;
}
.glossary .heading .form-link a:hover {
  text-decoration: underline;
}
.glossary .heading .form-link a .icon {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}
.glossary .heading .subtitle {
  color: #fff;
  letter-spacing: 0.0025rem;
}
.glossary .heading h1 {
  color: #fff;
  letter-spacing: 0.00625rem;
  line-height: 4.25rem;
}
.glossary .keep-exploring {
  background: #3c008d;
  background: linear-gradient(110deg, #3c008d, #1783bd 70%, #3ac7a7 85%, #70bc1f);
  padding: 1.25rem;
}
.glossary .keep-exploring h6 {
  color: #efeeee;
  display: inline-block;
  font-size: 1.5rem;
  letter-spacing: 0.00313rem;
  line-height: 2.25rem;
  margin-right: 3.4375rem;
}
.glossary .search-bar {
  background: #3d269a;
  border-top: 2px solid #fff;
  padding-top: 1.875rem;
  position: relative;
}
.glossary .search-bar .anchors {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}
.glossary .search-bar .anchors__wrapper {
  margin: 0.625rem auto;
  min-width: 55.625rem;
}
.glossary .search-bar .anchors a {
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #fff;
  display: inline-block;
  padding: 0.125rem 0.5rem;
  text-decoration: none;
  width: 1.875rem;
}
.glossary .search-bar .anchors a .active {
  background-color: #fff;
  color: #3d269a;
  transition: all 0.1s ease-in;
  transition-delay: 0s;
  transition-delay: 0.1s;
}
.glossary .search-bar .anchors a:hover {
  background-color: #fff;
  color: #3d269a;
}
.glossary .search-bar .anchors span {
  color: hsla(0deg, 0%, 100%, 0.5);
  padding: 0.625rem;
}
.glossary .search-bar .search-field__animation {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.125rem;
  left: 0;
  letter-spacing: 0;
  line-height: 1;
  padding-left: 2.5rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -ms-flex-align: center;
  -webkit-box-align: center;
}
.glossary .search-bar .search-field__animation-text {
  margin-left: 0.625rem;
}
.glossary #content .definition-list__item {
  border-bottom: 1px solid rgba(69, 85, 96, 0.4);
  margin-top: 2.5rem;
  padding-bottom: 1.875rem;
}
.glossary #content .definition-list__item:first-child {
  margin-top: 0.625rem;
}
.glossary #content .definition-list__item:last-child {
  border: 0;
}
.glossary #content .definition-list__item:hover {
  cursor: pointer;
  position: relative;
}
.glossary #content .definition-list__item:hover::before {
  box-shadow: 0 2rem 2rem -3rem rgba(0, 0, 0, 0.5);
  content: " ";
  height: 80%;
  left: 1%;
  position: absolute;
  top: 20%;
  width: 96%;
}
.glossary #content .definition-list__item h2 {
  color: #323f49;
  display: inline-block;
  font-size: 1.875rem;
  letter-spacing: 0.00375rem;
  line-height: 2.625rem;
  position: relative;
  word-break: break-word;
}
.glossary #content .definition-list__item h2 a {
  text-decoration: none;
}
.glossary #content .definition-list__item h2 a .icon {
  fill: #323f49;
  height: 1.5625rem;
  margin-left: 0.625rem;
  transform: scaleX(-1);
  vertical-align: middle;
  width: 1.5625rem;
  -webkit-transform: scaleX(-1);
}
.glossary #content .definition-list__item h2 span {
  position: relative;
}
.glossary #content .definition-list .letter-header h2 {
  margin: 0;
  overflow: hidden;
  position: relative;
}
.glossary #content .definition-list .letter-header h2::after {
  background: #3c008d;
  background: linear-gradient(90deg, #3c008d, #1783bd 70%, #3ac7a7 85%, #70bc1f);
  content: "";
  display: block;
  height: 2px;
  left: 3.125rem;
  position: absolute;
  top: 50%;
  width: 100%;
}
.glossary #content .glossary-intro {
  border-bottom: 1px solid #094267;
  padding-bottom: 0;
}
.glossary #content .phonetic {
  color: #696765;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  margin-top: 0;
}
.glossary #content h1 {
  color: #323f49;
  font-family: vollkornregular, Times New Roman, serif;
  font-size: 2.25rem;
  letter-spacing: 0.005rem;
  line-height: 3rem;
  margin-top: 0.9375rem;
  word-break: break-word;
}
.glossary #main-content .definition-intro p {
  font-family: vollkornregular, Times New Roman, serif;
  font-size: 1.1875rem;
  letter-spacing: 0;
  line-height: 1.875rem;
}
.glossary #sidebar {
  margin-top: -3.75rem;
}
.glossary #sidebar .form-cta .icon-link {
  color: #130067;
  text-decoration: none;
}
.glossary #sidebar .form-cta .icon-link:hover {
  text-decoration: underline;
}
.glossary #sidebar .form-cta .icon-link .icon {
  margin-left: 0.625rem;
}
.glossary #sidebar .section {
  padding: 1.875rem 0 0.3125rem;
}

.search-page .header {
  position: fixed;
  top: 0;
  width: 100%;
}

#searchinput {
  background: rgba(0, 0, 0, 0.2);
  background-image: url("../images/graphs/icon-search.svg");
  background-position: center right 1.875rem;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 30px;
  color: #fff;
  font-family: Roboto-Regular;
  font-size: 1.125rem;
  letter-spacing: 0;
  outline: none;
  padding: 0.9375rem 2.1875rem;
  width: 100%;
}

.glossary.search-page #content {
  padding-top: 3.125rem;
}
.glossary #content {
  background: #fff;
  padding-bottom: 3.75rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 598.98px) {
  .glossary #content {
    padding-top: 0.9375rem;
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 598.98px) {
  .glossary #content h1 {
    font-size: 2.625rem;
    letter-spacing: 0.005625rem;
    line-height: 3rem;
  }
}
.glossary #content .glossary-intro {
  border-bottom: 1px solid #094267;
  padding: 2em 0;
  margin: 0;
}
@media (max-width: 598.98px) {
  .glossary #content .glossary-intro {
    padding-top: 0.0625rem;
  }
}
.glossary #content .glossary-intro h1 {
  font-size: 2.25rem;
  font-family: "vollkornregular", "Times New Roman", serif;
  letter-spacing: 0.005rem;
  line-height: 3rem;
  margin-top: 0.9375rem;
  word-break: break-word;
}
@media (max-width: 598.98px) {
  .glossary #content .glossary-intro h1 {
    font-size: 1.5rem;
    letter-spacing: 0.003125rem;
    line-height: 2.25rem;
  }
}
.glossary #content .glossary-intro .phonetic {
  font-family: Roboto-Regular;
  font-size: 1rem;
  color: #696765;
  letter-spacing: 0;
  margin-top: 0;
}
.glossary #content .definition-list .letter-header h2 {
  position: relative;
  overflow: hidden;
  margin: 0;
}
.glossary #content .definition-list .letter-header h2:after {
  background: #3c008d;
  background: linear-gradient(90deg, #3c008d, #1783bd 70%, #3ac7a7 85%, #70bc1f);
  content: "";
  display: block;
  height: 2px;
  left: 3.125rem;
  position: absolute;
  top: 50%;
  width: 100%;
}
.glossary #content .definition-list__item {
  margin-top: 2.5rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid rgba(69, 85, 96, 0.4);
}
.glossary #content .definition-list__item:first-child {
  margin-top: 0.625rem;
}
.glossary #content .definition-list__item:last-child {
  border: 0;
}
.glossary #content .definition-list__item h2 {
  font-size: 1.875rem;
  color: #323f49;
  letter-spacing: 0.00375rem;
  line-height: 2.625rem;
  position: relative;
  display: inline-block;
  word-break: break-word;
}
.glossary #content .definition-list__item h2 span {
  position: relative;
}
.glossary #content .definition-list__item h2 a {
  text-decoration: none;
}
.glossary #content .definition-list__item h2 a:hover {
  text-decoration: none;
}
.glossary #content .definition-list__item h2 a:focus span {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .glossary #content .definition-list__item h2 a:focus span {
    background: none;
    color: #130067;
  }
}
.glossary #content .definition-list__item h2 a:focus span::after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.glossary #content .definition-list__item h2 a .icon {
  fill: #323f49;
  transform: scaleX(-1);
  vertical-align: middle;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-left: 0.625rem;
}
.glossary #content .definition-list__item p a {
  text-decoration: none;
  color: #130067;
}
.glossary #content .definition-list__item p a:hover, .glossary #content .definition-list__item p a:focus {
  text-decoration: underline;
}
.glossary #content .definition-list__item .content-parent .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.625rem;
  vertical-align: text-top;
}
@media (hover: hover) {
  .glossary #content .definition-list__item:hover {
    cursor: pointer;
    position: relative;
  }
  .glossary #content .definition-list__item:hover:before {
    box-shadow: 0 2rem 2rem -3rem rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 80%;
    width: 96%;
    top: 20%;
    left: 1%;
    content: " ";
  }
  .glossary #content .definition-list__item:hover span {
    background: #3C008D;
    background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}
@media (hover: hover) and (-ms-high-contrast: none), (hover: hover) and (-ms-high-contrast: active) {
  .glossary #content .definition-list__item:hover span {
    background: none;
    color: #130067;
  }
}
@media (hover: hover) {
  .glossary #content .definition-list__item:hover span::after {
    background: #3C008D;
    background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.glossary .definition-header {
  margin-top: 3.125rem;
}
@media (max-width: 598.98px) {
  .glossary .definition-header {
    margin-top: 0;
  }
}
.glossary .definition-header a .icon {
  margin-right: 0.625rem;
}
.glossary .definition-header a:hover, .glossary .definition-header a:focus {
  text-decoration: underline;
}
.glossary #main_content {
  margin-top: 0.625rem;
}
.glossary #main_content .definition-intro p {
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.1875rem;
  letter-spacing: 0;
  line-height: 1.875rem;
}
.glossary #main_content .content-parent h3 {
  font-size: 1.25rem;
  color: #787878;
  letter-spacing: 0.0025rem;
  line-height: 2.4375rem;
}
.glossary #main_content .content-parent h2 {
  font-size: 1.875rem;
  color: #787878;
  letter-spacing: 0.0025rem;
  line-height: 2.4375rem;
}
.glossary #main_content .content-parent .icon-link {
  color: #130067;
  display: inline-block;
  margin-top: 2.5rem;
}
.glossary #main_content .content-parent .icon-link .icon {
  margin-left: 0.625rem;
}
.glossary #main_content .content-parent .icon-link:hover, .glossary #main_content .content-parent .icon-link:focus {
  text-decoration: underline;
}
.glossary #main_content .image-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.glossary #sidebar {
  margin-top: -3.75rem;
}
@media (max-width: 598.98px) {
  .glossary #sidebar {
    margin: 0;
  }
}
.glossary #sidebar .section {
  padding: 1.875rem 0 0.3125rem;
  margin: 0;
}
.glossary #sidebar h3 {
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.5rem;
  color: #323f49;
  letter-spacing: 0.003125rem;
  line-height: 2.25rem;
}
.glossary #sidebar .glossary-related-terms ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.glossary #sidebar .glossary-related-terms ul li {
  margin: 0.75rem 0;
}
.glossary #sidebar .glossary-related-terms ul li a {
  color: #696765;
  letter-spacing: 0.0025rem;
}
.glossary #sidebar .share-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.glossary #sidebar .share-panel ul li {
  display: inline-block;
  margin-right: 0.9375rem;
}
.glossary #sidebar .share-panel .icon--social:hover, .glossary #sidebar .share-panel .icon--social:focus {
  color: #130067;
  fill: #130067;
}
.glossary #sidebar .share-panel .icon--email:hover, .glossary #sidebar .share-panel .icon--email:focus {
  stroke: #130067;
}
.glossary #sidebar .form-cta p {
  margin-top: 0;
}
.glossary #sidebar .form-cta .icon-link {
  color: #130067;
}
.glossary #sidebar .form-cta .icon-link .icon {
  margin-left: 0.625rem;
}
@media (hover: hover) {
  .glossary #sidebar .form-cta .icon-link:hover {
    text-decoration: underline;
  }
}
.glossary #sidebar .form-cta .icon-link:focus {
  text-decoration: underline;
}
.glossary--related-content {
  margin-top: 3.75rem;
}
@media (max-width: 598.98px) {
  .glossary--related-content {
    margin-top: 2.5rem;
  }
  .glossary--related-content h2 {
    font-size: 2.25rem;
    letter-spacing: 0.005rem;
    line-height: 2.625rem;
  }
}
.glossary--related-content__wrapper {
  display: flex;
  margin: 2.5rem auto 3.125rem;
  overflow: auto;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.glossary--related-content__wrapper:hover {
  cursor: pointer;
}
.glossary--related-content__wrapper::-webkit-scrollbar {
  display: none;
}
.glossary--related-content__items {
  display: flex;
  margin: 0 auto;
}
.glossary--related-content .related-item {
  margin: 0 0.8125rem;
  padding: 1.5625rem 0.9375rem 1.25rem;
  display: inline-block;
  width: 16.25rem;
  height: 18.4375rem;
  vertical-align: top;
  position: relative;
  background-size: cover;
}
@media (max-width: 598.98px) {
  .glossary--related-content .related-item {
    margin: 0 0.3125rem;
  }
}
.glossary--related-content .related-item:hover .icon-link, .glossary--related-content .related-item:focus .icon-link {
  text-decoration: underline;
}
.glossary--related-content .related-item .gradient-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
.glossary--related-content .related-item .icon-link {
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.25rem;
  letter-spacing: 0.0025rem;
  line-height: 1.6875rem;
  position: relative;
}
.glossary--related-content .related-item .icon-link:hover, .glossary--related-content .related-item .icon-link:focus {
  text-decoration: underline;
}
.glossary--related-content .related-item .icon-link .icon {
  transform: scaleX(-1);
  margin-left: 0.3125rem;
}
.glossary--related-content .related-item-type {
  font-size: 0.875rem;
  letter-spacing: 0.001875rem;
  line-height: 1.5rem;
  position: relative;
}
.glossary--related-content .related-item-country {
  position: absolute;
  bottom: 10px;
}
.glossary .contact-form {
  padding-bottom: 0;
}
.glossary .keep-exploring {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  padding: 1.25rem;
}
@media (max-width: 598.98px) {
  .glossary .keep-exploring {
    display: none;
  }
}
.glossary .keep-exploring h6 {
  display: inline-block;
  font-size: 1.5rem;
  color: #EFEEEE;
  letter-spacing: 0.003125rem;
  line-height: 2.25rem;
  margin-right: 3.4375rem;
}
.glossary .term-form .form .btn {
  min-width: 8.75rem;
  font-family: Roboto-Medium;
  color: #455560;
}
.glossary .term-form .form label, .glossary .term-form .form .text-small {
  font-family: Roboto-Medium;
}
.glossary .term-form__intro h1 {
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 2.625rem;
  color: #455560;
  letter-spacing: 0.005625rem;
  text-align: center;
  line-height: 3.375rem;
}
.glossary .term-form__intro .form-introduction p {
  margin: 0.625rem 0;
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.5rem;
  color: #455560;
  letter-spacing: 0.003125rem;
  text-align: center;
  line-height: 2.25rem;
}

.thanks-modal {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(33, 33, 33, 0.2);
  z-index: 20;
  top: 0;
  left: 0;
}
.modal-active .thanks-modal {
  display: block;
}
.thanks-modal__window {
  background: #FFFFFF;
  box-shadow: 10px 12px 36px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 4.375rem 8.75rem 3.75rem;
}
@media (max-width: 898.98px) {
  .thanks-modal__window {
    padding: 2.1875rem 4.375rem 1.875rem;
  }
}
.thanks-modal__window:after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  bottom: -4px;
  width: 100%;
}
.thanks-modal__window .close-modal {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
}
.thanks-modal__window .close-modal .icon {
  fill: #3C008D;
  width: 2.3125rem;
  height: 2.3125rem;
}
.thanks-modal__window h3 {
  margin: 0;
  font-size: 2.625rem;
  color: #455560;
  letter-spacing: 0.005625rem;
  line-height: 2.25rem;
}
.thanks-modal__window h4 {
  margin: 0;
  font-size: 1.875rem;
  color: #455560;
  letter-spacing: 0.005625rem;
  line-height: 2.25rem;
}
.thanks-modal__window p {
  letter-spacing: 0.0025rem;
}

#results {
  display: none;
  max-height: calc(100vh - 402px);
  overflow-y: scroll;
  border-bottom: 4px solid #3C269A;
  padding: 2.5rem 2.5rem 1.25rem 2.5rem;
  width: 88%;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.sticky-search #results {
  max-height: calc(100vh - 195px);
}
.search-active #results {
  display: block;
}
@media (max-width: 898.98px) {
  .search-active #results {
    max-height: calc(100vh - 195px);
  }
}
@media (max-width: 898.98px) {
  .search-active #results {
    max-height: calc(100vh - 195px);
  }
}
@media (max-width: 898.98px) {
  #results {
    width: 85%;
    padding: 1.25rem;
  }
}
@media (max-width: 598.98px) {
  #results {
    width: 100%;
    top: 6.3125rem;
    max-height: calc(100vh - 337px);
  }
  .sticky-search #results {
    max-height: calc(100vh - 175px);
  }
}
#results ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#results ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (max-width: 598.98px) {
  #results ul li {
    margin-bottom: 1.25rem;
    padding-bottom: 0;
  }
}
#results ul li h3 {
  font-family: "vollkornregular", "Times New Roman", serif;
  font-size: 1.5rem;
  color: #455560;
  letter-spacing: 0.003125rem;
  line-height: 2.25rem;
}
#results ul li p {
  font-family: Roboto-Regular;
  font-size: 1rem;
  color: #455560;
  letter-spacing: 0.0025rem;
  line-height: 1.5rem;
}
@media (max-width: 598.98px) {
  #results ul li p {
    display: none;
  }
}
#results ul li a {
  text-decoration: none;
}
#results ul li:hover {
  cursor: pointer;
  box-shadow: 0 1.125rem 2.0625rem -1.75rem rgba(0, 0, 0, 0.3);
}
#results ul li:hover a {
  text-decoration: underline;
}
#results ul li span {
  background: #D0CCE1;
}
#results #no-results-message h5 {
  font-size: 1.625rem;
  color: #323F49;
  letter-spacing: 0.003125rem;
  line-height: 2.25rem;
}
#results #no-results-message .popular {
  margin-top: 2.5rem;
}
#results #no-results-message .popular h6 {
  font-size: 1.3125rem;
  letter-spacing: 0.003125rem;
  line-height: 1.875rem;
}
#results #no-results-message .popular a {
  display: inline-block;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
}

.search-active #content::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

body.search-active {
  overflow: hidden;
}

.glossary__header-wrap {
  background: #3C008D;
  background: linear-gradient(110deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  width: 100%;
  top: 0;
}
.glossary__header-wrap .header {
  z-index: 15;
}
.glossary__overlay {
  background-image: url("../images/overlays/Homepage.jpg");
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.glossary__header-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/shape-lexicon-bg.png");
  background-repeat: no-repeat;
  background-size: 440px;
  background-position: top -130px right -150px;
}
.glossary .term__overlay {
  background-image: url("../images/graphs/snail-colour.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  transform: scaleX(-1);
  background-position: bottom -70px right;
}
.glossary .form__overlay {
  background-image: url("../images/shape-lexicon-bg.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  opacity: 0.7;
  background-position: bottom right -200px;
}
.glossary .header {
  position: relative;
  background: transparent;
}
@media (max-width: 898.98px) {
  .glossary .header__nav {
    padding: 0.3125rem 0;
  }
  .glossary .header .brand-logo {
    width: 5.5rem;
    height: 2.9375rem;
  }
}
.glossary .heading {
  padding-top: 6.75rem;
  padding-bottom: 1.5625rem;
}
@media (max-width: 598.98px) {
  .glossary .heading {
    padding-top: 3.4375rem;
  }
}
.glossary .heading h1 {
  color: #ffffff;
  letter-spacing: 0.00625rem;
  line-height: 4.25rem;
}
@media (max-width: 598.98px) {
  .glossary .heading h1 {
    font-size: 1.875rem;
    letter-spacing: 0.00375rem;
    line-height: 1.6875rem;
  }
}
.glossary .heading .subtitle {
  color: #ffffff;
  letter-spacing: 0.0025rem;
}
.glossary .heading .form-link {
  text-align: right;
}
.glossary .heading .form-link a {
  color: #fff;
}
.glossary .heading .form-link a .icon {
  margin-left: 0.625rem;
}
.glossary .heading .form-link a:hover {
  text-decoration: underline;
}
.glossary .heading .form-link .icon {
  transform: scaleX(-1);
}
@media (max-width: 598.98px) {
  .glossary.search-active .heading, .glossary.search-active .header {
    display: none;
  }
  .glossary.search-active .heading .container-fluid, .glossary.search-active .heading .container-xs, .glossary.search-active .heading .container-sm, .glossary.search-active .heading .container-md, .glossary.search-active .heading .container-lg, .glossary.search-active .heading .container-xl, .glossary.search-active .header .container-fluid, .glossary.search-active .header .container-xs, .glossary.search-active .header .container-sm, .glossary.search-active .header .container-md, .glossary.search-active .header .container-lg, .glossary.search-active .header .container-xl {
    display: none;
  }
}
.glossary .search-bar {
  background: #3d269a;
  position: relative;
  padding-top: 1.875rem;
  border-top: 2px solid #fff;
}
@media (max-width: 898.98px) {
  .glossary .search-bar {
    padding-top: 0.9375rem;
  }
}
.glossary .search-bar .search-field__animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 2.5rem;
  font-size: 1.125rem;
  color: #FFFFFF;
  letter-spacing: 0;
}
.glossary .search-bar .search-field__animation-text {
  margin-left: 0.625rem;
}
.glossary .search-bar .search-field__animation.is-hidden {
  display: none;
}
.glossary .search-bar .anchors {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.glossary .search-bar .anchors::-webkit-scrollbar {
  display: none;
}
.glossary .search-bar .anchors__wrapper {
  margin: 0.625rem auto;
  min-width: 55.625rem;
}
.glossary .search-bar .anchors a {
  display: inline-block;
  width: 1.875rem;
  color: #ffffff;
  text-decoration: none;
  padding: 0.125rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 1.25rem;
}
@media (hover: hover) {
  .glossary .search-bar .anchors a:hover {
    color: #3d269a;
    background-color: #ffffff;
  }
}
.glossary .search-bar .anchors a.active {
  transition: all 0.1s ease-in;
  transition-delay: 0.1s;
  color: #3d269a;
  background-color: #ffffff;
}
.glossary .search-bar .anchors span {
  padding: 0.625rem;
  color: rgba(255, 255, 255, 0.5);
}

.search-page .header {
  position: fixed;
  width: 100%;
  top: 0;
}

.search-close {
  display: none;
  position: absolute;
  top: 9px;
  right: 36px;
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: none;
  background-size: 100% 100%;
  background-image: url("../images/graphs/icon-close.svg");
}
.search-active .search-close {
  display: block;
}

#searchinput {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding: 0.9375rem 2.1875rem;
  outline: none;
  border: 0;
  font-family: Roboto-Regular;
  font-size: 1.125rem;
  color: #ffffff;
  letter-spacing: 0;
  background-image: url("../images/graphs/icon-search.svg");
  background-repeat: no-repeat;
  background-position: center right 1.875rem;
}
.search-active #searchinput {
  background-image: none;
}
#searchinput::placeholder {
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.5);
}
#searchinput::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.sticky-search .search-bar {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 6.625rem;
  margin: 0;
}
@media (max-width: 898.98px) {
  .sticky-search .search-bar {
    top: 3.5625rem;
  }
  .sticky-search .search-bar.nav-up {
    top: -0.625rem;
  }
}
.sticky-search #content {
  margin-top: 8.6875rem;
}
@media (max-width: 898.98px) {
  .sticky-search #content {
    margin-top: 6.125rem;
  }
}

.navigation-pagination {
  align-items: center;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 4.5em 0;
}
.navigation-pagination ul,
.navigation-pagination li {
  list-style-type: none;
}
.navigation-pagination__list {
  margin: 0 10px;
  padding-left: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}
.navigation-pagination__item {
  margin-left: 0.625em;
  margin-right: 0.625em;
}
@media (max-width: 600px) {
  .navigation-pagination .dots {
    margin-left: 0;
    margin-right: 0;
  }
}
.navigation-pagination__link {
  border: 2px solid #455560;
  border-radius: 50%;
  color: #696765;
  text-align: center;
  text-decoration: none;
  width: 2.5em;
  height: 2.5em;
  padding: 2px 2px 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-pagination__link:hover, .navigation-pagination__link:focus {
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F 0%, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  color: #FFFFFF;
}
.navigation-pagination__link-active {
  background-color: #455560;
  color: #FFFFFF;
  border: 2px solid #455560;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  width: 2.5em;
  height: 2.5em;
  padding: 2px 2px 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 600px) {
  .navigation-pagination__dots {
    align-items: center;
    border: 2px solid #455560;
    border-radius: 50%;
    color: #696765;
    display: flex;
    height: 2.5em;
    justify-content: center;
    padding: 2px 2px 0 2px;
    text-align: center;
    text-decoration: none;
    width: 2.5em;
  }
}
.navigation-pagination__arrow {
  position: relative;
  width: 1.5em;
}
@media (min-width: 600px) {
  .navigation-pagination__arrow:focus, .navigation-pagination__arrow:hover {
    width: 2.5em;
  }
  .navigation-pagination__arrow:focus img:first-child, .navigation-pagination__arrow:hover img:first-child {
    display: none;
  }
  .navigation-pagination__arrow:focus img:last-child, .navigation-pagination__arrow:hover img:last-child {
    display: initial;
  }
}
.navigation-pagination__arrow--previous {
  margin-left: 20px;
  margin-right: 20px;
}
.navigation-pagination__arrow--previous img {
  position: absolute;
  right: 0px;
  top: 1px;
}
@media (max-width: 600px) {
  .navigation-pagination__arrow--previous {
    margin-left: 40px;
    margin-right: 10px;
  }
}
.navigation-pagination__arrow--next {
  padding-left: 20px;
}
.navigation-pagination__arrow--next img {
  position: absolute;
  top: 1px;
}
@media (max-width: 600px) {
  .navigation-pagination__arrow--next {
    padding-left: 10px;
  }
}
.navigation-pagination__arrow img:first-child {
  display: initial;
}
.navigation-pagination__arrow img:last-child {
  display: none;
}
.navigation-pagination__arrow img:only-child {
  display: initial;
}
.navigation-pagination__icon {
  color: #696765;
  fill: #212121;
  display: block;
  height: 15px;
  width: 15px;
  position: relative;
  top: 50%;
  margin-top: -8px;
  left: 50%;
  margin-left: -8px;
}
.navigation-pagination__icon-right {
  transform: scaleX(-1);
}
.navigation-pagination__disabled {
  color: #696765;
  pointer-events: none;
}
.navigation-pagination__disabled .navigation-pagination__icon {
  fill: #696765;
}
.navigation-pagination__item-dots {
  border: none;
}

.featured-article {
  position: relative;
  top: -4em;
  z-index: 2;
}
.featured-article .featured-panel {
  max-height: 22em;
}
.featured-article .featured-panel .featured-panel-card {
  background: #FFFFFF;
  border-radius: 5px;
  max-width: 64.375em;
  overflow: hidden;
  padding: 0 1em 0.75em;
  position: relative;
  z-index: 1;
  box-shadow: 0px 6px 11px 0px rgba(105, 103, 101, 0.5019607843);
}
.featured-article .featured-panel .featured-panel-card:after {
  background: #3C008D;
  background: linear-gradient(136.38deg, #3C008D 36.3%, #1783BD 67.12%, #3AC7A7 94.17%, #70BC1F 117.76%);
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 16px;
  position: absolute;
  z-index: -1;
}
.featured-article .featured-panel .featured-panel-card h2, .featured-article .featured-panel .featured-panel-card h5 {
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "vollkornregular", "Times New Roman", serif;
  width: fit-content;
  margin: 0;
}
.featured-article .featured-panel .featured-panel-card p {
  color: #455560;
  font-family: "robotoregular", "Gill Sans", Arial;
  margin: 0.65em 0;
  font-size: 0.93rem;
}
.featured-article .featured-panel .featured-panel-card p.cat {
  text-transform: uppercase;
  font-size: 0.85rem;
}
.featured-article .featured-panel .featured-panel-card a {
  text-decoration: none;
}
.featured-article .featured-panel .featured-panel-card .break--gradient {
  height: 1px;
  margin: 0;
  width: 85%;
}
.featured-article .featured-panel .featured-panel-card .btn--news {
  width: 11.25rem;
  text-align: center;
  margin-top: 0.5em;
  margin-left: -0.35em;
}
.featured-article .featured-panel .featured-panel-card .featured__image {
  width: 100%;
}

@media (max-width: 598.98px) {
  .featured-article {
    margin: 0 1em;
    z-index: 2;
  }
  .featured-article .featured-panel {
    margin-top: 4.063em;
    max-height: initial;
  }
  .featured-article .featured-panel .featured-panel-card {
    padding: 0.75em 1em 2em 1em;
  }
  .featured-article .featured-panel .featured-panel-card:after {
    display: none;
  }
  .featured-article .featured-panel .featured-panel-card .btn--news {
    width: 100%;
    margin: 0;
  }
  .featured-article .featured-panel .featured-panel-card .featured__image {
    width: 17.5em;
    display: block;
  }
}
.search-results .search-results-items select {
  border: 1px solid #455560;
  border-radius: 2px;
  box-sizing: border-box;
  color: #455560;
  font-family: "robotoregular", "Gill Sans", Arial;
  margin: 0px 16px;
  padding: 6px;
}
.search-results .search-results-items .search-results-item a {
  text-decoration: none;
}
.search-results .search-results-items .search-results-item h4 span {
  background: #3C008D;
  background: linear-gradient(315deg, #70BC1F, #3AC7A7 17.53%, #1783BD 34.53%, #3C008D 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-color: transparent;
  font-family: "vollkornregular", "Times New Roman", serif;
}
.search-results .search-results-items .search-results-item h4 span:hover {
  --g: linear-gradient(315deg,#70bc1f,#3ac7a7 17.53%,#1783bd 34.53%,#3c008d);
  background: var(--g), var(--g) bottom 0.5px left 0/100% 0.75px no-repeat;
  background-clip: text, padding-box;
  -webkit-background-clip: text, padding-box;
}
.search-results .search-results-items .search-results-item p {
  font-family: "robotoregular", "Gill Sans", Arial;
  font-size: 0.875em;
  line-height: 1.125em;
}
.search-results .search-results-items .search-results-item p.top {
  color: #8797A3;
  padding: 0.5em 0;
}
.search-results .search-results-items .search-results-item p.bottom {
  color: #8797A3;
  padding: 0.5em 0;
}

.header-search {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 10;
}
.header-search__input-group {
  margin: 10px 0;
}
.header-search__input-group button {
  background-color: #F4F4F4;
  border: none;
  height: 73px;
  position: absolute;
  right: 16px;
  top: 11px;
  width: 70px;
}
.header-search__input-group button span .icon {
  filter: invert(60%);
  margin-left: 20px;
}
.header-search__input-group p {
  color: #8797A3;
  position: absolute;
  right: 100px;
  top: 20px;
}
@media (max-width: 600px) {
  .header-search__input-group p {
    display: none;
  }
}
.header-search__input-group input {
  border: 1px solid #B2B2B2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  height: 75px;
  padding-left: 100px;
  padding-right: 200px;
  width: 100%;
}
@media (max-width: 600px) {
  .header-search__input-group input {
    padding-left: 10px;
    padding-right: 75px;
  }
}

.global-copy {
  padding: 1em 0;
}
.global-copy .break--gradient {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  border: none;
  height: 1.5px;
  width: 65%;
}
@media (max-width: 898.98px) {
  .global-copy .break--gradient {
    width: 100%;
  }
}
.global-copy .btn.btn--square {
  background: transparent;
  border: 2px solid #EFEEEE;
  border-radius: 5px;
  width: 10rem;
  height: 8rem;
}
.global-copy .btn.btn--square p {
  font-size: 0.8rem;
  margin-top: 0.7em;
}
.global-copy .btn.btn--square:before, .global-copy .btn.btn--square:after {
  display: none;
}
.global-copy .btn.btn--square:hover, .global-copy .btn.btn--square.active, .global-copy .btn.btn--square:focus {
  border-color: #212121;
  color: #212121;
}
.global-copy h1 {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 2.8rem;
}
.global-copy h3 {
  font-size: 2.1rem;
}
.global-copy h4 {
  font-size: 1.65rem;
}
.global-copy h4 span {
  position: relative;
}
.global-copy h4 a {
  text-decoration: none;
}
.global-copy h4 a:focus span, .global-copy h4 a:hover span {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .global-copy h4 a:focus span, .global-copy h4 a:hover span {
    background: none;
    color: #130067;
  }
}
.global-copy h4 a:focus span::after, .global-copy h4 a:hover span::after {
  background: #3C008D;
  background: linear-gradient(to right, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  content: "";
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.global-copy h4 a .icon {
  transform: scaleX(-1);
  margin-left: 0.625rem;
}

.international {
  background: #3C008D;
  background: linear-gradient(122deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  color: #FFFFFF;
  min-height: 100vh;
  position: relative;
}
.international__background:before {
  background: transparent url(/includes/img/international/world-map.png) no-repeat;
  background-position: center 266px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
.international__background:after {
  background: transparent url(/includes/img/international/dots.png) no-repeat;
  background-position: center 232px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
.international__nodes {
  height: 392px;
  left: 50%;
  margin-left: -580px;
  position: absolute;
  top: 325px;
  width: 1013px;
  max-width: 100%;
}
.international__nodes:after {
  background: url(/includes/img/international/nodes.png);
  content: "";
  height: 100%;
  left: -7px;
  position: absolute;
  top: -8px;
  width: 100%;
}
.international__nodes__highlight {
  background-repeat: no-repeat;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.international__nodes__highlight.highlighted {
  display: block;
}
.international__nodes__highlight[data-highlight=canada] {
  background-image: url(/includes/img/international/lines/canada.png);
}
.international__nodes__highlight[data-highlight=united-kingdom] {
  background-image: url(/includes/img/international/lines/united-kingdom.png);
}
.international__nodes__highlight[data-highlight=united-states] {
  background-image: url(/includes/img/international/lines/united-states.png);
}
.international .header {
  background: transparent;
}
.international .header .header__nav {
  background: #3C008D;
  background: linear-gradient(122deg, #3C008D, #1783BD 70%, #3AC7A7 85%, #70BC1F 100%);
  background-size: 100% 100vh;
  transition: none;
}
.international .location-selector {
  border-bottom: 1px solid #FFFFFF;
  display: flex;
  list-style: none;
  padding: 0 0 3em 0;
  margin-bottom: 3em;
  margin-top: 2em;
}
.international .location-selector__option {
  display: block;
  width: 33.33%;
  padding: 0;
  margin: 0;
}
.international .location-selector__link {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin: 0 auto;
  max-width: 120px;
}
.international .location-selector__link img {
  height: 84px;
  width: 84px;
}
.international .location-selector__link:hover p {
  text-decoration: underline;
}
@media (max-width: 438.98px) {
  .international .location-selector {
    flex-direction: column;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .international .location-selector__option {
    margin: 0.6875rem 0;
    width: 100%;
  }
  .international .location-selector__link {
    flex-direction: row;
    max-width: none;
  }
  .international .location-selector__link img {
    height: 46px;
    width: 46px;
    margin: 0 1.125rem;
  }
  .international .location-selector__link p {
    margin: 0;
  }
}