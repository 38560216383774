// Primary navigation

.primary-navigation {
	font-size: rem(14);
	text-align: right;

	.primary-navigation__menu {
		list-style-type: none;
	}

	.primary-navigation__menu-2 {
		display: none;
	}

	.primary-navigation__item {
		display: inline-block;
		margin: 0 15px;

		a {
			color: $white;
			text-decoration: none;

			.icon {
				display: none;
			}
		}

		&.active {
			border-bottom: 2px solid #fff;
			font-family: $font-stack-bold;
			padding-bottom: 1px;		
		}
	}

	.primary-navigation__item--login {
		a {
			display: inline-block;
			position: relative;

			.icon {
				display: inline-block;
				position: absolute;
				top: 0;
			}
		}
	}

	.primary-navigation__mobile-social {
		display: none;
	}
}

@include media-breakpoint-up(lg) {

	.primary-navigation__item {
		height: 22px;
	}

	.primary-navigation__item--login {
		a {
			margin-right: 30px;
			.icon {
				right: -30px;
			}
		}
	}

	
	.primary-navigation__menu-2 {
		display: none !important;
	}
}

@include media-breakpoint-down(lg) {
	.primary-navigation {

		.primary-navigation__menu {
			padding-right: 40px;
		}
		
		.primary-navigation__item {
			margin: 0 5px;
		}
	}

	.primary-navigation__item--login a {
		margin-right: 0;

		.icon {
			left: unset !important;
			margin-left: 10px;
		}
	}
}

@include media-breakpoint-up(md) {
	.primary-navigation__item {

		a {
			&:hover,
			&:focus {
				border-bottom: 2px solid #fff;
				padding-bottom: 1px;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.primary-navigation {
		@include gradient-angle();
		bottom: 0;
		left: -500px;
		overflow-y: scroll;
		padding: 0;
		position: fixed;
		text-align: center;
		top: 7.7em;
		transition: background-color .25s;
		width: 0;
		z-index: 10;
		-webkit-overflow-scrolling: touch;

		// Remove defaults
		.primary-navigation__menu {
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 10;

			li {
				display: block;
				margin-top: 0;
				margin-bottom: 0;
				float: none;
				list-style-type: none;

				&:before {
					display: none;
				}
			}
		}

		.primary-navigation__item {
			&.active {
				border-bottom: 0;
			}
		}

		// Hide initially, to transition in
		.primary-navigation__menu {
			opacity: 0;
			transform: translate3d(0, 4vh, 0);
			transition: .5s;

			a,
			a:visited {
				color: $white;
				display: block;
				padding: 1.2em 30px;
				text-decoration: none;
				text-transform: uppercase;
			}
		}

		// Level 1
		> ul > li {
			> a,
			> a:visited {
				border-bottom: 1px solid $white;
			}
		}

		// Level 2
		.has-children {
			> a,
			> a:visited {
				position: relative;

				.icon {
					display: block;
					margin-top: -12px;
					position: absolute;
					right: 0;
					top: 50%;
					transition: $easing-regular;
				}
			}

			&.sub-menu-open {
				> a {
					text-decoration: underline;
				}
				> a,
				> a:visited {
					.icon {
						transform: rotate(-90deg);
					}
				}
			}

			.primary-navigation__menu-2 {
				display: none;
				margin: 0;
				padding: 0;
				text-align: left;

				> li {
					padding-left: 15px;
					padding-right: 15px;

					> a,
					> a:visited {
						border-bottom: 1px solid $white;
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
		}

		.primary-navigation__item--login {
			a .icon {
				right: auto;
				left: 0;
				top: 1.2em;
			}
		}

		.primary-navigation__mobile-social {
			padding-bottom: 2em;
			padding-top: 2em;

			a,
			a:visited {
				color: $white;
				font-size: rem(24);
			}

			ul {
				margin: 0;
				padding: 0;
			}

			li {
				display: inline-block;
				list-style-type: none;
				margin: 0 1em;
			}
		}
	}

	.menu-open {
		max-height: 100vh;
		overflow: hidden;

		.primary-navigation {
			overflow: auto;
			right: 0;
			left: 0;
			width: auto;

			.primary-navigation__menu {
				opacity: 1;
				transform: translate3d(0,0,0);
			}

			.primary-navigation__mobile-social {
				display: block;
			}
		}

		#content,
		.footer {
			display: none;
		}
	}
}